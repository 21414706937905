import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useUpdateProduct from "../../../api/productplan/update";
import useProduct from "../../../api/productplan/get";
import useListCategory from "../../../api/category/list";
import Button, { ButtonPrev } from "../../../parts/Button";
import TagInput from "../../../parts/TagInput/TagInput";
import styled from "styled-components";
import ImageInput from "../../../parts/ImageInput";
import { cautionThumbnail } from "../../../consts/texts";
import FileContainer from "../../../parts/FileContainer";

type TFiles =
  | "file1"
  | "file2"
  | "file3"
  | "file4"
  | "file5"
  | "file6"
  | "file7"
  | "file8"
  | "file9"
  | "file10"
  | "file11"
  | "file12"
  | "file13"
  | "file14"
  | "file15"
  | "file16"
  | "file17"
  | "file18"
  | "file19"
  | "file20";

const Form = styled.form`
  width: 95%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    width: 70%;
  }
`;

const Item = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 10px;
  border-bottom: dotted 1px #d3d3d3;
  padding: 2% 0;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const Key = styled.div`
  // height: 100%;
  width: 30%;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 30%;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  font-size: 100%;
  @media (max-width: 768px) {
    width: 100%;
    flex-basis: auto;
  }
`;
const Value = styled.div`
  width: auto;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: auto;
  @media (max-width: 768px) {
    width: 100%;
    flex-basis: auto;
  }
`;
const Caution = styled.p`
  font-size: 0.8rem;
  color: #6d6d6d;
  white-space: pre-wrap;
  text-wrap: normal;
`;

const Content: React.FC = () => {
  const { id = "0" } = useParams<{ id?: string }>();
  const __id = parseInt(id);
  const navigate = useNavigate();

  const [name, setName] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [category1, setCategory1] = React.useState<number | undefined>(
    undefined
  );
  const [tags, setTags] = React.useState<string[]>([]);

  const updateProduct = useUpdateProduct();
  const product = useProduct({ pathParams: { id } });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("handleSubmit");
    const thumbnail = e.currentTarget.thumbnail.files?.[0];
    const file1 = e.currentTarget.file1.files?.[0];
    const file2 = e.currentTarget.file2.files?.[0];
    const file3 = e.currentTarget.file3.files?.[0];
    const file4 = e.currentTarget.file4.files?.[0];
    const file5 = e.currentTarget.file5.files?.[0];
    const file6 = e.currentTarget.file6.files?.[0];
    const file7 = e.currentTarget.file7.files?.[0];
    const file8 = e.currentTarget.file8.files?.[0];
    const file9 = e.currentTarget.file9.files?.[0];
    const file10 = e.currentTarget.file10.files?.[0];
    const file11 = e.currentTarget.file11.files?.[0];
    const file12 = e.currentTarget.file12.files?.[0];
    const file13 = e.currentTarget.file13.files?.[0];
    const file14 = e.currentTarget.file14.files?.[0];
    const file15 = e.currentTarget.file15.files?.[0];
    const file16 = e.currentTarget.file16.files?.[0];
    const file17 = e.currentTarget.file17.files?.[0];
    const file18 = e.currentTarget.file18.files?.[0];
    const file19 = e.currentTarget.file19.files?.[0];
    const file20 = e.currentTarget.file20.files?.[0];

    const result = await updateProduct.execute({
      id: __id,
      name,
      description,
      thumbnail,
      tags,
      category1,
      ...(file1 && { file1 }),
      ...(file2 && { file2 }),
      ...(file3 && { file3 }),
      ...(file4 && { file4 }),
      ...(file5 && { file5 }),
      ...(file6 && { file6 }),
      ...(file7 && { file7 }),
      ...(file8 && { file8 }),
      ...(file9 && { file9 }),
      ...(file10 && { file10 }),
      ...(file11 && { file11 }),
      ...(file12 && { file12 }),
      ...(file13 && { file13 }),
      ...(file14 && { file14 }),
      ...(file15 && { file15 }),
      ...(file16 && { file16 }),
      ...(file17 && { file17 }),
      ...(file18 && { file18 }),
      ...(file19 && { file19 }),
      ...(file20 && { file20 }),
    });
    console.log(result);
    if (result instanceof Error) return;
    alert("商品を修正しました");
    navigate("/creator/productplan");
  };

  const listCategory = useListCategory();

  useEffect(() => {
    if (!product.data) return;
    if (!product.called) return;
    if (product.loading) return;
    if (product.error) return;
    setName(product.data.name);
    setDescription(product.data.description);
    setCategory1(product.data?.category1?.id);
    setTags(product.data.tags?.map((tag) => tag.name) ?? []);
  }, [product.loading, product.data, product.called, product.error]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    const isTextarea = (e?.target as HTMLElement)?.tagName === "TEXTAREA";
    if (isTextarea) return;
    const isEnter = e.key === "Enter";
    if (isEnter) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  if (product.loading) return <div>loading...</div>;

  return (
    <Form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
      <div>
        <div className="main">
          <h2 className="page-title">継続プラン商品修正</h2>

          <div className="information-wrap">
            <Item>
              <Key>商品No</Key>
              <p>{id}</p>
            </Item>

            <Item>
              <Key>
                商品名 <span className="required">必須</span>
              </Key>
              <input
                size={20}
                type="text"
                className="wide"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Item>
            <Item>
              <Key>
                サムネイル画像<span className="required">必須</span>
              </Key>
              <Value>
                <ImageInput
                  name="thumbnail"
                  srcInitial={product.data?.thumbnail}
                />
                <Caution>{cautionThumbnail}</Caution>
              </Value>
            </Item>

            <Item>
              <Key>
                商品説明<span className="required">必須</span>
              </Key>
              <textarea
                id="product-text"
                name="product-text"
                rows={5}
                cols={33}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </Item>

            <Item>
              <Key>
                商品カテゴリ選択<span className="required">必須</span>
              </Key>
              <div className="cp_ipselect cp_sl">
                <select
                  required
                  value={category1}
                  onChange={(e) => setCategory1(parseInt(e.target.value))}
                >
                  <option value="" hidden>
                    選択
                  </option>
                  {listCategory.data?.map((category, index) => (
                    <option value={category.id} key={index}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </Item>

            <Item>
              <Key>
                商品タグ選択<span className="required">必須</span>
              </Key>

              <TagInput tags={tags} setTags={setTags} />
            </Item>

            <FileContainer>
              {[...new Array(20)].map((_, index) => (
                <Item key={index}>
                  <Key>画像ギャラリー{index + 1}</Key>
                  <ImageInput
                    name={`file${index + 1}`}
                    srcInitial={
                      product.data?.[`file${index + 1}` as TFiles] as string
                    }
                  />
                </Item>
              ))}
            </FileContainer>
          </div>
        </div>
      </div>
      <div className="btn">
        <ButtonPrev>戻る</ButtonPrev>
        <Button loading={updateProduct.loading}>確定</Button>
      </div>
    </Form>
  );
};

export default Content;
