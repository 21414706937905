import React from "react";
import { Link } from "react-router-dom";

const Content: React.FC = () => {
  // const orders = [
  //   { id: 1, product: "オーダー商品1", status: "未発送" },
  //   { id: 2, product: "オーダー商品2", status: "未発送" },
  //   { id: 3, product: "オーダー商品3", status: "未発送" },
  // ];
  return (
    <>
      <div className="wrap">
        <div className="main">
          <h2>オーダー詳細</h2>

          <div>
            <div className="information-box boxcolor">
              <h3>オーダーNo</h3>
              <p>123456879</p>
            </div>
            <div className="information-box boxcolor">
              <h3>購入年月日</h3>
              <p>2022年9月26日</p>
            </div>

            <div className="information-box boxcolor">
              <h3>購入時分秒</h3>
              <p>01:27:22</p>
            </div>
            <div className="information-box boxcolor">
              <h3>商品No</h3>
              <p>123456879</p>
            </div>

            <div className="information-box boxcolor">
              <h3>販売種別</h3>
              <p>都度</p>
            </div>

            <div className="information-box boxcolor">
              <h3>商品カテゴリ</h3>
              <p>カテゴリ</p>
            </div>

            <div className="information-box boxcolor">
              <h3>商品名</h3>
              <p>商品名XXXXX</p>
            </div>

            <div className="information-box boxcolor">
              <h3>個数</h3>
              <p>10個</p>
            </div>

            <div className="information-box boxcolor">
              <h3>単価</h3>
              <p>1300円</p>
            </div>
            <div className="information-box boxcolor">
              <h3>合計金額</h3>
              <p>13000円</p>
            </div>

            <div className="information-box boxcolor">
              <h3>決済手段</h3>
              <p>クレジットカード</p>
            </div>
            <div className="information-box boxcolor">
              <h3>価格設定</h3>
              <p>1300円</p>
            </div>
            <div className="information-box boxcolor">
              <h3>会員No</h3>
              <p>1234567</p>
            </div>

            <div className="information-box boxcolor">
              <h3>ニックネーム</h3>
              <p>ヤマダ</p>
            </div>

            <div className="information-box boxcolor">
              <h3>会員氏名（漢字）</h3>
              <p>山田太郎</p>
            </div>

            <div className="information-box boxcolor">
              <h3>対応状況</h3>
              <p>未発送</p>
            </div>

            <div className="information-box boxcolor">
              <h3>電話番号</h3>
              <p>000-000-000</p>
            </div>

            <div className="information-box boxcolor">
              <h3>メールアドレス</h3>
              <p>XXXXXXXXXX＠XXXX</p>
            </div>
          </div>
        </div>
      </div>

      <div className="btn">
        <Link to="/creator/order">
          <input
            type="submit"
            className="back"
            // onclick"location.href='creator-order-list.html'"
            value="戻る"
          />
        </Link>
      </div>
    </>
  );
};

export default Content;
