import React from "react";
import Button from "../../parts/Button";
import Container from "../../parts/Container";
import Text from "../../parts/Text";
import { Link } from "react-router-dom";

const LinkButton = Button.withComponent(Link);

const Content: React.FC = () => {
  return (
    <Container fullWidth maxWidth750 gap={30}>
      <Text center large flex justifyCenter bold>
        購入完了
      </Text>
      <Text center>
        購入が完了しました。ご利用のメールアドレスに購入完了メールをお送りします。
      </Text>
      <Container row justifyCenter>
        <LinkButton to={"/"}>商品一覧へ</LinkButton>
        <LinkButton to={"/"}>TOPへ</LinkButton>
      </Container>
    </Container>
  );
};

export default Content;
