import Content from "../components/Company";
import Layout from "../themplate/Layout";

const Page: React.FC = () => (
  <Layout>
    <Content />
  </Layout>
);

export default Page;
