import styled from "styled-components";
import clsx from "clsx";
import usePostMessage from "../api/message/add";
import React from "react";
import Button from "./Button";

const ModalBackground = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--z-index-modal);

  justify-content: center;
  align-items: center;

  &.active {
    display: flex;
  }
`;
const Modal = styled.form`
  position: relative;
  margin: 0;
  padding: 20px;
  width: 300px;
  height: fit-content;
  display: flex;
  border: none;
  border-radius: 10px;
  background-color: white;
  gap: 20px;
  flex-direction: column;
`;

const Header = styled.h1`
  margin: 0;
`;

const TitleLabel = styled.label`
  margin: 0 0 -10px !important;
`;
const TitleInput = styled.input`
  width: 100%;
  height: 30px;
  padding: 5px !important;
  border: 1px solid #ccc;
  border-radius: 5px;
`;
const MessageLabel = styled.label`
  margin: 0 0 -10px !important;
`;
const MessageInput = styled.textarea`
  width: 100%;
  max-width: 100% !important;
  padding: 5px !important;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
`;
const MessageButton = styled(Button)``;

interface Props {
  userId: number;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const User: React.FC<Props> = (p) => {
  const { userId, setOpen, open } = p;
  const closeModal = () => {
    setOpen(false);
  };

  const postMessage = usePostMessage();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("submit");
    const form = e.currentTarget;
    const message = form?.message?.value;
    const title = form?.messageTitle?.value;
    if (!title) return alert("タイトルを入力してください");
    if (!message) return alert("メッセージを入力してください");

    const result = await postMessage.execute({ to: userId, message, title });

    if (result instanceof Error) return alert("送信に失敗しました");

    alert("送信しました");
    closeModal();
  };
  const stopPropagation = (e: React.MouseEvent<HTMLFormElement>) => {
    e.stopPropagation();
  };

  return (
    <ModalBackground className={clsx({ active: open })} onClick={closeModal}>
      <Modal onSubmit={handleSubmit} onClick={stopPropagation}>
        <Header>メッセージを送信する</Header>

        <TitleLabel>タイトル</TitleLabel>
        <TitleInput name="messageTitle" />

        <MessageLabel>メッセージ</MessageLabel>
        <MessageInput name="message" />
        <MessageButton type="submit">送信</MessageButton>
      </Modal>
    </ModalBackground>
  );
};
export default User;
