import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal, {
  ModalTitle,
  ModalButton,
  ModalText,
} from "../../../parts/Modal";
import useAddTag from "../../../api/tag/add";

const Content: React.FC = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState<string>("");
  const [modal, setModal] = useState<boolean>(false);
  const closeModal = () => setModal(false);
  const openModal = () => {
    if (!category) {
      alert("タグ名を入力してください。");
      return;
    }
    setModal(true);
  };
  const addTag = useAddTag();

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement | HTMLInputElement>
  ) => {
    e.preventDefault();
    const r = await addTag.execute({ name: category });
    if (r instanceof Error) return;
    navigate(-1);
  };

  return (
    <>
      <div className="wrap">
        <div className="main">
          <h2>商品タグ新規追加</h2>

          <div className="information-box">
            <h3>タグ</h3>
            <input
              size={20}
              type="text"
              className="wide category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
          </div>
        </div>

        <div id="creator-side-menu"></div>
      </div>
      <div className="btn">
        <Link to="/creator/product/category">
          <input type="submit" className="back" value="戻る" />
        </Link>
        <span>
          <input
            type="submit"
            onClick={openModal}
            value="商品カテゴリ作成確認"
          />
        </span>
      </div>
      <Modal open={modal} onClose={closeModal}>
        <ModalTitle>タグを追加します。</ModalTitle>
        <ModalText>タグ名: {category}</ModalText>
        <ModalButton>
          <span>
            <input
              type="submit"
              className="back"
              onClick={closeModal}
              value="戻る"
            />
          </span>
          <span>
            <input type="submit" onClick={handleSubmit} value="追加" />
          </span>
        </ModalButton>
      </Modal>
    </>
  );
};

export default Content;
