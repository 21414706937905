const Icon: React.FC<React.HTMLAttributes<HTMLOrSVGElement>> = (p) => (
  <svg
    version="1.1"
    id="レイヤー_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 113.39 113.39"
    {...p}
  >
    <path
      fill="currentColor"
      d="M64.7,0C37.85,0,16.01,21.84,16.01,48.69c0,9.32,2.64,18.04,7.2,25.45L3.32,94.03
	c-4.43,4.43-4.43,11.61,0,16.04c2.21,2.21,5.12,3.32,8.02,3.32s5.8-1.11,8.02-3.32l19.89-19.89c7.41,4.56,16.13,7.2,25.45,7.2
	c26.85,0,48.69-21.84,48.69-48.69C113.39,21.84,91.54,0,64.7,0z M64.7,76.84c-15.52,0-28.15-12.63-28.15-28.15
	S49.17,20.53,64.7,20.53s28.16,12.63,28.16,28.16S80.22,76.84,64.7,76.84z"
    />
  </svg>
);
export default Icon;
