import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useGetUser from "../../../api/user/get";
import useUpdateUser from "../../../api/user/update";
import Button from "../../../parts/Button";

const UserList: React.FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const user = useGetUser();
  const updateUser = useUpdateUser();
  const navigate = useNavigate();
  const handleApprove = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const result = await updateUser.execute({
      id: Number(id),
      approved: true,
    });
    if (result instanceof Error) return alert("失敗");
    alert("更新しました");
    navigate(-1);
  };

  return (
    <div className="wrap">
      <h2>会員認証詳細</h2>

      <div>
        <div className="information-box line">
          <h3>会員No</h3>
          <p>No.{user.data?.id}</p>
        </div>
        <div className="information-box line">
          <h3>メールアドレス</h3>
          <p>{user.data?.email}</p>
        </div>

        <div className="information-box line">
          <h3>パスワード</h3> <p>XXXXX</p>
        </div>
        <div className="information-box line">
          <h3>氏名</h3>
          <p>{user.data?.profile?.nameKanji}</p>
        </div>
        <div className="information-box line">
          <h3>氏名（カナ）</h3>
          <p>{user.data?.profile?.nameKana}</p>
        </div>

        <div className="information-box line">
          <h3>アカウント名</h3>
          <p>{user.data?.profile?.nickname}</p>
        </div>
        <div className="information-box line">
          <h3>生年月日</h3>
          <p>{user.data?.profile?.birthday}</p>
        </div>
        <div className="information-box line">
          <h3>郵便番号</h3>
          <p>{user.data?.profile?.zip}</p>
        </div>

        <div className="information-box line">
          <h3>都道府県</h3>
          <p>{user.data?.profile?.state}</p>
        </div>

        <div className="information-box line">
          <h3>住所</h3>
          <p>{user.data?.profile?.address}</p>
        </div>
        <div className="information-box line">
          <h3>電話番号</h3>
          <p>{user.data?.profile?.call}</p>
        </div>
        <div className="information-box line">
          <h3>年齢確認書類</h3>
          {user.data?.file ? (
            <img className="icon" src={user.data?.file} alt="" />
          ) : (
            <p>未登録</p>
          )}
        </div>
      </div>
      <div className="btn">
        <Link to="/master/user-unapproved">
          <input
            type="submit"
            className="back"
            // onclick="location.href='master-unapproved-list.html'"
            value="戻る"
          />
        </Link>
        <Button onClick={handleApprove}>承認</Button>
      </div>
    </div>
  );
};

export default UserList;
