import Content from "../components/ProductUser";
import Layout from "../themplate/Layout";

const HomePage: React.FC = () => (
  <Layout>
    <Content />
  </Layout>
);

export default HomePage;
