import React from "react";
import styled from "styled-components";
import imgsrc from "../imgs/logo.png";
import { Link } from "react-router-dom";
import Button, { ButtonPrev } from "../parts/Button";
const LinkedButton = Button.withComponent(Link);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 20px;
  gap: 20px;
  @media (max-width: 768px) {
    width: 70%;
  }
`;

const HeadText = styled.h1``;
const TextMessage = styled.p`
  white-space: pre-wrap;
  text-align: left;
  text-wrap: wrap;
`;

const Image = styled.span<{ src: string }>`
  display: flex;
  position: relative;
  width: 100%;
  &::before {
    content: "";
    display: block;
    padding-top: 22%;
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${(props) => props.src});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
  }
`;
const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 20px;
  align-items: center;
`;

const Content: React.FC = () => {
  return (
    <Container>
      <HeadText>アカウントが凍結されています</HeadText>
      <TextMessage>
        {`ご利用者様\nこのアカウントはFunnyfans利用規約に繰り返し違反したため凍結されております\n申し訳ありませんが、Funnyfansサポートまでお問い合わせください。`}
      </TextMessage>
      <Buttons>
        <ButtonPrev />
        <LinkedButton to={`/`}>ホームへ</LinkedButton>
      </Buttons>
    </Container>
  );
};

export default Content;
