import React from "react";
import { Link } from "react-router-dom";
import Container from "../../../parts/Container/Container";
import Text from "../../../parts/Text";
import Button, { ButtonPrev } from "../../../parts/Button";
import useEarning from "../../../api/earning/list";

const LinkedButton = Button.withComponent(Link);

const Content: React.FC = () => {
  const earning = useEarning();

  const total = earning?.data?.reduce((a, c) => a + c.coins, 0);

  return (
    <Container width70L width95>
      <Container card round>
        <Text bold large>
          月別売上情報
        </Text>
        {earning?.data?.map((order) => {
          const [year, month] = order.date.split("/");
          return (
            <Container row background="light-blue" alignCenter>
              <Container noPadding basis={200}>
                {year}年{month}月
              </Container>
              <Container noPadding row>
                総売上 <Text bold>￥{order.coins.toLocaleString()}</Text>(
                {order.coins.toLocaleString()}c)
              </Container>
              <Container grow />
              <LinkedButton
                flexBasis={"auto"}
                to={`/creator/earning/${year}/${month}`}
              >
                詳細
              </LinkedButton>
            </Container>
          );
        })}
      </Container>
      <Container card round>
        <Container className="title-padding" row>
          <Text bold large>
            累計売上
          </Text>
          <Text>2022年7月〜</Text>
        </Container>
        <Container row alignEnd justifyEnd>
          <Text bold large pink>
            ￥{total}
          </Text>
          <Text>コイン換算</Text>
          <Text small pink>
            {total}c
          </Text>
        </Container>
        <Container row>
          <Text className="caution">
            {`※月毎の売上計算及び表示に時間がかかることがあります。
※月売上の支払いは提示月の翌々月末に販売ユーザー指定口座に振込み致します。
※売上金額が3万円を満たさない場合は基本的に支払いができません。予めご了承ください。
※売上には販売コイン額より下記手数料を引いた金額が表示されます。
  ・月売上50万円未満まで12％、
  ・月売上50万円以上100万円未満まで11％、
  ・月売上100万円以上から10%がさし引かれます。`}
          </Text>
        </Container>
      </Container>
      {/* {JSON.stringify(orders?.data)} */}
      <div className="btn">
        <ButtonPrev className="earnings-btn">戻る</ButtonPrev>
      </div>
    </Container>
  );
};

export default Content;
