import React from "react";
import { Link, useParams } from "react-router-dom";
import Container from "../../../parts/Container/Container";
import Text from "../../../parts/Text";
import Button from "../../../parts/Button";
import useEarning from "../../../api/earning/list";
import usePurchase from "../../../api/purchase/list";
import usePlanbought from "../../../api/planbought/list";
import moment from "moment";
import styled from "styled-components";
const LinkedButton = Button.withComponent(Link);

const C = styled(Container)`
  display: flex;
  padding: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-basis: 100px;
  flex-grow: 0;
  flex-shrink: 0;
`;
const ProductName = styled(C)`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
`;
const ProductAmount = styled(C)``;
const ProductPrice = styled(C)``;
const PurchaseDate = styled(C)``;
const PurchaseId = styled(C)``;
const PurchaseUserId = styled(C)``;
const PurchaseUserName = styled(C)``;
const PurchaseItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const PurchaseContainer = styled(Container)`
  background: white;
  overflow-x: scroll;
`;

const PlanBoughtItem = styled.div``;
const PlanboughtId = styled.div`
  width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
const PlanboughtPrice = styled.div`
  width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
const PlanboughtDate = styled.div`
  width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
const PlanboughtCreatedAt = styled.div`
  width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Content: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const earning = useEarning({ params: { userId } });
  const purchases = usePurchase({ params: { userId } });
  const planboughts = usePlanbought({
    params: { userId: parseInt(userId || "") },
  });

  const total = earning?.data?.reduce((a, c) => a + c.coins, 0) || 0;
  const count = earning?.data?.length || 1;

  return (
    <Container fullWidth maxWidth750>
      <h2>クリエーター売上管理</h2>
      <Container background="gray">
        <Container row>
          <Text>累計売上</Text>
          <Container grow />
          <Text>￥{total.toLocaleString()}</Text>
        </Container>
        <PurchaseContainer>
          <PurchaseItem>
            <ProductName>商品名</ProductName>
            <ProductAmount>数量</ProductAmount>
            <ProductPrice>小計</ProductPrice>
            <PurchaseId>購入ID</PurchaseId>
            <PurchaseDate>購入日時</PurchaseDate>
            <PurchaseUserId>購入者ID</PurchaseUserId>
            <PurchaseUserName>購入者名</PurchaseUserName>
          </PurchaseItem>
          {purchases.data?.map((purchase) =>
            purchase.products.map((product) => (
              <PurchaseItem>
                <ProductName>{product.name}</ProductName>
                <ProductAmount>1</ProductAmount>
                <ProductPrice> ￥{product.price.toLocaleString()}</ProductPrice>
                <PurchaseId>{purchase.id}</PurchaseId>
                <PurchaseDate>
                  {moment(purchase.createdAt).format("YYYY/MM/DD HH:mm:ss")}
                </PurchaseDate>
                <PurchaseUserId>{purchase?.user?.id}</PurchaseUserId>
                <PurchaseUserName>
                  {purchase.user?.profile?.nameKanji || "未設定"}
                </PurchaseUserName>
              </PurchaseItem>
            ))
          )}
        </PurchaseContainer>
        <PurchaseContainer>
          <PurchaseItem>
            <PlanboughtId>ID</PlanboughtId>
            <PlanboughtPrice>価格</PlanboughtPrice>
            <PlanboughtDate>購入商品</PlanboughtDate>
            <PlanboughtCreatedAt>購入日時</PlanboughtCreatedAt>
          </PurchaseItem>
          {planboughts.data?.map((planbought) => (
            <PurchaseItem>
              <PlanboughtId>{planbought.id}</PlanboughtId>
              <PlanboughtPrice>{planbought.price}</PlanboughtPrice>
              <PlanboughtDate>
                {planbought.year}年{planbought.month}月サブスク分
              </PlanboughtDate>
              <PlanboughtCreatedAt>
                {moment(planbought.createdAt).format("YYYY/MM/DD HH:mm:ss")}
              </PlanboughtCreatedAt>
            </PurchaseItem>
          ))}
        </PurchaseContainer>
      </Container>
      <Container background="gray">
        <Container row>
          <Text>月平均売上</Text>
          <Container grow />
          <Text>￥{total / count}</Text>
        </Container>
        <Container overflowXscroll background="white">
          {earning.data?.map((e) => {
            const [year, month] = e.date.split("/");
            return (
              <Container noPadding row>
                <Container basis={100}>
                  {year}年{month}月
                </Container>
                <Container alignCenter basis={100}>
                  ￥{e.coins.toLocaleString()}
                </Container>
                <Container grow noPadding />
                <LinkedButton
                  color="blackBlue"
                  to={`/master/earning/${userId}/${year}/${month}`}
                >
                  詳細を見る
                </LinkedButton>
              </Container>
            );
          })}
        </Container>
      </Container>
      <div className="btn">
        <Link to="/master/earning">
          <input type="submit" className="back" value="戻る" />
        </Link>
      </div>
    </Container>
  );
};

export default Content;
