import { useApiFormData } from "../useApi";
import Purchase from "../../types/Payment";

type DataType = Purchase[];
interface Params {
  year?: number;
  month?: number;
  userId?: number | string;
}

const path = "/payment";
const method = "get";
const eagerLoad = true;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApiFormData<DataType, Params>({
  path,
  method,
  eagerLoad,
});
