import styled from "styled-components";
import { Link } from "react-router-dom";
import IUser from "../../types/User";

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const UserName = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #333;
`;
const UserDescriptios = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: start;
  align-items: center;
`;
const UserImage = styled(Link)<{ src: string }>`
  width: 80px;
  flex-basis: 80px;
  height: 80px;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: #ccc;
  border-radius: 50%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
`;
const UserDescription = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  overflow-y: hidden;
  gap: 10px;
  justify-content: start;
  align-items: center;
`;
const UserPlansLink = styled(Link)`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 150px;
  background-color: #70b4cc;
  color: #fff;
  border-radius: 5px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  max-width: 100px;
  max-height: 50px;
  margin:auto;
`;

interface Props {
  user: IUser | null;
}
const User: React.FC<Props> = (p) => {
  const user = p?.user;
  if (!user) return null;
  const username = user?.shopProfile?.name;
  const userId = user.id;
  const introduction = user?.shopProfile?.introduction;
  const icon = user?.shopProfile?.icon;
  return (
    <UserContainer>
      <UserName>{username}</UserName>
      <UserDescriptios>
        <UserImage src={icon} to={`/shop?userId=${userId}`} />
        <UserDescription>
          {introduction || "紹介文はありません"}
        </UserDescription>
      </UserDescriptios>
      <UserPlansLink to={`/plan?userId=${userId}`}>
          プラン一覧
        </UserPlansLink>
    </UserContainer>
  );
};
export default User;
