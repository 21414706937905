import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import User from "types/User";
import UserType from "types/UserType";
import useUserlist from "../../../api/user/list";

const UserList: React.FC = () => {
  const [searchParams] = useSearchParams();
  const userType = (searchParams.get("type") as UserType) || "member";
  const userlist = useUserlist({
    params: { type: userType, limit: 100, unapproved: true },
  });

  return (
    <div className="wrap">
      <h2>未承認会員一覧</h2>

      <div className="information-box">
        <table>
          {userlist.data?.map((user: User) => (
            <tr>
              <th>NO.{user.id}</th>
              <td>承認状態</td>
              <td>
                <p>{user?.profile?.nickname}</p>
              </td>
              <td>
                <p>{user?.profile?.nickname}</p>
              </td>
              <td>
                <p>{user?.email}</p>
              </td>
              <td>
                <Link to={`/master/user-unapproved/${user.id}`}>
                  <input type="submit" className="detail" value="認証詳細" />
                </Link>
              </td>
            </tr>
          ))}
        </table>

        <div className="btn">
          <Link to="/master/member">
            <input
              type="submit"
              className="back"
              // onclick="location.href='master-member-top.html'"
              value="戻る"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserList;
