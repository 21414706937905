import styles from "./Container.module.scss";
import clsx from "clsx";
import styled from "styled-components";

interface Props {
  row?: boolean;
  fullWidth?: boolean;
  width70L?: boolean;
  width95?: boolean;
  quarter?: boolean;
  card?: boolean;
  half?: boolean;
  noPadding?: boolean;
  sideMargin?: boolean;
  alignCenter?: boolean;
  justifyCenter?: boolean;
  alignEnd?: boolean;
  justifyEnd?: boolean;
  overflowYscroll?: boolean;
  overflowXscroll?: boolean;
  round?: boolean;
  background?: "light-blue" | "gray" | "white";
  backgroundGrayOdd?: boolean;
  padding50?: boolean;
  paddingX?: number;
  paddingY?: number;
  maxWidth750?: boolean;
  wrap?: boolean;
  overflowHidden?: boolean;
  gap?: number;
  width?: number | string;
  grow?: boolean;
  shrink?: boolean;
  basis?: number;
  borderBottom?: boolean;
  borderTop?: boolean;
}
const Container = styled("div").attrs<Props>((p) => ({
  className: clsx(
    styles.div,
    p.fullWidth && styles.fullWidth,
    p.width70L && styles.width70L,
    p.width95 && styles.width95,
    p.quarter && styles.quarter,
    p.half && styles.half,
    p.round && styles.round,
    p.card && styles.card,
    p.alignCenter && styles.alignCenter,
    p.justifyCenter && styles.justifyCenter,
    p.alignEnd && styles.alignEnd,
    p.justifyEnd && styles.justifyEnd,
    p.background === "light-blue" && styles.backgroundLightblue,
    p.background === "gray" && styles.backgroundGray,
    p.background === "white" && styles.backgroundWhite,
    p.noPadding && styles.noPadding,
    p.padding50 && styles.padding50,
    p.row && styles.row,
    p.overflowYscroll && styles.overflowYscroll,
    p.overflowXscroll && styles.overflowXscroll,
    p.backgroundGrayOdd && styles.backgroundGrayOdd,
    p.maxWidth750 && styles.maxWidth750,
    p.wrap && styles.wrap,
    p.overflowHidden && styles.overflowHidden,
    p?.shrink && styles.shrink,
    p?.grow && styles.grow,
    p?.borderBottom && styles.borderBottom,
    p?.borderTop && styles.borderTop,
    p.className
  ),
}))<Props>`
  ${(p) =>
    p.paddingX && `padding-left:${p.paddingX}px;padding-right:${p.paddingX}px;`}
  ${(p) =>
    p.paddingY && `padding-top:${p.paddingY}px;padding-bottom:${p.paddingY}px;`}
  ${(p) => p?.gap !== undefined && `gap:${p.gap}px;`}
  ${(p) =>
    p?.width !== undefined &&
    (typeof p.width === "number" ? `width:${p.width}px` : `width:${p.width}`)}
  ${(p) => p?.basis !== undefined && `flex-basis:${p.basis}px`}
`;

export default Container;
