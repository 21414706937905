import React, { useEffect } from "react";
import styled from "styled-components";
import clsx from "clsx";
import img1 from "../../imgs/kari01.png";
import img2 from "../../imgs/top01.png";

const Image = styled.img`
  width: 100%;
`;

interface SliderDotProps {
  percentage: number;
}
const SliderDot = styled.span<SliderDotProps>`
  display: inline-block;
  width: 100px;
  height: 6px;
  margin: 4px;
  background: #eaeaea;
  cursor: pointer;
  &:before {
    content: "";
    display: block;
    width: 0%;
    height: 6px;
    margin: 0;
    font-size: 0;
    padding: 0;
    border: 0;
    outline: none;
    background: #70b4cc;
    cursor: pointer;
  }
  &.active:before {
    width: 100%;
    transition: width 2s linear;
  }
`;

interface SliderProps {
  //   imgs: string[];
}
const Slider: React.FC<SliderProps> = () => {
  const imgs = [img1, img2];
  const [current, setCurrent] = React.useState<number>(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent((current) => (current + 1) % imgs.length);
    }, 2000);
    return () => clearInterval(interval);
  }, [current, setCurrent, imgs.length]);
  return (
    <>
      <div className="slider-wrap">
        <ul className="slider" id="js-slider">
          {imgs.map((img, index) => (
            <li
              key={index}
              style={{ display: current === index ? "block" : "none" }}
            >
              <Image src={img} alt="" />
            </li>
          ))}
        </ul>
      </div>
      <div className="dots slick-dots" style={{ textAlign: "center" }}>
        {/* <ul className="" role="tablist"> */}
        {imgs.map((img, index) => (
          <SliderDot
            onClick={() => setCurrent(index)}
            percentage={10}
            className={clsx(current === index && "active")}
          />
        ))}
        {/* </ul> */}
      </div>
    </>
  );
};

export default Slider;
