import Content from "../../components/Creator/ProductPlanEdit";
import Layout from "../../themplate/Layout";

const HomePage: React.FC = () => (
  <Layout type="creator">
    <Content />
  </Layout>
);

export default HomePage;
