import Layout from "../../themplate/Layout";
import Container from "../../parts/Container";
import Button, { ButtonPrev } from "../../parts/Button";
import styled, { css } from "styled-components";
import { Link, useParams } from "react-router-dom";
import useMail from "../../api/mail/get";
import useUpdateMail from "../../api/mail/update";
import { useEffect, useRef } from "react";

const Header = styled.header`
  margin: 0;
`;
const MaillistContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0;
  padding: 20px;
  gap: 20px;
  @media (min-width: 768px) {
    width: 70%;
  }
`;
const LabelText = styled.div`
  font-weight: bold;
`;
const Label = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0px;
  gap: 10px;
`;
const Input = css`
  height: 40px;
  width: 100%;
  border: 2px solid skyblue;
  border-radius: 5px;
  padding: 10px;
`;
const MailSubject = styled.input`
  ${Input}
`;
const MailContent = styled.textarea`
  ${Input}
  height: 200px;
`;

const Page: React.FC = () => {
  const { key } = useParams<{ key: string }>();
  const formRef = useRef<HTMLFormElement>(null);
  if (!key) throw new Error("key is not defined");
  const mail = useMail({ pathParams: { id: key } });
  const updateMail = useUpdateMail();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const subject = formRef.current?.subject.value;
    const content = formRef.current?.content.value;
    if (!subject) alert("件名を入力してください");
    if (!content) alert("本文を入力してください");
    const result = await updateMail.execute({ key, subject, content });
    if (result instanceof Error) return alert(result.message);
    await mail.execute({}, { id: key });
    alert("送信しました");
  };

  useEffect(() => {
    if (!mail.data) return;
    if (mail.loading) return;
    const form = formRef.current;
    if (!form) return;
    form.subject.value = mail.data?.subject;
    form.content.value = mail.data?.content;
  }, [mail]);

  return (
    <Layout type="master">
      <MaillistContainer onSubmit={handleSubmit} ref={formRef}>
        <Header>メール</Header>

        <Label>
          <LabelText>件名</LabelText>
          <MailSubject name="subject" disabled={updateMail.loading} />
        </Label>
        <Label>
          <LabelText>本文</LabelText>
          <MailContent name="content" disabled={updateMail.loading} />
        </Label>

        <Button loading={updateMail.loading}>送信</Button>
        <ButtonPrev />
      </MaillistContainer>
    </Layout>
  );
};

export default Page;
