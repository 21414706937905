import React, { useEffect } from "react";
import Layout from "../themplate/Layout";
import styled, { css } from "styled-components";
import Container from "../parts/Container";
import Icon from "../parts/Icon";
import { Link, useSearchParams } from "react-router-dom";
import Image from "../parts/Image";
import useSearchUser from "../api/search/searchUser";
import useSearchProduct from "../api/search/searchProduct";
import useSearchProductplan from "../api/search/searchProductplan";
import useSearchTag from "../api/search/searchTag";
import useSearchProductWithTags from "../api/search/searchProductTag";
import SearchIcon from "../imgs/SearchIcon";

const StyledContainer = styled(Container)`
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 1000px;
  padding: 20px;
  box-sizing: border-box;
`;

const PagenationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
const PagenationItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  padding: 10px;
`;

const InputContainer = styled.form`
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const InputText = styled.span`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  display: flex;
`;
const Input = styled.input`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
`;
const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;
const Tab = styled.div<{ active?: boolean }>`
  display: flex;
  flex-basis: 30px;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 50px;
  position: relative;
  ${(p) =>
    p.active &&
    css`
      &:after {
        content: "";
        position: absolute;
        display: block;
        height: 2px;
        background-color: rgb(228, 168, 196);
        bottom: 0;
        left: 0;
        right: 0;
      }
    `}
`;
const SearchResultsShop = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
// const SearchResultFreeword = styled.div``;

const SearchResultShop = styled(Link)`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
`;
const SearchResultShopIcon = styled(Icon)`
  width: 100px;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 100px;
`;
const SearchResultShopTexts = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
`;
const SearchResultShopName = styled.div`
  width: 100%;
`;
const SearchResultShopDescription = styled.div``;

const SearchResultsProduct = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;
const SearchResultProduct = styled(Link)`
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
`;
const SearchResultProductIcon = styled(Image)``;
const SearchResultProductName = styled.div``;
const SearchResultProductDescription = styled.div``;

const SearchResultsProductplan = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;
const SearchResultProductplan = styled(Link)`
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
`;
const SearchResultProductplanIcon = styled(Image)``;
const SearchResultProductplanName = styled.div``;
const SearchResultProductplanDescription = styled.div``;

const SearchResultsTag = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  background-color: #ccc;
  gap: 10px;
  padding: 10px;
`;

const SearchResultTag = styled.div`
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  color: inherit;
`;

const SearchResultNotfound = styled.div`
  width: 100%;
  height: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const StyledSearchIcon = styled(SearchIcon)`
  width: 20px;
  height: 20px;
  color: skyblue;
  fill: currentColor;
  & > path {
    fill: currentColor;
    color: currentColor;
  }
`;

// const SearchResultFreewordIcon = styled(Image)``;
// const SearchResultFreewordName = styled.div``;
// const SearchResultFreewordDescription = styled.div``;
// const SearchResultFreewordLink = styled(Link)``;

const SearchResultText = styled.div``;

const tabs = [
  { name: "クリエイター・ショップ", key: "user" },
  { name: "商品名", key: "product" },
  { name: "継続商品", key: "productplan" },
  { name: "タグ検索", key: "tag" },
  // { name: "フリーワード", key: "freeword" },
];

interface Props {
  keyword: string;
  setPage: (page: string | number) => void;
}
const SearchResultShops: React.FC<Props> = (p) => {
  const { keyword } = p;
  const search = useSearchUser({ params: { searchType: "user", keyword } });
  useEffect(() => {
    search.execute({ searchType: "user", keyword });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);
  return (
    <SearchResultsShop>
      {search.data?.map?.((user) => (
        <SearchResultShop to={`/shop?userId=${user.id}`} key={user.id}>
          <SearchResultShopIcon src={user.shopProfile?.icon || ""} />
          <SearchResultShopTexts>
            <SearchResultShopName>
              {user.shopProfile?.name}
            </SearchResultShopName>
            <SearchResultShopDescription>
              {user.shopProfile?.introduction}
            </SearchResultShopDescription>
            {/* <SearchResultShopLink /> */}
          </SearchResultShopTexts>
        </SearchResultShop>
      ))}
      {search.data?.length === 0 && (
        <SearchResultNotfound>
          ユーザー・ショップが見つかりませんでした
        </SearchResultNotfound>
      )}
    </SearchResultsShop>
  );
};

const SearchResultProducts: React.FC<Props> = (p) => {
  const { keyword } = p;
  const search = useSearchProduct({
    params: { searchType: "product", keyword },
  });
  useEffect(() => {
    search.execute({ searchType: "product", keyword });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);
  return (
    <SearchResultsProduct>
      {search.data?.map?.((product) => (
        <SearchResultProduct to={`/product/${product.id}`}>
          <SearchResultProductIcon src={product?.thumbnail || ""} />

          <SearchResultProductName>{product?.name}</SearchResultProductName>
          <SearchResultProductDescription>
            {product.description}
          </SearchResultProductDescription>
          {/* <SearchResultShopLink /> */}
        </SearchResultProduct>
      ))}
      {search.data?.length === 0 && (
        <SearchResultNotfound>商品が見つかりませんでした</SearchResultNotfound>
      )}
    </SearchResultsProduct>
  );
};
const SearchResultProductplans: React.FC<Props> = (p) => {
  const { keyword } = p;
  const search = useSearchProductplan({
    params: { searchType: "productplan", keyword },
  });
  useEffect(() => {
    search.execute({ searchType: "productplan", keyword });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);
  return (
    <SearchResultsProductplan>
      {search.data?.map?.((product) => (
        <SearchResultProductplan to={`/productplan/${product.id}`}>
          <SearchResultProductplanIcon src={product?.thumbnail || ""} />

          <SearchResultProductplanName>
            {product?.name}
          </SearchResultProductplanName>
          <SearchResultProductplanDescription>
            {product.description}
          </SearchResultProductplanDescription>
        </SearchResultProductplan>
      ))}
      {search.data?.length === 0 && (
        <SearchResultNotfound>商品が見つかりませんでした</SearchResultNotfound>
      )}
    </SearchResultsProductplan>
  );
};
const SearchResultTags: React.FC<Props> = (p) => {
  const { keyword } = p;
  const [searchParams, setSearchParms] = useSearchParams();
  const paramssearch = searchParams.get("search") || "";
  const paramstab = searchParams.get("tab") || "user";
  const setTab = (tag: string) => () =>
    setSearchParms({ search: paramssearch, tab: paramstab, tag });
  const search = useSearchTag({
    params: { searchType: "tag", keyword },
  });
  useEffect(() => {
    search.execute({ searchType: "tag", keyword });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);
  return (
    <SearchResultsTag>
      {search.data?.map?.((tag) => (
        <SearchResultTag onClick={setTab(tag.name)}>{tag.name}</SearchResultTag>
      ))}
      {search.data?.length === 0 && (
        <SearchResultNotfound>タグが見つかりませんでした</SearchResultNotfound>
      )}
    </SearchResultsTag>
  );
};

const SearchResultProductsWithTag: React.FC<{
  tag: string;
  page: number;
  setPage: (page: string | number) => void;
}> = (p) => {
  const { tag, page } = p;
  const search = useSearchProductWithTags({
    params: { searchType: "product-tag", tag, page },
  });
  useEffect(() => {
    search.execute({ searchType: "product-tag", tag, page });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag, page]);
  const handleSetPage = (page: number) => () => p.setPage(page);
  return (
    <>
      <SearchResultsProduct>
        {search.data?.map?.((product) => (
          <SearchResultProduct to={`/product/${product.id}`}>
            <SearchResultProductIcon src={product?.thumbnail || ""} />

            <SearchResultProductName>{product?.name}</SearchResultProductName>
            <SearchResultProductDescription>
              {product.description}
            </SearchResultProductDescription>
            {/* <SearchResultShopLink /> */}
          </SearchResultProduct>
        ))}
        {search.data?.length === 0 && (
          <SearchResultNotfound>
            商品が見つかりませんでした
          </SearchResultNotfound>
        )}
      </SearchResultsProduct>
      <PagenationContainer>
        {page > 0 && (
          <PagenationItem onClick={handleSetPage(page - 1)}>
            {page}
          </PagenationItem>
        )}
        <PagenationItem onClick={handleSetPage(page)}>
          {page + 1}
        </PagenationItem>
        {search.data?.length && search.data?.length >= 10 && (
          <PagenationItem onClick={handleSetPage(page + 1)}>
            {page + 2}
          </PagenationItem>
        )}
      </PagenationContainer>
    </>
  );
};

const Page: React.FC = () => {
  // const [tabIndex, setTabIndex] = useState<number>(0);
  const [searchParams, setSearchParms] = useSearchParams();
  const search = searchParams.get("search") || "";
  const paramstab = searchParams.get("tab") || "user";
  const tag = searchParams.get("tag") || "";
  const page = parseInt((searchParams.get("page") as string) || "0") || 0;
  const setSearch = (search: string) =>
    setSearchParms({ search, tab: paramstab });
  const setTab = (tab: string) => setSearchParms({ search, tab });
  const setPage = (page: string | number) =>
    setSearchParms({ search, tab: paramstab, tag, page: `${page}` });

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const search = e.currentTarget.search.value;
    if (search.length < 2)
      return alert("検索ワードは2文字以上で入力してください");
    setSearch(search);
  };

  const handleTabClick = (tab: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    setTab(tab);
  };

  return (
    <Layout>
      <StyledContainer>
        <InputContainer onSubmit={handleSearch}>
          <InputText>検索</InputText>
          <StyledSearchIcon />
          <Input type="search" name="search" />
        </InputContainer>
        <Tabs>
          {tabs.map((tab, index) => (
            <Tab
              onClick={handleTabClick(tab.key)}
              key={index}
              active={tab.key === paramstab}
            >
              {tab.name}
            </Tab>
          ))}
        </Tabs>

        <SearchResultText>
          {search
            ? `「${search}」の${
                paramstab === "user"
                  ? "クリエーター・ショップ"
                  : paramstab === "product"
                  ? "商品"
                  : paramstab === "productplan"
                  ? "継続プラン商品"
                  : "タグ"
              }検索結果`
            : "検索ワードを入力してください"}
        </SearchResultText>

        {paramstab === "user" && (
          <SearchResultShops keyword={search || ""} setPage={setPage} />
        )}
        {paramstab === "product" && (
          <SearchResultProducts keyword={search || ""} setPage={setPage} />
        )}
        {paramstab === "productplan" && (
          <SearchResultProductplans keyword={search || ""} setPage={setPage} />
        )}
        {paramstab === "tag" && (
          <SearchResultTags keyword={search || ""} setPage={setPage} />
        )}
        {paramstab === "tag" && !!tag && (
          <SearchResultProductsWithTag
            tag={tag}
            setPage={setPage}
            page={page}
          />
        )}
      </StyledContainer>
    </Layout>
  );
};

export default Page;
