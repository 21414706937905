import styled, { keyframes } from "styled-components";
import clsx from "clsx";
const modalOpen = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ModalContainer = styled.div`
  display: none;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  &.open {
    display: block;
  }
`;

const ModalContent = styled.div`
  background-color: #f4f4f4;
  margin: 20% auto;
  width: 50%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
  animation-name: ${modalOpen};
  animation-duration: 1s;
  border-radius: 10px;
  @media screen and (max-width: 750px) {
    & {
      width: 90%;
    }
  }
`;
const ModalHeader = styled.div`
  background: lightblue;
  padding: 3px 15px;
  text-align: end;
  justify-content: space-between;
  border-radius: 10px 10px 0 0;
`;
const CloseButton = styled.div`
  font-size: 2rem;
  color: #fff;
  &:hover {
    cursor: pointer;
  }
`;
const ModalBody = styled.div`
  padding: 5% 20px;
  color: black;
  text-align: center;
  & h3 {
    margin: 0 0 5% 0 !important;
  }
`;
export const ModalTitle = styled.h3`
  margin: 0px;
`;
export const ModalText = styled.p`
  margin: 1% 0;
`;
export const ModalButton = styled.div.attrs((props) => ({
  className: clsx("btn", props.className),
}))`
  margin: 5% 0 0 0;
`;

interface ModalProps {
  open?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}
const Header: React.FC<ModalProps> = (props) => {
  const { open = false, onClose, children } = props;
  return (
    <>
      <ModalContainer className={clsx({ open })}>
        <ModalContent>
          <ModalHeader>
            <CloseButton onClick={onClose}>×</CloseButton>
          </ModalHeader>
          <ModalBody>{children}</ModalBody>
        </ModalContent>
      </ModalContainer>
    </>
  );
};

export default Header;
