import React from "react";
import { Link } from "react-router-dom";
import useGetUser from "../../../api/user/get";
import classes from "./CreatorAccount.module.scss";
import Container from "../../../parts/Container";

const Content: React.FC = () => {
  const user = useGetUser({ pathParams: { id: "me" } });
  return (
    <Container  width70L width95>
      <div className="main">
        <h2 className="page-title title-blank">アカウント情報</h2>

        <div className="information-wrap">
          <div className="information-box information-border information-contents ">
            <h3>ユーザーID</h3>
            <p>{user.data?.id}</p>
          </div>

          <div className="information-box information-border information-contents ">
            <h3>ユーザー名</h3>
            <p>{user.data?.profile?.nickname}</p>
          </div>

          <div className="information-box information-border account-icon information-contents">
            <h3>アイコン</h3>
            {user.data?.profile?.icon ? (
              <img
                src={user.data?.profile?.icon}
                alt=""
                className={classes.icon}
              />
            ) : (
              <p>アイコンが設定されていません</p>
            )}
          </div>

          <div className="information-box information-border information-contents">
            <h3>メールアドレス</h3>
            <p>{user.data?.email}</p>
          </div>
          <div className="information-box information-contents last-contents">
            <h3>パスワード</h3>
            <p>********</p>
          </div>
        </div>
      </div>
      <Container row  className="btn">
        <Link to="/creator">
          <input type="submit" className="back" value="戻る" />
        </Link>
        <Link to="/creator/account/edit">
          <input type="submit" value="アカウント管理" />
        </Link>
      </Container>
    </Container>
  );
};

export default Content;
