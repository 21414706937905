import useApi from "../useApi";

type DataType = {
  id: number;
  name: string;
};
interface Params {}

const path = "/category";
const method = "put";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
