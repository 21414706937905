import React from "react";
import { Link } from "react-router-dom";
import useListCategory from "../../../api/category/list";
import useDeleteCategory from "../../../api/category/delete";
import Container from "../../../parts/Container";
import Title from "../../../parts/Title";
import Button, { ButtonPrev } from "../../../parts/Button";
import Text from "../../../parts/Text";
import Space from "../../../parts/Space";
const Text2 = Text.withComponent("span");
const LinkButton = Button.withComponent(Link);

const Content: React.FC = () => {
  // const navigate = useNavigate();
  const category = useListCategory();
  const deleteCategory = useDeleteCategory();
  const refetch = () => category.execute();
  const handleDelete = (id: number) => async (e: React.MouseEvent) => {
    e.preventDefault();
    const result = await deleteCategory.execute(undefined, {
      id: id.toString(),
    });
    if (result instanceof Error)
      return alert(result?.message || "エラーが発生しました");
    await refetch();
  };

  return (
    <>
      <Container fullWidth>
        <Title>カテゴリー覧</Title>

        <LinkButton to="/master/category/add">カテゴリ　追加</LinkButton>
        {category.data?.map((category) => (
          <Container row backgroundGrayOdd alignCenter>
            <Text2>{category.name}</Text2>
            <Space />
            <Button onClick={handleDelete(category.id)}>削除</Button>
          </Container>
        ))}

        <ButtonPrev>戻る</ButtonPrev>
      </Container>
    </>
  );
};

export default Content;
