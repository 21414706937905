import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Modal, {
  ModalTitle,
  ModalButton,
  ModalText,
} from "../../../parts/Modal";
import useGetTag from "../../../api/tag/get";
import useUpdateTag from "../../../api/tag/update";

const Content: React.FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [tag, setTag] = useState<string>("");
  const [modal, setModal] = useState<boolean>(false);
  const closeModal = () => setModal(false);
  const openModal = () => {
    if (!tag) {
      alert("タグ名を入力してください。");
      return;
    }
    setModal(true);
  };
  const updateTag = useUpdateTag();
  const getTag = useGetTag({ pathParams: { id } });

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement | HTMLInputElement>
  ) => {
    e.preventDefault();
    const r = await updateTag.execute({
      id: parseInt(id),
      name: tag,
    });
    if (r instanceof Error) return;
    navigate(-1);
  };

  useEffect(() => {
    if (!getTag.data) return;
    if (!getTag.called) return;
    if (getTag.loading) return;
    if (getTag.error) return;
    setTag(getTag.data.name);
  }, [getTag.data, getTag.called, getTag.loading, getTag.error]);

  // const tag = [{ id: 1, name: "タグ1" }];

  return (
    <>
      <div className="wrap">
        <div className="main">
          <h2>商品タグ編集</h2>

          <div className="information-box">
            <h3>タグ名</h3>
            <input
              size={20}
              type="text"
              className="wide tag"
              value={tag}
              onChange={(e) => setTag(e.target.value)}
            />
          </div>
        </div>

        <div id="creator-side-menu"></div>
      </div>
      <div className="btn">
        <Link to="/creator/product/tag">
          <input type="submit" className="back" value="戻る" />
        </Link>
        <span>
          <input type="submit" onClick={openModal} value="商品タグ編集確認" />
        </span>
      </div>
      <Modal open={modal} onClose={closeModal}>
        <ModalTitle>タグ名を変更します</ModalTitle>
        <ModalText>ユーザー名: {tag}</ModalText>
        <ModalButton>
          <span>
            <input
              type="submit"
              className="back"
              onClick={closeModal}
              value="戻る"
            />
          </span>
          <span>
            <input type="submit" onClick={handleSubmit} value="変更" />
          </span>
        </ModalButton>
      </Modal>
    </>
  );
};

export default Content;
