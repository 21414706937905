import React from "react";
import useAddMessage from "../../../api/message/add";
import Container from "../../../parts/Container";
import Title from "../../../parts/Title";
import Button, { ButtonPrev } from "../../../parts/Button";
import Input from "../../../parts/Input";
import Text from "../../../parts/Text";
import useMessages from "../../../api/message/list";
import useDeleteMessage from "../../../api/message/delete";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/ja";

const InputMultiline = styled(Input.withComponent("textarea"))`
  width: 100%;
  box-sizing: border-box;
`;

const NotificationListTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const NotificationList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
`;
const Notification = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: #000000;
  padding: 10px;
  border-radius: 10px;
  box-sizing: border-box;
`;
const NotificationTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
`;
const NotificationMessage = styled.div`
  font-size: 16px;
  white-space: pre-wrap;
  word-break: break-all;
  overflow-wrap: break-word;
`;
const NotificationDate = styled.div``;

const Content: React.FC = () => {
  // const navigate = useNavigate();
  const addMessage = useAddMessage();
  const [title, setTitle] = React.useState("");
  const [message, setMessage] = React.useState("");

  const messages = useMessages();
  const deleteMessage = useDeleteMessage();

  const clear = () => {
    setTitle("");
    setMessage("");
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const result = await addMessage.execute({ message, title, to: "all" });
    if (result instanceof Error) return alert("エラー");
    clear();
    alert("送信しました");
  };
  const handleDelete =
    (id: number) => async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      const result = await deleteMessage.execute({}, { id: String(id) });
      if (result instanceof Error) return alert("エラー");
      alert("削除しました");
      await messages.execute();
    };

  return (
    <>
      <Container fullWidth>
        <Title>全員へメッセージ追加</Title>
        <Text>ラベル</Text>
        <Input value={title} onChange={(e) => setTitle(e.target.value)} />

        <Text>内容</Text>
        <InputMultiline
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Container row>
          <ButtonPrev>戻る</ButtonPrev>
          <Button onClick={handleSubmit} loading={addMessage.loading}>
            送信
          </Button>
        </Container>

        <NotificationList>
          <NotificationListTitle>通知一覧</NotificationListTitle>
          {messages.data?.map((message) => (
            <Notification key={message.id}>
              <NotificationTitle>{message.title}</NotificationTitle>
              <NotificationMessage>{message.message}</NotificationMessage>
              <NotificationDate>
                {moment(message.createdAt).format("YYYY/MM/DD HH:mm:SS")}
              </NotificationDate>
              {/* <NotificationTo>{!message.to && "全員"}</NotificationTo> */}
              <Button
                onClick={handleDelete(message.id)}
                loading={deleteMessage.loading}
              >
                削除
              </Button>
            </Notification>
          ))}
        </NotificationList>
      </Container>
    </>
  );
};

export default Content;
