import styled from "styled-components";
export const FileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: scroll;
  position: relative;
  &::after {
    content: "";
    position: sticky;
    width: 100%;
    height: 100px;
    flex-shrink: 0;
    z-index: 2;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 100%
    );
  }
`;

export default FileContainer;
