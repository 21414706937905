import useApi from "../useApi";
import User from "../../types/User";

type DataType = User[];
interface Params {
  type?: string;
  limit?: number;
  unapproved?: boolean;
  search?: string;
}
// export default useApi<>();

const path = "/user";
const method = "get";
const eagerLoad = true;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
