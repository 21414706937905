import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal, {
  ModalTitle,
  ModalButton,
  ModalText,
} from "../../../parts/Modal";
import useAddCategory from "../../../api/category/add";

const Content: React.FC = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState<string>("");
  const [modal, setModal] = useState<boolean>(false);
  const closeModal = () => setModal(false);
  const openModal = () => {
    if (!category) {
      alert("カテゴリ名を入力してください。");
      return;
    }
    setModal(true);
  };
  const addCategory = useAddCategory();

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement | HTMLInputElement>
  ) => {
    e.preventDefault();
    const r = await addCategory.execute({ name: category });
    if (r instanceof Error) return;
    navigate(-1);
  };

  // const category = [{ id: 1, name: "カテゴリ1" }];

  return (
    <>
      <div className="wrap">
        <div className="main">
          <h2 className="page-title title-blank">商品カテゴリ新規追加</h2>
          <div className="information-wrap">
            <div className="information-box category">
              <h3 className="category">追加カテゴリを入力</h3>
              <input
                size={20}
                type="text"
                className="wide category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />
            </div>

            <div className="btn">
              <Link to="/creator/product/category">
                <input type="submit" className="back" value="戻る" />
              </Link>
              <span>
                <input
                  type="submit"
                  onClick={openModal}
                  value="商品カテゴリ作成確認"
                />
              </span>
            </div>
          </div>
        </div>
      </div>

      <Modal open={modal} onClose={closeModal}>
        <ModalTitle>カテゴリを追加します。</ModalTitle>
        <ModalText>ユーザー名: {category}</ModalText>
        <ModalButton>
          <span>
            <input
              type="submit"
              className="back"
              onClick={closeModal}
              value="戻る"
            />
          </span>
          <span>
            <input type="submit" onClick={handleSubmit} value="変更" />
          </span>
        </ModalButton>
      </Modal>
    </>
  );
};

export default Content;
