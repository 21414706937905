import React from "react";
import { Link } from "react-router-dom";
import useListCategory from "../../../api/category/list";

const Content: React.FC = () => {
  // const navigate = useNavigate();
  const category = useListCategory();

  return (
    <>
      <div className="wrap">
        <div className="main">
          <div className="btn list-add">
            <h2 className="page-title">カテゴリー覧</h2>
            <Link to="/creator/category/add">
              <input type="submit" className="add" value="+カテゴリ追加" />
            </Link>
          </div>
          <div className="information-box">
            <table>
              {category.data?.map((category, index) => (
                <tr key={index}>
                  <th className="product-name">
                    <p>{category.name}</p>
                  </th>
                  <td>
                    <Link to={`/creator/category/${category.id}`}>
                      <input type="submit" className="edit" value="編集" />
                    </Link>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>

        <div id="creator-side-menu"></div>
      </div>
      <div className="btn">
        <Link to="/creator/categorytag">
          <input
            type="submit"
            className="back"
            // onclick="location.href='creator-category-tag.html'"
            value="戻る"
          />
        </Link>
      </div>
    </>
  );
};

export default Content;
