import React from "react";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

const ButtonPrev: React.FC<React.ComponentProps<typeof Button>> = (props) => {
  const navigate = useNavigate();
  return (
    <Button
      as="a"
      color="info"
      onClick={() => navigate(-1)}
      children="戻る"
      {...props}
    />
  );
};

export default ButtonPrev;
