import React from "react";
import Container from "../../parts/Container";
import classes from "./Tarm.module.scss";

const Content: React.FC = () => {
  return (
    <Container fullWidth>
      <div id="wrapper">
        <h1 className={classes.title}>利用規約</h1>

        <div className="information-box information-border ">
          <h2 className="article-heading common-title">はじめに</h2>
          <p>
            当サイトを利用する販売者様は、以下の点について十分ご理解をいただいた上でサービスをご利用ください。
          </p>
        </div>
        <div className="information-box information-border ">
          <ul className="term-list">
            <li>
              ・投稿されたコンテンツはトラブル防止のため、全て運営にて目視で確認しています。
            </li>
            <li>
              ・無修正画像・無断転載画像の投稿は絶対におやめください。悪質な行為に対しては厳正に対処させていただきます。
            </li>
            <li>
              ・実写コンテンツを投稿する場合は必ず年齢確認を必要といたします。
            </li>
            <li>
              ・投稿者及び出演者が18歳未満である実写コンテンツは投稿できません。
            </li>
          </ul>
        </div>
        <div className="information-box">
          <p>
            当サイトには、成人向けコンテンツが含まれています。 <br />
            成人向けコンテンツの修正に関するトラブルを防止するため、ファニーファンズでは投稿されたコンテンツを全て目視で確認させていただいております。{" "}
            <br />
            これにより、コンテンツの修正漏れを防ぐと共に、修正不十分によりファニーファンズ全体が運営できなくなるような事態や、無断転載などの違法コンテンツの登録を一定数防ぐことができます。{" "}
            <br />
            今後も皆様に安心してご利用いただけるよう、チェックについては万全を期して参ります。{" "}
            <br />
            コンテンツの投稿の際につきましては、投稿ガイドラインをお読みいただいた上での投稿をお願いいたします。
          </p>
        </div>
        <div className="information-box ">
          <h2 className="article-heading common-title ">
            成人向けならびに実写コンテンツの投稿・コンテンツに関して
          </h2>
          <p>
            ファニーファンズでは、全てのコンテンツの投稿につきまして、18歳未満および高校在学中の方のご利用はお断り（※1）させていただいております。
          </p>
          <p className="top-blank">
            <strong>実写コンテンツの投稿の場合</strong>
          </p>
          <p>
            実写コンテンツを投稿される場合、成人向けコンテンツを扱わない場合であっても、18歳未満のクリエイター登録はお断り（※2）させていただいております。
            また、18歳以上の方であっても、クリエイター登録には身分証のご提出をお願いしておりますので、ご了承ください。
            （※1）18歳の高校生の場合、「卒業月の月末まで」を18歳未満の期間として扱わせていただきます。
            （※2）年齢詐称等により不利益が生じた場合でも、弊社では一切の責任を負いかねますのでご了承ください。
          </p>
          <p className="top-blank">
            <strong>実写コンテンツ以外の投稿の場合</strong>
          </p>
          <p>
            クリエイター登録は、18歳以上（高校生不可）とさせていただきます。またクリエイター登録時には運営会社（株式会社rara）と業務委託契約を締結させていただいた上でご利用いただきます。
          </p>
        </div>
        <div className="information-box ">
          <h2 className="article-heading common-title">
            「成人向け」と判断されるコンテンツの基準について
          </h2>
          <p>
            ファニーファンズにおきましては、「性的好奇心をそそるための行為を表す場面や衣服を脱いだ状態」が含まれるコンテンツを成人向けと判断させていただいております。
          </p>
          <p className="top-blank">具体的には </p>
          <ul className="term-list">
            <li>・性器を露出する行為または、露出を連想させる行為</li>
            <li>
              ・主に女性、及び女性でなくても一見女性であると認識する可能性がある状況において、乳房を露出する行為
            </li>
            <li>
              ・その他、性、または性器の結合や接合、性行為などを想起させるもの
            </li>
            <li>
              ・その他、公然わいせつに該当する恐れがあるなど、運営が不適切と判断したもの
            </li>
          </ul>
          <p>
            などが該当します。
            その他の状況を総合的に判断しますので、個別の要素のみ抜き出しての回答は基本的にいたしかねます。
            <br />
            予めご了承ください。
            また、上記以外にも、過激な暴力表現（特に生物の肉体についてひどい損傷が確認できるもの、あるいは生物の内臓が明らかに確認できるもの）につきましても成人向けに該当します。
          </p>
        </div>
        <div className="information-box  ">
          <h2 className="article-heading common-title">
            局部修正に関するご案内
          </h2>
          <p>
            近頃、成人向けコンテンツにおける修正箇所の配慮が、社会的に求められてきている傾向がございます。
            <br />
            本サービス監視の過程にて、運営の判断で修正の対応をお願いする場合がございますこと、予めご了承下さい。
            <br />
            下記に当てはまるコンテンツは非公開もしくは削除させていただく場合がございます。
          </p>
          <p className="top-blank">
            <strong>投稿・コンテンツ禁止事項</strong>
          </p>
          <ul className="term-list">
            <li>
              ・局部の修正が未処理のもの、処理が不十分、またはまぎらわしいコンテンツ
              ※3DCGや画像生成AIによって出力された実写に近い写実的なコンテンツを含む
            </li>

            <li>・説明が不明瞭なコンテンツや投稿</li>
            <li>・食品類や健康食品といった食品の売買または送付</li>
            <li>
              ・過度の猥褻性がある表現、または過度に歪んだ状態での性表現コンテンツ
            </li>
            <li>
              ・刺激が強く嫌悪感や不快感を与える暴力的または残虐なコンテンツ
            </li>
            <li>・極端な思想や反社会的行為を賛美・助長するコンテンツ</li>
            <li>
              ・人種、信条、職業、性別、宗教などの差別を賛美・助長するコンテンツ
            </li>
            <li>
              ・カルト的宗教活動、過度な政治活動を賛美・助長するコンテンツ
            </li>
            <li>
              ・異性・同性を問わず、出会い、わいせつ行為、売買春・援助交際を目的とし、または誘引するコンテンツ
            </li>
            <li>
              ・その他本サービス上で提供される表現として適切でないと認められるコンテンツ
            </li>
          </ul>
          <p>
            <strong>イラスト、３Ｄコンテンツに関する禁止事項</strong>
          </p>
          <ul className="term-list">
            <li>
              ・未統合、非表示レイヤーなどで描かれた局部周辺の修正が不適切なデータ及びその意図があるデータの頒布
            </li>

            <li>
              ・隠蔽処理が適切でないデータを閲覧・ＤＬできる外部サイトへ誘導する行為
            </li>
          </ul>

          <p className="top-blank">
            <strong>成人向け実写コンテンツの取扱いに関する禁止事項</strong>
          </p>
          <ul className="term-list">
            <li>
              ・被写体を誹謗中傷する目的で投稿された肖像権、意匠権を侵害するコンテンツの投稿
            </li>
            <li>・被写体または撮影者の許可を得ていないコンテンツの投稿</li>
            <li>
              ・被写体が成人だと断定できない投稿
              ※3DCGや画像生成AIによって出力された実写に近い写実的なコンテンツを含みます
            </li>
            <li>
              ・成人向け実写コンテンツの撮影場所としてふさわしくない場所(屋外、公共施設等)で撮影されたコンテンツの投稿
            </li>
            ・撮影禁止場所など無断撮影がされたコンテンツの投稿
            <li>・性器に密着する衣装の売買または送付</li>
            <li>・自殺、自傷行為を助長する過度な表現を含むコンテンツの投稿</li>
            <li>
              ・違法薬物、脱法薬物の利用または薬物の不適切な利用を助長する表現を含むコンテンツの投稿
              ※上記に該当しない場合でも削除対象となる場合がありますので予めご了承ください。
              個別の具体的な事例に関する掲載の可否については回答いたしかねます。予めご了承ください。
            </li>
          </ul>

          <p>以下に従って適切な修正を行ってください。</p>

          <p className="top-blank">
            <strong>投稿・コンテンツ禁止事項</strong>
          </p>
          <ul className="term-list">
            <li>
              ・局部の修正が未処理のもの、処理が不十分、またはまぎらわしいコンテンツ
              ※3DCGや画像生成AIによって出力された実写に近い写実的なコンテンツを含む
            </li>

            <li>・説明が不明瞭なコンテンツや投稿</li>
            <li>・食品類や健康食品といった食品の売買または送付</li>
            <li>
              ・過度の猥褻性がある表現、または過度に歪んだ状態での性表現コンテンツ
            </li>
            <li>
              ・刺激が強く嫌悪感や不快感を与える暴力的または残虐なコンテンツ
            </li>
            <li>・極端な思想や反社会的行為を賛美・助長するコンテンツ</li>
            <li>
              ・人種、信条、職業、性別、宗教などの差別を賛美・助長するコンテンツ
            </li>
            <li>
              ・カルト的宗教活動、過度な政治活動を賛美・助長するコンテンツ
            </li>
            <li>
              ・異性・同性を問わず、出会い、わいせつ行為、売買春・援助交際を目的とし、または誘引するコンテンツ
            </li>
            <li>
              ・その他本サービス上で提供される表現として適切でないと認められるコンテンツ
            </li>
          </ul>
          <p className="top-blank">
            <strong>イラスト、３Ｄコンテンツに関する禁止事項</strong>
          </p>
          <ul className="term-list">
            <li>
              ・未統合、非表示レイヤーなどで描かれた局部周辺の修正が不適切なデータ及びその意図があるデータの頒布
            </li>

            <li>
              ・隠蔽処理が適切でないデータを閲覧・ＤＬできる外部サイトへ誘導する行為
            </li>
          </ul>

          <p className="top-blank">
            <strong>隠蔽処理が必要な個所の概要</strong>
          </p>
          <ul className="term-list">
            <li>・人間、動物に限らず全ての性器及び性器を連想する部分</li>
            <li>・性器、アヌスへの性器・異物挿入及び結合部分</li>

            <li>・過度に表現された嫌悪感や不快感を与える残虐な部分</li>
            <li>・性器で詳細に描かれたものと総合的に判断された部分</li>
          </ul>

          <p className="top-blank">
            <strong>イラスト・３Ｄコンテンツ等の性器への修正</strong>
          </p>
          <p className="top-blank">
            <strong>【男性器の修正】</strong>
          </p>
          <p>
            カリ部分を含む男性器に対して太目の「黒塗り」あるいは「白塗り」で複数線の修正、もしくは性器が見えない程度の「モザイク」による修正を実施して下さい。
          </p>

          <p className="top-blank">
            <strong>【女性器の修正】</strong>
          </p>
          <p>
            クリトリス部分を含む女性器に対して太目の「黒塗り」あるいは「白塗り」で複数線の修正、もしくは性器が見えない程度の「モザイク」による修正を実施して下さい。
          </p>
          <p className="top-blank">
            <strong>【動物、ケモノコンテンツの修正】</strong>
          </p>
          <p>
            性器に対して太目の「黒塗り」あるいは「白塗り」で複数線の修正、もしくは性器が見えない程度の「モザイク」による修正を実施して下さい。
          </p>
          <p className="top-blank">
            <strong>
              実写コンテンツの性器への修正
              ※3DCGや画像生成AIによって出力された実写に近い写実的なコンテンツを含みます
            </strong>
          </p>
          <ul className="term-list">
            <li>
              ・性器全体を完全に見えない状態にするか、「モザイク」または「強目のぼかし」による修正を実施して下さい。
            </li>
          </ul>

          <p className="top-blank">
            <strong>挿入に対する修正</strong>
          </p>
          <ul className="term-list">
            <li>
              ・「女性器」に対して「男性器」「触手」「道具」などの挿入・接触が行われる箇所に対して太目の「黒塗り」あるいは「白塗り」で複数線の修正、もしくは性器が見えない程度の「モザイク」による修正を実施して下さい。
            </li>
            <li>
              ・「アヌス」に対して「男性器」「異物」などの挿入・接触が行われる箇所に対して太目の「黒塗り」あるいは「白塗り」で複数線の修正、もしくは性器が見えない程度の「モザイク」による修正を実施して下さい。
              ※上記に該当しない場合でも削除対象となる場合がありますので予めご了承ください。
              修正が不十分のままの再公開や、外部誘導を繰り返す行為は禁止しています。
            </li>
          </ul>
        </div>

        <div className="information-box ">
          <h2 className="article-heading common-title">登録時の確認</h2>
          <p>
            ファニーファンズ登録時は株式会社raraとの業務委託契約が必要です。
            <br />
            実写コンテンツを投稿されるクリエイター様には、昨今の規制強化及び法令遵守の観点から、
            <br />
            年齢確認書類のご提出及び、利用規約・投稿ガイドラインへの同意をお願いしております。
            <br />
            実写コンテンツを対象とするカテゴリでファンクラブを新規開設する場合、成人向け・一般向けを問わず登録時に年齢確認書類の提出が必要です。年齢確認は業務委託契約時に行います。
          </p>

          <p className="top-blank">
            <strong>
              ▼年齢確認書類として有効なもの ※顔写真付きの書類が必要です
            </strong>
          </p>
          <ul className="term-list">
            <li>・運転免許証</li>
            <li>・「日本国発行のパスポート</li>
            <li>・運転免許証</li>
            <li>・「マイナンバーカード（表面のみ）</li>
            <li>・在留カードまたは外国人登録証明書（日本国籍ではない方）</li>
            <li>・特別永住者証明書</li>
            <li>・障害者手帳</li>
            <li>・顔写真付き住民基本台帳カード</li>
          </ul>

          <p>
            ※上記年齢確認書類の場合でも、
            <span className="red">
              有効期限が経過したものや、画像が加工されているものは無効です。
            </span>
            <br />
            画像が加工されている確認書類の提出が確認された場合、開設をお断りさせていただきます。
            <br />
            他人の身分証を登録する、加工した身分証を登録するといった悪質な行為に対しては厳正に対処させていただきます。{" "}
          </p>
          <p className="top-blank">
            ※ご提出いただいた年齢確認書類につきましては、利用規約及びプライバシーポリシーに従い厳重に管理いたします。
          </p>
          <p className="top-blank">
            ※法人でご登録いただいている場合は、代表者様の年齢確認書類をご提出ください。
          </p>
          <p className="top-blank">
            ※海外在住の方は、公的機関が発行した上記の書類に準ずる顔写真付きの身分証の提出をお願いします。
          </p>
          <p className="top-blank">
            ※お振込先の銀行口座情報と同一の氏名が確認できる本人確認書類をご提出ください。
          </p>
          <p className="top-blank">
            例）クリエイター様ご本人と別の方の振込口座を利用する場合
            ：クリエイター様ご本人と、振込先口座保持者の本人確認書類２つをご提出ください
            クリエイター様ご本人とは別の法人口座を利用する場合
            ：クリエイター様ご本人の本人確認書類と、
            振込先口座をお持ちの法人の履歴事項全部証明書の２つをご提出ください。
            <br />
            実写コンテンツにつきましては、誤解を招きやすい部分がございますので、慎重な対応を取らせていただいております。
          </p>
          <p className="top-blank">
            {" "}
            昨今の風潮などもあり、誠に恐れ入りますが、今後もクリエイターの皆様が安心して活動いただけるよう、ご理解・ご協力いただけますと幸いです。
            ファニーファンズ運営事務局
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Content;
