import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import useProduct from "../../api/product/get";
import useUser from "../../api/user/get";
import usePurchase from "../../api/purchase/add";
import Button, { ButtonPrev } from "../../parts/Button";
import Container from "../../parts/Container";
import Text from "../../parts/Text";
import Img from "../../parts/Img";
import { Link } from "react-router-dom";

const LinkedButton = Button.withComponent(Link);

const Content: React.FC = () => {
  const { id = "" } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const productId = parseInt(id);
  const product = useProduct({ pathParams: { id } });
  const user = useUser({ pathParams: { id: "me" } });
  const purchase = usePurchase();
  const balance = user.data?.balance || 0;
  const price = product.data?.price || 0;

  const handlePurchase = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const result = await purchase.execute({ productId });
    if (result instanceof Error) {
      console.log(result);
      if (result.message === "insufficient balance")
        return alert("FFコインが足りません");
      if (result.message === "dupulicate: purchase")
        return alert("購入済みです");
      return alert("エラーが発生しました");
    }
    navigate(`/product/${productId}`);
  };

  const isPurchasable = balance - price >= 0;

  return (
    <Container width70L width95>
      <Text>注文確認</Text>
      <Container card round>
        <Text center>ご注文内容</Text>
        <Container row noPadding>
          <Img
            src={product.data?.thumbnail || ""}
            square
            cover
            width={150}
            borderd
          />
          <Container noPadding>
            <Text>商品名</Text>
            <Text small>{price}C</Text>
            <Text>現在のFFコイン</Text>
            <Text>{balance}C</Text>
            <Text>決済後のFFコイン</Text>
            <Text>{balance - price}C</Text>
          </Container>
        </Container>
      </Container>
      <Container row justifyCenter>
        <ButtonPrev>戻る</ButtonPrev>

        {!isPurchasable ? (
          <LinkedButton to="/charge">コインをチャージする</LinkedButton>
        ) : (
          <Button onClick={handlePurchase} loading={user.loading}>
            購入確定
          </Button>
        )}
      </Container>
    </Container>
  );
};

export default Content;
