import Content from "../../components/Creator/PlanEdit";
import Layout from "../../themplate/Layout";

const Page: React.FC = () => (
  <Layout type="creator">
    <Content />
  </Layout>
);

export default Page;
