import React from "react";
import { Link } from "react-router-dom";
import Slider from "./Slider";
import styled from "styled-components";
import useProducts from "../../api/product/list";
import useProductplans from "../../api/productplan/list";
import { useAuth } from "../../libs/auth";

const Notetion = styled.div`
  background-color: #fe8346;
  color: #ffffff;
  padding: 10px;
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  text-overflow: ellipsis;
  text-wrap: wrap;
  white-space: pre-wrap;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Button = styled.button`
  margin: 0 auto;
`;

const ProductListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  padding: 0 15% 10%;
`;
const ProductlistItem = styled(Link)`
  padding: 10px;
  width: calc(25% - 7.5px);
  flex-basis: calc(25% - 7.5px);
  flex-grow: 0;
  background: #edf8ff;
  justify-content: start;
  gap: 10px;
  border-radius: 10px;
  @media (max-width: 768px) {
    width: calc(50% - 5px);
    flex-basis: calc(50% - 5px);
  }
  position: relative;
`;
const ProductlistImage = styled.div<{ src: string }>`
  width: 100%;
  position: relative;
  &:before {
    content: "";
    display: block;
    padding-top: 100%;
    width: 100%;
  }
  &:after {
    content: "";
    display: block;
    background: url(${({ src }) => src});
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
const ProductlistImageText = styled.div`
  z-index: 1;
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  font-size: 15px;
  line-height: 1;
  padding: 5px 10px;
  background: skyblue;
`;
const ProductlistTitle = styled.div`
  font-size: 1.2rem;
  font-color: #333;
  font-weight: bold;
  text-align: center;
`;
const ProductlistPrice = styled.div`
  text-align: center;
`;
const ProductlistDescription = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  height: 16px;

  overflow: hidden;
  text-overflow: ellipsis;
`;

const Home: React.FC = () => {
  const products = useProducts({ params: { latest: true } });
  const productplans = useProductplans({ params: { latest: true } });
  const { isLogin, isCreator, user } = useAuth();

  return (
    <div>
      {/* <div id="header"></div> */}
      {isCreator &&
        !user.data?.shopProfile?.icon &&
        !user.data?.shopProfile?.name &&
        !user.data?.shopProfile?.introduction && (
          <Notetion>
            {
              "クリエーターに変更されたため、お手数ですが、上記ショップアイコンからショップ名などの設定を行ってください。\n※ショップ名を設定しない場合、検索に表示されません。"
            }
          </Notetion>
        )}
      <Slider />
      <div className="dots" />
      <div id="wrapper">
        <main>
          <div className="overview">
            <img
              src="/asset/img/logo.png"
              className="overview-logo"
              alt="ロゴ"
              title="ロゴ"
            />
            <p>
              ファニーファンズ[Funny Fans]は、
              <br />
              イラストレーターやコスプレイヤーなど、創作活動を軸に活躍するクリエイターの応援やコニュニティー作りに最適な場所です。
              <br />{" "}
              誰でも簡単に無料登録でき、クリエイターを応援することができます。
            </p>

            {!isLogin && (
              <div className="add-button">
                <StyledLink to="/register">
                  <Button className="add-button">無料会員登録</Button>
                </StyledLink>
              </div>
            )}
          </div>
          <h2>新着商品一覧</h2>
          <ProductListContainer>
            {products.data?.slice(0, 4)?.map((product) => (
              <ProductlistItem to={`/product/${product.id}`}>
                <ProductlistImageText>new</ProductlistImageText>
                <ProductlistImage src={product.thumbnail} />
                <ProductlistTitle>{product.name}</ProductlistTitle>
                <ProductlistPrice>￥{product.price}</ProductlistPrice>
                <ProductlistDescription>
                  {product.description}
                </ProductlistDescription>
              </ProductlistItem>
            ))}
          </ProductListContainer>
          <div className="more-button">
            <Link to="/productnew" className="btn-flat-more">
              <i className="fa fa-caret-right" />
              もっと見る
            </Link>
          </div>
          <div className="overview" />
          <h2>新着プラン投稿</h2>
          <ProductListContainer>
            {productplans.data?.slice(0, 4)?.map((productplan) => (
              <ProductlistItem to={`/productplan/${productplan.id}`}>
                <ProductlistImageText>{productplan.name}</ProductlistImageText>
                <ProductlistImage src={productplan.thumbnail} />
                <ProductlistTitle>{productplan.name}</ProductlistTitle>
                <ProductlistDescription>
                  {productplan.description}
                </ProductlistDescription>
              </ProductlistItem>
            ))}
          </ProductListContainer>
          {!isLogin && (
            <div className="add-button">
              <StyledLink to="/register">
                <Button className="add-button">新規登録はこちら</Button>
              </StyledLink>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default Home;
