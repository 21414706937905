import { ReactNode } from "react";
import HomePage from "../pages/HomePage";
import Landing from "../pages/Landing";
import LandingCreator from "../pages/LandingCreator";
import LoginPage from "../pages/LoginPage";
import SignupPage from "../pages/SignupPage";
import Leave from "../pages/Leave";
import PasswordResetPage from "../pages/PasswordResetPage";
import PasswordResetCode from "../pages/PasswordResetCode";
import Product from "../pages/Product";
import ProductUser from "../pages/ProductUser";
import ProductBuy from "../pages/ProductBuy";
import ProductPlan from "../pages/ProductPlan";
import ProductPlans from "../pages/ProductPlans";
import Company from "../pages/Company";
import CardAdd from "../pages/member/CardAdd";
import Contact from "../pages/Contact";
import ProductListPage from "../pages/ProductListPage";
import ProductListNewPage from "../pages/ProductListNewPage";
import ProductListPickupPage from "../pages/ProductListPickupPage";
import MasterLoginPage from "../pages/master/LoginPage";
import MasterHomePage from "../pages/master/HomePage";
import UserListPage from "../pages/master/UserlistPage";
import UserAddPage from "../pages/master/UserAddPage";
import UserEditPage from "../pages/master/UserEditPage";
import UserMember from "../pages/master/Member";
import UserUnapproved from "../pages/master/UserUnapproved";
import UserUnapprovedList from "../pages/master/UserUnapprovedList";
import UserApproval from "../pages/master/UserApproval";
import CreatorTopPage from "../pages/creator/HomePage";
import AccountPage from "../pages/creator/AccountPage";
import AccountEditPage from "../pages/creator/AccountEditPage";
import CreatorProductList from "../pages/creator/ProductListPage";
import CreatorProductAdd from "../pages/creator/ProductAddPage";
import CreatorProductEdit from "../pages/creator/ProductEdit";
import CreatorOrderList from "../pages/creator/OrderListPage";
import CreatorOrderDetail from "../pages/creator/OrderDetail";
import CreatorSales from "../pages/creator/Sales";
import BankAccount from "../pages/creator/Bank";
import CreatorSalesMethod from "../pages/creator/SalesMethod";
import CreatorSalesMethodEdit from "../pages/creator/SalesMethodEdit";
import CreatorSetting from "../pages/creator/SettingPage";
import CategoryTag from "../pages/creator/CategoryTag";
import CreatorMessageSetting from "../pages/creator/MessageSettingPage";
import NotFound from "../pages/NotFoundPage";
import CategoryList from "../pages/creator/CategoryList";
import CategoryAdd from "../pages/creator/CategoryAdd";
import CategoryEdit from "../pages/creator/CategoryEdit";
import TagAdd from "../pages/creator/TagAdd";
import TagEdit from "../pages/creator/TagEdit";
import TagList from "../pages/creator/TagList";
import MosaicList from "../pages/master/MosaicList";
import MosaicListAccount from "../pages/master/MosaicListAccount";
import MosaicListProduct from "../pages/master/MosaicListProduct";
import MosaicListProductplan from "../pages/master/MosaicListProductplan";
import MosaicListSales from "../pages/master/MosaicListSales";
import Member from "../pages/member/Home";
import AccountEdit from "../pages/member/AccountEdit";
import AuthConfirm from "../pages/member/AuthConfirm";
import Privacypolicy from "../pages/Privacypolicy";
import Trade from "../pages/Trade";
import Commingsoon from "../pages/CommingSoon";
import ShopInformation from "../pages/creator/ShopInformation";
import ShopInformationEdit from "../pages/creator/ShopInformationEdit";
import ProductPlanAdd from "../pages/creator/ProductPlanAdd";
import ProductPlanList from "../pages/creator/ProductPlanList";
import ProductPlanEdit from "../pages/creator/ProductPlanEdit";
import Payment from "../pages/Payment";
import PaymentDone from "../pages/PaymentDone";
import PaymentError from "../pages/PaymentError";
import QA from "../pages/QA";
import MasterCategories from "../pages/master/Categories";
import MasterCategoryAdd from "../pages/master/CategoryAdd";
import CreatorPlan from "../pages/creator/Plan";
import CreatorPlanAdd from "../pages/creator/PlanAdd";
import CreatorPlanEdit from "../pages/creator/PlanEdit";
import Plan from "../pages/member/Plan";
import PlanJoin from "../pages/member/PlanJoin";
import PlanLeave from "../pages/member/PlanLeave";
import Plans from "../pages/member/Plans";
import CreatorHome from "../pages/CreatorHome";
import SendMessage from "../pages/master/SendMessage";
import Message from "../pages/member/Message";
import Messages from "../pages/member/Messages";
import SendNotify from "../pages/master/SendNotify";
import Notify from "../pages/member/Notify";
import Notifies from "../pages/member/Notifies";
import Charge from "../pages/member/Charge";
import ChargeConfirm from "../pages/member/ChargeConfirm";
import ChargeComplete from "../pages/member/ChargeComplete";
import ChargeError from "../pages/member/ChargeError";
import Earning from "../pages/creator/Earning";
import Earnings from "../pages/creator/Earnings";
import MasterEarnings from "../pages/master/Earnings";
import MasterEarning from "../pages/master/Earning";
import MasterEarningDescription from "../pages/master/EarningDescription";
import MasterPayments from "../pages/master/Payments";
import Term from "../pages/Term";
import BackNumber from "../pages/BackNumber";
import BackNumberBuy from "../pages/BackNumberBuy";
import ProductBought from "../pages/ProductBought";
import PointEdit from "../pages/master/PointEdit";
import PointEditAll from "../pages/master/PointEditAll";
import MasterMail from "../pages/master/Mail";
import MasterMails from "../pages/master/Mails";
import Search from "../pages/Search";
import MasterPurchase from "../pages/master/Purchase";
import DeleteAccount from "../pages/DeleteAccount";
// import Purchase from "../pages/creator/Purchase";

interface IRoute {
  path: string;
  Component: ReactNode;
}

const Routes: IRoute[] = [
  { path: "/", Component: <HomePage /> },
  { path: "/landing", Component: <Landing /> },
  { path: "/landing/creator", Component: <LandingCreator /> },
  { path: "/contact", Component: <Contact /> },
  { path: "/trade", Component: <Trade /> },
  { path: "/privacypolicy", Component: <Privacypolicy /> },
  { path: "/login", Component: <LoginPage /> },
  { path: "/leave", Component: <Leave /> },
  { path: "/register", Component: <SignupPage /> },
  { path: "/shop", Component: <CreatorHome /> },
  { path: "/plan", Component: <Plans /> },
  { path: "/plan/:id", Component: <Plan /> },
  { path: "/plan/:id/join", Component: <PlanJoin /> },
  { path: "/plan/:id/leave", Component: <PlanLeave /> },
  { path: "/qa", Component: <QA /> },
  { path: "/passwordreset", Component: <PasswordResetPage /> },
  { path: "/passwordreset/code", Component: <PasswordResetCode /> },
  { path: "/product", Component: <ProductUser /> },
  { path: "/product/:id", Component: <Product /> },
  { path: "/product/:id/buy", Component: <ProductBuy /> },
  { path: "/productplan/:id", Component: <ProductPlan /> },
  { path: "/productplan", Component: <ProductPlans /> },
  { path: "/productlist", Component: <ProductListPage /> },
  { path: "/productnew", Component: <ProductListNewPage /> },
  { path: "/productpickup", Component: <ProductListPickupPage /> },
  { path: "/company", Component: <Company /> },
  { path: "/member", Component: <Member /> },
  { path: "/member/edit", Component: <AccountEdit /> },
  { path: "/member/auth-confirm", Component: <AuthConfirm /> },
  { path: "/creator", Component: <CreatorTopPage /> },
  { path: "/creator/plan", Component: <CreatorPlan /> },
  { path: "/creator/plan/add", Component: <CreatorPlanAdd /> },
  { path: "/creator/plan/:id", Component: <CreatorPlanEdit /> },
  { path: "/creator/account", Component: <AccountPage /> },
  { path: "/creator/account/edit", Component: <AccountEditPage /> },
  { path: "/creator/product", Component: <CreatorProductList /> },
  { path: "/creator/product/add", Component: <CreatorProductAdd /> },
  { path: "/creator/product/:id", Component: <CreatorProductEdit /> },
  { path: "/creator/productplan/add", Component: <ProductPlanAdd /> },
  { path: "/creator/productplan", Component: <ProductPlanList /> },
  { path: "/creator/productplan/:id", Component: <ProductPlanEdit /> },
  { path: "/creator/order", Component: <CreatorOrderList /> },
  { path: "/creator/order/:id", Component: <CreatorOrderDetail /> },
  { path: "/creator/sales", Component: <CreatorSales /> },
  { path: "/creator/bank", Component: <BankAccount /> },
  { path: "/creator/earning", Component: <Earnings /> },
  { path: "/creator/earning/:year/:month", Component: <Earning /> },
  { path: "/creator/sales-method", Component: <CreatorSalesMethod /> },
  { path: "/creator/sales-method/edit", Component: <CreatorSalesMethodEdit /> },
  { path: "/creator/setting", Component: <CreatorSetting /> },
  { path: "/creator/categorytag", Component: <CategoryTag /> },
  { path: "/creator/category", Component: <CategoryList /> },
  { path: "/creator/category/add", Component: <CategoryAdd /> },
  { path: "/creator/category/:id", Component: <CategoryEdit /> },
  { path: "/creator/tag", Component: <TagList /> },
  { path: "/creator/tag/add", Component: <TagAdd /> },
  { path: "/creator/tag/:id", Component: <TagEdit /> },
  { path: "/creator/message", Component: <CreatorMessageSetting /> },
  { path: "/creator/shop", Component: <ShopInformation /> },
  { path: "/creator/shop/edit", Component: <ShopInformationEdit /> },
  { path: "/master/login", Component: <MasterLoginPage /> },
  { path: "/master", Component: <MasterHomePage /> },
  { path: "/master/mail", Component: <MasterMails /> },
  { path: "/master/mail/:key", Component: <MasterMail /> },
  { path: "/master/user", Component: <UserListPage /> },
  { path: "/master/user/add", Component: <UserAddPage /> },
  { path: "/master/user/:id", Component: <UserEditPage /> },
  { path: "/master/member", Component: <UserMember /> },
  { path: "/master/user-unapproved/:id", Component: <UserUnapproved /> },
  { path: "/master/user-unapproved", Component: <UserUnapprovedList /> },
  { path: "/master/user-approval", Component: <UserApproval /> },
  { path: "/master/mosaic", Component: <MosaicList /> },
  { path: "/master/mosaic/user", Component: <MosaicListAccount /> },
  { path: "/master/mosaic/product", Component: <MosaicListProduct /> },
  { path: "/master/mosaic/productplan", Component: <MosaicListProductplan /> },
  { path: "/master/mosaic/sales", Component: <MosaicListSales /> },
  { path: "/master/category", Component: <MasterCategories /> },
  { path: "/master/category/add", Component: <MasterCategoryAdd /> },
  { path: "/master/message", Component: <SendMessage /> },
  { path: "/master/earning", Component: <MasterEarnings /> },
  { path: "/master/earning/:userId", Component: <MasterEarning /> },
  {
    path: "/master/earning/:userId/:year/:month",
    Component: <MasterEarningDescription />,
  },
  { path: "/master/point", Component: <PointEdit /> },
  { path: "/master/point/all", Component: <PointEditAll /> },
  { path: "/master/payment", Component: <MasterPayments /> },
  { path: "/message", Component: <Messages /> },
  { path: "/message/:id", Component: <Message /> },
  { path: "/master/notify", Component: <SendNotify /> },
  { path: "/notify", Component: <Notifies /> },
  { path: "/notify/:id", Component: <Notify /> },
  { path: "/commingsoon", Component: <Commingsoon /> },
  { path: "/payment", Component: <Payment /> },
  { path: "/payment/error", Component: <PaymentError /> },
  { path: "/payment/done", Component: <PaymentDone /> },
  { path: "/charge", Component: <Charge /> },
  { path: "/charge/complete", Component: <ChargeComplete /> },
  { path: "/charge/error", Component: <ChargeError /> },
  { path: "/charge/:coin", Component: <ChargeConfirm /> },
  { path: "/card/add", Component: <CardAdd /> },
  { path: "/backnumber/:userId", Component: <BackNumber /> },
  { path: "/backnumber-buy/:planId", Component: <BackNumberBuy /> },
  { path: "/term", Component: <Term /> },
  { path: "/search", Component: <Search /> },
  { path: "/bought", Component: <ProductBought /> },
  { path: "/master/purchase", Component: <MasterPurchase /> },
  { path: "/delete-account", Component: <DeleteAccount /> },
  { path: "*", Component: <NotFound /> },
];

export default Routes;
