import React from "react";
import { useParams } from "react-router-dom";
import Container from "../../../parts/Container/Container";
import Text from "../../../parts/Text";
import useEarning from "../../../api/earning/list";
import usePurchase from "../../../api/purchase/list";
import moment from "moment";
import { ButtonPrev } from "../../../parts/Button";

const Content: React.FC = () => {
  const {
    userId,
    year: __year,
    month: __month,
  } = useParams<{
    userId: string;
    year: string;
    month: string;
  }>();

  const year = parseInt(__year || "2023") || 2023;
  const month = parseInt(__month || "1") || 1;

  const earning = useEarning({ params: { userId } });
  const purchase = usePurchase({ params: { userId, month, year } });

  const total =
    earning.data?.find((e) => e.date === `${year}/${month}`)?.coins || 0;

  return (
    <Container fullWidth maxWidth750>
      <h2>{earning.data?.[0]?.user?.profile?.nickname}売上詳細</h2>
      <Container background="gray">
        <Container row>
          <Text>
            {year}年{month}月売上
          </Text>
          <Container grow />
          <Text>￥{total.toLocaleString()}</Text>
        </Container>
        <Container background="white">
          <Container row noPadding>
            <Container
              noPadding
              basis={80}
              alignCenter
              className="master-list title"
            >
              購入年月日
            </Container>
            <Container
              noPadding
              basis={80}
              alignCenter
              className="master-list title"
            >
              購入ユーザーID
            </Container>
            <Container
              noPadding
              basis={100}
              alignCenter
              className="master-list title"
            >
              購入金額
            </Container>
            <Container
              noPadding
              basis={100}
              alignCenter
              className="master-list title"
            >
              購入商品ID
            </Container>
            <Container
              noPadding
              basis={100}
              alignCenter
              className="master-list title"
            >
              購入商品名
            </Container>
            <Container
              noPadding
              basis={80}
              alignCenter
              className="master-list title"
            >
              商品種別
            </Container>
            <Container
              noPadding
              basis={80}
              alignCenter
              className="master-list title"
            >
              購入個数
            </Container>
          </Container>
          {purchase.data?.map((purchase) =>
            purchase.products.map((product) => (
              <Container row noPadding borderTop>
                <Container noPadding basis={80} alignCenter>
                  {moment(purchase.createdAt).format("yyyy/M/D")}
                </Container>
                <Container
                  noPadding
                  basis={80}
                  alignCenter
                  className="master-list"
                >
                  {purchase?.user?.id}
                </Container>
                <Container
                  noPadding
                  basis={100}
                  alignCenter
                  className="master-list"
                >
                  ￥{product.price.toLocaleString()}
                </Container>
                <Container
                  noPadding
                  basis={100}
                  alignCenter
                  className="master-list"
                >
                  {product.id}
                </Container>
                <Container
                  noPadding
                  basis={100}
                  alignCenter
                  className="master-list "
                >
                  {product.name}
                </Container>
                <Container
                  noPadding
                  basis={80}
                  alignCenter
                  className="master-list"
                >
                  通常
                </Container>
                <Container
                  noPadding
                  basis={80}
                  alignCenter
                  className="master-list"
                >
                  1
                </Container>
              </Container>
            ))
          )}
        </Container>
      </Container>
      <div className="btn">
        <ButtonPrev />
      </div>
    </Container>
  );
};

export default Content;
