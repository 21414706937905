import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import User from "types/User";
import UserType from "types/UserType";
import useUserlist from "../../../api/user/list";
import Button from "../../../parts/Button";
import useUpdateUser from "../../../api/user/update";

const UserList: React.FC = () => {
  const [searchParams] = useSearchParams();
  const userType = (searchParams.get("type") as UserType) || "member";
  const userTypeRef = React.useRef(userType);
  const userlist = useUserlist({ params: { type: userType, limit: 999 } });
  const updateUser = useUpdateUser();
  useEffect(() => {
    if (userlist.loading) return;
    if (userTypeRef.current === userType) return;
    userTypeRef.current = userType;
    userlist.execute({ type: userType });
  }, [userlist, userType]);

  const handleUpgrade =
    (id: number) => async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      const result = await updateUser.execute({
        id: id,
        upgradeCreator: true,
      });
      if (result instanceof Error) {
        alert("エラーが発生しました。");
        return;
      }
      userlist.execute({ type: userType, limit: 999 });
    };

  return (
    <div className="wrap">
      <h2>
        {userType === "master"
          ? "管理ユーザー"
          : userType === "creator"
          ? "販売者ユーザー"
          : "ユーザー"}
        一覧
      </h2>

      <div className="information-box">
        <table id="user-list">
          {userlist.data?.map((user: User, index) => (
            <tr key={index}>
              <th className="table-name">
                <p>No.{user.id}</p>
              </th>
              <th className="table-name">
                <p>{user?.profile?.nickname}</p>
              </th>
              <th className="table-name">
                <p>{user?.email}</p>
              </th>
              <th>
                <Button
                  disabled={user.roles.some((role) => role.name === "creator")}
                  onClick={handleUpgrade(user.id)}
                >
                  {user.roles.some((role) => role.name === "creator")
                    ? "クリエーター"
                    : "クリエーターに変更"}
                </Button>
              </th>
              <th>
                <Link to={`/master/user/${user.id}?type=${userType}`}>
                  <input
                    type="submit"
                    className="edit"
                    // onclick=""
                    value="編集"
                  />
                </Link>
              </th>
              <th>
                <input type="submit" className="delete" value="削除" />
              </th>
            </tr>
          ))}
          <template id="user-list-item-template"></template>
        </table>

        <div className="btn">
          <Link to="/master">
            <input type="submit" className="back" value="戻る" />
          </Link>

          <Link to={`/master/user/add?type=${userType}`}>
            <button type="submit">
              {userType === "master"
                ? "管理ユーザー"
                : userType === "creator"
                ? "販売者ユーザー"
                : "ユーザー"}
              新規作成
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserList;
