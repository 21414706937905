import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useGetUser from "../../../api/user/get";
import useUpdateUser from "../../../api/user/update";
// import useAddUser from "../../api/user/add";
import UserType from "types/UserType";
import styled from "styled-components";
import ImageInput from "../../../parts/ImageInput";
import Icon from "../../../parts/Icon";
import Button from "../../../parts/Button";
import Container from "../../../parts/Container";

const roleMaps: { [key: string]: string } = {
  admin: "管理者",
  creator: "販売者",
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;
const Label = styled.label<{ label?: string }>`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 20px;
  &:before {
    content: "${(props) => props.label}";
    display: flex;
    flex-basis: 200px;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: end;
    align-items: center;
    @media (max-width: 768px) {
      flex-basis: 100px;
    }
  }
`;

const LabelMultiline = styled.label<{ label?: string }>`
  display: flex;
  width: 100%;
  height: 100px;
  flex-direction: row;
  gap: 20px;
  &:before {
    content: "${(props) => props.label}";
    display: flex;
    flex-basis: 200px;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: end;
    align-items: center;
    @media (max-width: 768px) {
      flex-basis: 100px;
    }
  }
`;

const ItemInput = styled.input`
  flex-basis: 70%;
  flex-grow: 1;
  flex-shrink: 1;

  border: 2px solid #949fb5;
  border-radius: 5px;
  height: 3em;
  padding: 0.5em;
  box-sizing: border-box;

  .confirm & {
    border: none;
    pointer-events: none;
  }
`;

const ItemInputMultiline = styled.textarea`
  height: 100%;
  flex-basis: 70%;
  flex-grow: 1;
  flex-shrink: 1;

  border: 2px solid #949fb5;
  border-radius: 5px;
  padding: 0.5em;
  box-sizing: border-box;

  .confirm & {
    border: none;
    pointer-events: none;
  }
`;
const Value = styled.div`
  flex-basis: 70%;
  flex-grow: 1;
  flex-shrink: 1;
`;

const StyledIcon = styled(Icon)`
  width: 100px;
`;

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userType = (searchParams.get("type") as UserType) || "member";
  const { id = "" } = useParams<{ id: string }>();
  const formRef = React.useRef<HTMLFormElement>(null);

  const [confirm, setConfirm] = useState<boolean>(false);

  const user = useGetUser({ pathParams: { id } });
  const updateUser = useUpdateUser();

  const getFileURL = (name: string) => {
    const input = formRef.current?.[name] as HTMLInputElement;
    if (!input) return "";
    const file = input.files?.[0];
    if (!file) return "";
    const url = URL.createObjectURL(file);
    return url;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!confirm) {
      setConfirm(true);
      return;
    }

    const getValue = (name: string) => {
      const input = formRef.current?.[name] as HTMLInputElement;
      if (!input) return;
      return input.value;
    };
    const getFile = (name: string): File | undefined => {
      const input = formRef.current?.[name] as HTMLInputElement;
      if (!input) return;
      const file = input.files?.[0];
      if (!file) return;
      return file;
    };

    const password = getValue("password");
    const passwordConfirm = getValue("password2");
    if (password !== passwordConfirm) return;

    const email = getValue("email");
    const username = getValue("name");
    const icon = getFile("icon");
    const shopIcon = getFile("shopIcon");
    const zip = getValue("zip");
    const address = getValue("address");
    const call = getValue("call");
    const birthday = getValue("birthday");
    const shopIntroduction = getValue("introduction");
    const shopName = getValue("shopName");
    const nameKanji = getValue("nameKanji");
    const nameKana = getValue("nameKana");
    const memo = getValue("memo");

    const params = {
      id: Number(id),
      ...(email !== user.data?.email && { email }),
      ...(password && { password }),
      ...(username !== user.data?.profile?.nickname && {
        nickname: username,
      }),
      ...(icon && { icon }),
      ...(shopIcon && { shopIcon }),
      ...(zip !== user.data?.profile?.zip && { zip }),
      ...(address !== user.data?.profile?.address && { address }),
      ...(call !== user.data?.profile?.call && { call }),
      ...(birthday !== user.data?.profile?.birthday && { birthday }),
      ...(shopIntroduction !== user.data?.shopProfile?.introduction && {
        shopIntroduction,
      }),
      ...(shopName !== user.data?.shopProfile?.name && { shopName }),
      ...(nameKanji !== user.data?.profile?.nameKanji && { nameKanji }),
      ...(nameKana !== user.data?.profile?.nameKana && { nameKana }),
      ...(memo !== user.data?.memo && { memo }),

      role: userType,
    };

    console.log(params);

    const result = await updateUser.execute(params);
    if (result instanceof Error) return;
    navigate("/master/user");
  };

  const handleSuspend =
    (suspend: boolean) => async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const params = {
        id: Number(id),
        suspend: !suspend,
      };

      const result = await updateUser.execute(params);
      if (result instanceof Error) return;

      // navigate("/master/user");
    };

  const handlePrev = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (confirm) setConfirm(false);
    else navigate(-1);
  };
  useEffect(() => {
    if (!user.called) return;
    if (user.loading) return;
    if (!user.data) return;
    if (user.error) return;

    // const nickname = user?.data?.profile?.nickname;

    const setValue = (name: string, value?: string) => {
      const input = formRef.current?.[name] as HTMLInputElement;
      if (!input) return;
      input.value = value || "";
    };
    setValue("email", user?.data?.email);
    setValue("name", user?.data?.profile?.nickname);
    setValue("zip", user?.data?.profile?.zip);
    setValue("address", user?.data?.profile?.address);
    setValue("call", user?.data?.profile?.call);
    setValue("birthday", user?.data?.profile?.birthday);
    setValue("introduction", user?.data?.shopProfile?.introduction);
    setValue("shopName", user?.data?.shopProfile?.name);
    setValue("nameKanji", user?.data?.profile?.nameKanji);
    setValue("nameKana", user?.data?.profile?.nameKana);
    setValue("password", "");
    setValue("password2", "");
    setValue("memo", user?.data?.memo);
  }, [user.called, user.loading, user.error, user.data, id]);

  if (user.loading) return <div>loading...</div>;
  return (
    <div className="wrap">
      <h2>ユーザー編集</h2>
      <Form
        id="user-edit"
        onSubmit={handleSubmit}
        ref={formRef}
        className={confirm ? "confirm" : ""}
      >
        <Label label="アカウントNo">
          <Value>{id}</Value>
        </Label>
        <Label label="アカウント名">
          <ItemInput name="name" />
        </Label>
        <Label label="アカウントアイコンアップロード">
          {confirm ? (
            <ImageInput
              icon
              name="icon"
              srcInitial={user.data?.profile?.icon}
            />
          ) : (
            <StyledIcon
              src={getFileURL("icon") || user.data?.profile?.icon || ""}
            />
          )}
        </Label>
        <Label label="メールアドレス">
          <ItemInput name="email" />
        </Label>
        <Label label="パスワード">
          <ItemInput name="password" />
        </Label>
        <Label label="パスワード確認">
          <ItemInput name="password2" />
        </Label>
        <Label label="郵便番号">
          <ItemInput name="zip" />
        </Label>
        <Label label="住所">
          <ItemInput name="address" />
        </Label>
        <Label label="電話番号">
          <ItemInput name="call" />
        </Label>
        <Label label="生年月日">
          <ItemInput name="birthday" />
        </Label>
        <Label label="性別">
          {!user.data?.profile?.sex
            ? "未回答"
            : user.data?.profile?.sex === "1"
            ? "男性"
            : user.data?.profile?.sex === "2"
            ? "女性"
            : "その他"}
        </Label>
        <Label label="ショップ紹介">
          <ItemInput name="introduction" />
        </Label>
        <Label label="ショップ名">
          <ItemInput name="shopName" />
        </Label>
        <Label label="ショップアイコン">
          {confirm ? (
            <ImageInput
              name="shopIcon"
              icon
              srcInitial={user.data?.shopProfile?.icon}
            />
          ) : (
            <StyledIcon
              src={getFileURL("icon") || user.data?.shopProfile?.icon || ""}
            />
          )}
        </Label>
        <Label label="名前">
          <ItemInput name="nameKanji" />
        </Label>
        <Label label="フリガナ">
          <ItemInput name="nameKana" />
        </Label>
        <Label label="ロール">
          {user.data?.roles
            ?.map?.((role) => roleMaps?.[role.name])
            ?.join?.(", ") || "なし"}
        </Label>

        <Label label="ポイント残高">
          <Value>{user?.data?.balance}</Value>
        </Label>

        <LabelMultiline label="メモ">
          <ItemInputMultiline name="memo" />
        </LabelMultiline>

        <Container className="btn" row>
          <input type="hidden" name="backstate" />
          <Button type="submit" className="back" onClick={handlePrev}>
            戻る
          </Button>
          <Button type="submit" name="submit">
            確認
          </Button>
        </Container>
        <Button
          onClick={handleSuspend(!!user.data?.suspend)}
          color={!user.data?.suspend ? "primary" : "secondary"}
        >
          {!user.data?.suspend ? "凍結する" : "凍結を解除する"}
        </Button>
      </Form>
    </div>
  );
};

export default Home;
