import { useApiFormData as useApi } from "../useApi";

type DataType = {};
type Params = {
  resetCode: string;
  password: string;
};

const path = "/user/passwordresetexecute";
const method = "post";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
