import React from "react";
const Content: React.FC = () => {
  return (
    <div id="wrapper">
      <main>
        <div className="pvwrap">
          <div className="panel panel-default full-xs kiyaku">
            <div className="panel-heading panel-heading-lg"></div>
            <div className="panel-body">
              <h1 className="top-title">特定商取引法開示</h1>


              <h4>サービス提供元</h4>
              <p>株式会社rara</p>
              <h4>代表者</h4>
              <p>代表取締役　塩野 克彦</p>
              <h4>所在地</h4>
              <p>〒170-0014 東京都豊島区池袋1-1-8 SKYビル6F</p>


              <h1 className="top-title">お問い合わせ先</h1>
              <p style={{ fontWeight: "bold" }}>株式会社rara</p>
              <p>URL：https://rarajp.com/</p>

              <h1 className="top-title">商品・役務の名称、種類</h1> <p>
              ファンクラブサイト運営
              </p>

              <h1 className="top-title">お支払い方法について</h1>
              <p>
                銀行振込（納品完了後、請求書発行月の翌月末までにお支払いをお願い致します。）
              </p>

              <h4>
                サービス料金以外の料金について
              </h4>
              <p>銀行振込手数料はお客様負担とさせていただきます。</p>

              <h4>商品のお引渡し方法について</h4>
              <p>
                データ容量に応じて、弊社指定サーバよりダウンロード、メールまたはファイル送信サービスを利用したデータ送信。
              </p>

              <h4>商品のお引渡し時期について</h4>
              <p>
                原則として、弊社が指定する納期目安を基準とし、別途ご発注時に取り決めさせていただいた納品日。
              </p>

              <h4>
                返品・交換・納品データの不具合について
              </h4>
              <p>
                受注制作のため、お客様のご都合によるご返品はできません。事前にチェックのうえ納品致しておりますが、納品後は必ずチェックを行って下さい。万が一、納品後に弊社過失によるデータの不具合が見つかった場合は速やかに対応させていただきますので、納品後10営業日以内にご連絡ください。
              </p>

              <h4>キャンセルについて</h4>
              <p>
                ご発注後のお客様のご都合によるキャンセルにつきましては、サイト公開の有無、理由に関わらず、料金が発生いたします。
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Content;
