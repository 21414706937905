import React, { useState } from "react";
import Layout from "../../themplate/Layout";
import styled from "styled-components";
import Button from "../../parts/Button";
import Input from "../../parts/Input";
import Usersearch from "../../components/UserSearch";
import useUpdateUesr from "../../api/user/update";
import { Link } from "react-router-dom";

const LinkedButton = Button.withComponent(Link);

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  @media (min-width: 768px) {
    width: 70%;
  }
  gap: 20px;
  padding: 20px;
`;
const Header = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
`;

const item = [-1000, -500, 0, 500, 1000];

const Page: React.FC = () => {
  const [count, setCount] = useState<number>(0);
  const reload = () => setCount((c) => c + 1);
  const [coin, setCoin] = useState<number>(0);
  const [id, setId] = useState<number>(0);

  const updateUser = useUpdateUesr();

  const handleCoinClick =
    (coin: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
      setCoin(coin);
    };
  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const result = await updateUser.execute({ id, balance: coin });
    if (result instanceof Error) return;
    alert("更新しました");
    reload();
  };
  return (
    <Layout type="master">
      <Container>
        <Header>ポイント管理</Header>
        <Usersearch id={id} setId={setId} reload={count} />
        <Input
          type="number"
          value={coin}
          onChange={(e) => setCoin(Number(e.currentTarget.value))}
        />
        <ButtonContainer>
          {item.map((i, index) => (
            <Button
              key={index}
              color={coin === i ? "secondary" : "info"}
              onClick={handleCoinClick(i)}
            >
              {i}FC
            </Button>
          ))}
        </ButtonContainer>
        {updateUser.error && <div>{updateUser.error.message}</div>}
        <Button
          color="primary"
          onClick={handleSubmit}
          loading={updateUser.loading}
          disabled={!id}
        >
          送信
        </Button>
      </Container>
      <LinkedButton to={`/master/point/all`}>
        全ユーザーのポイント残高を書き換える
      </LinkedButton>
    </Layout>
  );
};

export default Page;
