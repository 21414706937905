import React from "react";
import { useNavigate } from "react-router-dom";
import Button, { ButtonPrev } from "../../parts/Button";
import Container from "../../parts/Container";
import Input from "../../parts/Input";
import Text from "../../parts/Text";
import styled from "styled-components";

const Form = styled(Container).attrs({ as: "form" })`
  width: 100%;
  max-width: 750px;
  gap: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 30px 50px;
  overflow: hidden;
`;

const Content: React.FC = () => {
  const navigate = useNavigate();
  const [number, setNumber] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [expMonth, setExpMonth] = React.useState<string>("");
  const [expYear, setExpYear] = React.useState<string>("");
  const [cvc, setCvc] = React.useState<string>("");

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    console.log(number, name, expMonth, expYear, cvc);
    navigate("/charge");
  };

  return (
    <Container fullWidth maxWidth750>
      <Container>
        <Text center large>
          チャージ金額を選択
        </Text>
      </Container>
      <Form>
        <Text>注文の確定</Text>
        <Container />
        <Text>カード番号</Text>
        <Input value={number} onChange={(e) => setNumber(e.target.value)} />
        <Text gray>ハイフンなし - 半角英数</Text>
        <Container />
        <Text>カード保有者(カードに記載されたお名前)</Text>
        <Input value={name} onChange={(e) => setName(e.target.value)} />
        <Text gray>半角ローマ字</Text>
        <Container />
        <Text>有効期限(月/年)</Text>
        <Container row noPadding gap={100}>
          <Input
            placeholder="月"
            value={expMonth}
            onChange={(e) => setExpMonth(e.target.value)}
          />
          <Input
            placeholder="年"
            value={expYear}
            onChange={(e) => setExpYear(e.target.value)}
          />
        </Container>
        <Container />
        <Text>セキュリティコード</Text>
        <Input
          value={cvc}
          onChange={(e) => setCvc(e.target.value)}
          type="password"
        />
        <Text red>
          クレジットカードの裏面または表面に記載された3桁もしくは4桁の番号
        </Text>
      </Form>
      <Container row justifyCenter alignCenter>
        <ButtonPrev />
        <Button onClick={handleSubmit}>カードを追加</Button>
      </Container>
    </Container>
  );
};

export default Content;
