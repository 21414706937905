import Home from "../components/Home";
import Layout from "../themplate/Layout";

const HomePage: React.FC = () => (
  <Layout>
    <Home />
  </Layout>
);

export default HomePage;
