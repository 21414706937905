import styled from "styled-components";

const Thumbnail = styled.div<{ src?: string }>`
  width: 100%;
  position: relative;
  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${(p) => p?.src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

export default Thumbnail;
