import styled from "styled-components";
import Button from "./Button";

const handleShare = async (e: React.MouseEvent<HTMLButtonElement>) => {
  e.preventDefault();

  // const path = window.location.pathname;
  // const origin = window.location.origin;
  // const url = `${origin}${path}`;
  const url = window.location.href;
  await navigator.clipboard.writeText(url);
  alert("URLをコピーしました");
};
const ShareButton = styled(Button).attrs({ onClick: handleShare })``;

export default ShareButton;
