import React, { useCallback, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import jwt_decode from "jwt-decode";
import useLogin from "../api/user/login";
import useSignup from "../api/user/signup";
import useUser from "../api/user/get";

class TokenExpired extends Error {
  constructor() {
    super("Token is expired");
    this.name = "TokenExpired";
  }
}

interface Auth extends Payload {
  setToken: (token: string) => void;
  token?: string;
  logout: () => void;
  getTokenPayload: () => T;
  isLogin: boolean;
  loading: boolean;
  login: ReturnType<typeof useLogin>;
  signup: ReturnType<typeof useSignup>;
  user: ReturnType<typeof useUser>;
}

interface Payload {
  userId: string;
  isCreator: boolean;
  isAdmin: boolean;
  approved: boolean;
  exp?: number;
}
interface T extends Payload {
  isLogin?: boolean;
}

const initialState: Auth = {
  token: "",
  userId: "",
  setToken: (token: string) => {},
  logout: () => {},
  getTokenPayload: () => ({} as T),
  isAdmin: false,
  isCreator: false,
  isLogin: false,
  approved: false,
  loading: true,
  login: {
    execute: () => {},
    error: null,
    loading: false,
    data: null,
  } as ReturnType<typeof useLogin>,
  signup: {
    execute: () => {},
    error: null,
    loading: false,
    data: null,
  } as ReturnType<typeof useSignup>,
  user: {
    execute: () => {},
    error: null,
    loading: false,
    data: null,
  } as ReturnType<typeof useUser>,
};

const Context = React.createContext<Auth>(initialState);

export const Provider: React.FC<{ children: React.ReactNode }> = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [payload, setPayload] = useState<Payload>({
    userId: "",
    isAdmin: false,
    isCreator: false,
    approved: false,
  });
  const login = useLogin();
  const signup = useSignup();
  const user = useUser({ pathParams: { id: "me" } });
  const token = cookies.token;
  const logout = () => {
    removeCookie("token", { path: "/" });
    window.location.href = "/";
  };
  const setToken = useCallback(
    (token: string) => {
      setCookie("token", token);
    },
    [setCookie]
  );

  const getTokenPayload = useCallback((): T => {
    if (!token) throw new Error("token is undefined");
    const decoded = jwt_decode(token);
    if (!decoded) throw new Error("decoded is undefined");
    const payload = decoded as Partial<Payload>;
    const result = { ...decoded, isLogin: !!payload.userId } as T;
    return result;
  }, [token]);
  useEffect(() => {
    try {
      const t = getTokenPayload();
      const { isLogin, ...payload } = t;
      const {
        userId = "",
        isAdmin = false,
        isCreator = false,
        approved = false,
        exp,
      } = payload;
      if (!exp) throw new TokenExpired();
      if (exp < Date.now() / 1000) throw new TokenExpired();

      setPayload({ userId, isAdmin, isCreator, approved });
      setIsLogin(isLogin || false);
    } catch (e) {
      if (e instanceof TokenExpired) setToken("");
      setPayload({
        userId: "",
        isAdmin: false,
        isCreator: false,
        approved: false,
      });
      setIsLogin(false);
    } finally {
      setLoading(false);
    }
  }, [token, getTokenPayload, login.loading, signup.loading, setToken]);
  useEffect(() => {
    if (!payload.userId) return;
    if (user.loading) return;
    if (user.data) return;
    if (!token) return;
    console.log(payload.userId, user);

    user.execute(undefined, { id: payload.userId });
  }, [payload.userId, user, token]);

  const value = {
    token,
    setToken,
    logout,
    getTokenPayload,
    isLogin,
    loading,
    login,
    signup,
    user,
    ...payload,
  };

  return <Context.Provider value={value}>{props.children}</Context.Provider>;
};
export const useAuth = () => useContext(Context);
