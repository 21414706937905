import React from "react";
import { Link } from "react-router-dom";
import usePasswordReset from "../api/user/passwordreset";
import Layout from "../themplate/Layout";

const PasswordResetPage: React.FC = () => {
  const passwordReset = usePasswordReset();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const resullt = await passwordReset.execute({
      email: e.currentTarget?.email.value,
    });
    if (resullt instanceof Error) {
      alert("パスワード再設定に失敗しました。");
      return;
    }
    alert("パスワード再設定用のメールを送信しました。");
  };

  return (
    <Layout>
      <div id="wrapper">
        <div className="login-page">
          <div className="form">
            <form className="login-form reset" onSubmit={handleSubmit}>
              <p>
                アカウントに登録されたメールアドレス宛に、パスワード再設定用URLを記載したメールを送信します。
              </p>
              <input type="text" placeholder="メールアドレス" name="email" />

              <button>送信</button>

              <p className="message">
                <Link to="/login">ログイン</Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default PasswordResetPage;
