import Login from "../components/Login";
import Layout from "../themplate/Layout";

const Page: React.FC = () => (
  <Layout loginPage>
    <Login />
  </Layout>
);

export default Page;
