import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import useUsers from "../api/user/list";

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;
const User = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: ${(props) => (props.active ? "lightblue" : "transparent")};
`;
const UserName = styled.div`
  display: flex;
  flex-basis: 100px;
  flex-grow: 1;
  flex-shrink: 1;
`;
const Balance = styled.div`
  flex-basis: 100px;
  flex-grow: 0;
  flex-shrink: 0;
`;
const Icon = styled.div<{ src: string }>`
  width: 40px;
  height: 40px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: 1px solid #ccc;
  box-sizing: border-box;
`;

const SearchContainer = styled.form`
  width: 100%;
  height: 40px;
  flex-direction: row;
`;
const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 2px solid skyblue;
  border-radius: 5px;
`;

const Head = styled.div`
  width: 100%;
  text-align: left;
`;

interface Props {
  id?: number;
  setId?: React.Dispatch<React.SetStateAction<number>>;
  reload?: number;
}
const Content: React.FC<Props> = (props) => {
  const user = useUsers();

  const formRef = useRef<HTMLFormElement>(null);

  const handleClick = (id: number) => (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    props?.setId?.(id);
  };

  const search = async (search: string) => {
    const result = await user.execute({ search });
    return result;
  };

  useEffect(() => {
    const searchText = formRef.current?.search?.value;
    search(searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reload]);

  const handleSearch = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const searchText = e.currentTarget?.search?.value;
    await search(searchText);
  };

  return (
    <Container>
      <Head>ユーザー</Head>
      <SearchContainer onSubmit={handleSearch} ref={formRef}>
        <SearchInput type="text" placeholder="ユーザー検索" name="search" />
      </SearchContainer>
      {user.data?.slice(0, 5)?.map?.((u) => (
        <User
          key={u.id}
          active={props?.id === u.id}
          onClick={handleClick(u.id)}
        >
          <Icon src={u.profile?.icon} />
          <UserName>{u.profile?.nickname}</UserName>
          <Balance>{u.balance} FC</Balance>
        </User>
      ))}
      {user.data?.length === 0 && "ユーザーが見つかりませんでした"}
    </Container>
  );
};

export default Content;
