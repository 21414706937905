import { useApiFormData } from "../useApi";

type ParamsForCreator = {
  name: string;
  price: number;
  description: string;
  thumbnail: File;
};
type ParamsForJoin = {
  planId: number;
};

type DataType = {};
type Params = ParamsForJoin | ParamsForCreator;

const path = "/plan";
const method = "post";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApiFormData<DataType, Params>({
  path,
  method,
  eagerLoad,
});
