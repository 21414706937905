import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../libs/auth";
import Button, { ButtonPrev } from "../../parts/Button";
import Container from "../../parts/Container";
import styed from "styled-components";
import { AxiosError } from "axios";

const Item = styed.div`
  display: flex;
  justify-content: start;
  align-items: center;
  border-bottom: dotted 1px #d3d3d3;
  padding: 2% 0;
  row-gap: 0px;
  column-gap: 10px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    gap: 15px;
    padding: 5% 0;
  };
`;

const Key = styed.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 30%;
  flex-basis: calc(30% - 10px);
  text-align: left;
  flex-shrink: 0;
  flex-grow: 0;
  line-height: 1;
  font-weight: bold;
  box-sizing: border-box;
  @media (max-width: 768px) {
    flex-basis: auto;
    width: 100%;
    font-size: 0.8rem;
  }
`;
const Value = styed.input`
  width: auto;
  flex-basis: auto;
  text-align: left;
  flex-shrink: 1;
  flex-grow: 1;
  height: 40px;
  border: ${(p) => (p.disabled ? "none" : "2px solid skyblue")};
  border-radius: 5px;
  height: 3em;
  padding: 0.5em;
  box-sizing: border-box;
  @media (max-width: 768px) {
    flex-basis: auto;
    width: 100%;
  }
  background-color: white;
  &:disabled:-webkit-autofill{  
    box-shadow: 0 0 0px 999px #fff inset;
    color: white !important;
  }
`;

const Text = styed.p`
  width: 100%;
  flex-basis: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  padding-left: 30%;
box-sizing: border-box;
`;

const CheckboxLabel = styed.label`
  flex-basis: 100px;
  box-sizing: border-box;
  margin: 0 !important;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 5px;
  @media (max-width: 768px) {
    width: 100%;
    flex-basis: auto;
  }
`;

const Content: React.FC = () => {
  const [page, setPage] = useState<number>(0);
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [passwordConfirmationShown, setPasswordConfirmationShown] =
    useState<boolean>(false);
  const togglePasswordShown = () => setPasswordShown((p) => !p);
  const togglePasswordConfirmationShown = () =>
    setPasswordConfirmationShown((p) => !p);

  const navigate = useNavigate();

  const { signup } = useAuth();

  // const [error, setError] = useState<string>("");
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const email = e.currentTarget.email.value;
    const password = e.currentTarget.password.value;
    const passwordConfirmation = e.currentTarget.password2.value;
    const nickname = e.currentTarget.nickname.value;
    const name = e.currentTarget.name_kanji.value;
    const nameKana = e.currentTarget.name_kana.value;
    const sex = e.currentTarget.sex.value;
    const birthday = e.currentTarget.birthday.value;
    const zip = e.currentTarget.zip.value;
    const state = e.currentTarget.state.value;
    const address = e.currentTarget.address.value;
    const phoneNumber = e.currentTarget.call.value;

    if (password !== passwordConfirmation) {
      e.currentTarget.password2?.setCustomValidity?.(
        "パスワードが一致しません"
      );
      setPage(0);
      return;
    }
    const d = await signup.execute({
      email,
      password,
      nickname,
      name_kanji: name,
      name_kana: nameKana,
      sex,
      birthday,
      zip,
      state,
      address,
      call: phoneNumber,
      // file,
    });
    if (d instanceof AxiosError && d.response?.status === 409) {
      setPage(0);
      return alert("既に登録されているメールアドレスです");
    }

    if (d instanceof Error) {
      setPage(0);
      return alert("エラー");
    }
    alert("登録しました");
    navigate("/?reload=true");
  };
  const handlePrev = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setPage(0);
  };
  const handleNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setPage(1);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.currentTarget?.setCustomValidity?.("");
  };

  return (
    <Container
      width70L
      width95
      as="form"
      action="/api/user/signup"
      method="post"
      onSubmit={handleSubmit}
    >
      <div className="registerform" id="registerform">
        <div className="main">
          <h2 className="page-title">新規登録</h2>
          <div className="information-wrap">
            <Item>
              <Key>メール（ログインID）</Key>
              <Value type="email" name="email" required disabled={page !== 0} />
            </Item>
            <Item>
              <Key>パスワード</Key>
              <Value
                type={passwordShown ? "text" : "password"}
                name="password"
                required
                disabled={page !== 0}
              />
              <CheckboxLabel>
                <input
                  type="checkbox"
                  onClick={togglePasswordShown}
                  checked={passwordShown}
                />
                表示する
              </CheckboxLabel>
            </Item>
            <Item>
              <Key>パスワード確認</Key>
              <Value
                type={passwordConfirmationShown ? "text" : "password"}
                name="password2"
                required
                onChange={handleChange}
                disabled={page !== 0}
              />
              <CheckboxLabel>
                <input
                  type="checkbox"
                  onClick={togglePasswordConfirmationShown}
                  checked={passwordConfirmationShown}
                />
                表示する
              </CheckboxLabel>
            </Item>
            <Item>
              <Key>ニックネーム</Key>
              <Value
                type="text"
                name="nickname"
                required
                disabled={page !== 0}
              />
            </Item>
            <Item>
              <Key>氏名（漢字）</Key>
              <Value
                type="text"
                name="name_kanji"
                required
                disabled={page !== 0}
              />
            </Item>
            <Item>
              <Key>氏名（カナ）</Key>
              <Value
                type="text"
                name="name_kana"
                required
                disabled={page !== 0}
              />
            </Item>
            <Item>
              <Key>性別</Key>
              <Value as="select" required name="sex" disabled={page !== 0}>
                <option value="" hidden>
                  選択
                </option>
                <option value="1">男性</option>
                <option value="2">女性</option>
                <option value="3">秘密</option>
              </Value>
            </Item>
            <Item>
              <Key>生年月日</Key>
              <Value
                type="date"
                name="birthday"
                required
                disabled={page !== 0}
              />
            </Item>
            <Item>
              <Key>郵便番号</Key>
              <Value type="text" name="zip" required disabled={page !== 0} />
              <Text>※ハイフンなしで入力してください</Text>
            </Item>
            <Item>
              <Key>都道府県</Key>
              <Value type="text" name="state" required disabled={page !== 0} />
            </Item>
            <Item>
              <Key>住所</Key>
              <Value
                type="text"
                name="address"
                required
                disabled={page !== 0}
              />
            </Item>
            <Item>
              <Key>電話番号</Key>
              <Value type="text" name="call" required disabled={page !== 0} />
              <Text>※ハイフンなしで入力してください</Text>
            </Item>
          </div>
        </div>
      </div>

      {signup.error && <Container>エラー{signup.error?.message}</Container>}

      <Container row className="btn">
        {page === 0 ? (
          <>
            <ButtonPrev>戻る</ButtonPrev>
            <Button onClick={handleNext}>会員情報確認</Button>
          </>
        ) : (
          <>
            <Button onClick={handlePrev} color="info">
              戻る
            </Button>
            <Button>登録</Button>
          </>
        )}
      </Container>
    </Container>
  );
};

export default Content;
