import React from "react";
import useProductplans from "../../../api/productplan/list";
import useUpdateProduct from "../../../api/productplan/updateAdmin";
import Button from "../../../parts/Button";
import styled from "styled-components";

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-collapse: collapse;
  gap: 10px;
`;

const TableItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  gap: 10px;
`;
const Cell = styled.div`
  display: flex;
`;
const AccouontName = styled(Cell)``;
const UserId = styled(Cell)``;
const ProductName = styled(Cell)`
  font-weight: bold;
`;
const Buttons = styled(Cell)`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const ImageCell = styled(Cell)<{ src?: string }>`
  display: ${(p) => (p.src ? "flex" : "none")};
  height: 100px;
  width: 100px;
  border: 1px solid #ccc;
  position: relative;
  &:before {
    content: "";
    display: flex;
    z-index: 1;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 100%;
    background-image: url(${(props) => props.src});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
`;
const ThumbnailLabel = styled.div<{ label?: string }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  &:before {
    content: "${(props) => props.label}";
    display: flex;
  }
`;
const Thumbnail = styled(ImageCell)``;
const File = styled(ImageCell)`
  flex-grow: 0;
  flex-shrink: 0;
`;
const Files = styled(Cell)<{ label: string }>`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: 30px;
  position: relative;
  flex-wrap: wrap;
  &:before {
    content: "${(props) => props.label}";
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
const Status = styled(Cell)``;

const Home: React.FC = () => {
  const products = useProductplans();
  const updateProduct = useUpdateProduct();
  const handleApprove =
    (id: number, action: "accept" | "reject") =>
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      await updateProduct.execute({
        id,
        ...(action === "accept" ? { isActive: true } : { isRejected: true }),
      });
      products.execute();
    };
  return (
    <>
      <div className="wrap">
        <h2>商品画像動画確認</h2>

        <div className="information-box">
          <Table>
            {products.data?.map((product) => (
              <TableItem key={product.id}>
                <ProductName>{product.name}</ProductName>
                <ThumbnailLabel label="サムネイル">
                  <Thumbnail src={product.thumbnail} />
                </ThumbnailLabel>
                <Files label="画像">
                  <File src={product.file1} />
                  <File src={product.file2} />
                  <File src={product.file3} />
                  <File src={product.file4} />
                  <File src={product.file5} />
                </Files>
                <AccouontName>
                  アカウント名：{product.user?.profile?.nickname}
                </AccouontName>
                <UserId>アカウントID：{product.user?.id}</UserId>
                <Status>
                  {product.isActive
                    ? "承認済"
                    : product.isRejected
                    ? "差替指示済み"
                    : "未承認"}
                </Status>
                <Buttons>
                  <Button
                    onClick={handleApprove(product.id, "accept")}
                    loading={updateProduct.loading}
                  >
                    承認
                  </Button>
                  <Button
                    onClick={handleApprove(product.id, "reject")}
                    loading={updateProduct.loading}
                  >
                    差替指示
                  </Button>
                </Buttons>
              </TableItem>
            ))}
          </Table>

          <div className="btn message">
            <span>
              <input
                type="submit"
                className="confirm"
                // onclick="location.href='master-mosaic-product.html'"
                onClick={() => products.execute()}
                value="更新"
              />
            </span>
          </div>
        </div>

        <h2>メッセージ送信</h2>
        <h3>ユーザー情報</h3>
        <input
          size={20}
          type="text"
          className="wide"
          style={{ width: "20%" }}
        />
        <div className="message-form">
          <textarea
            id="product-text"
            name="product-text"
            rows={5}
            cols={33}
          ></textarea>
        </div>
      </div>

      <div className="btn">
        <span>
          <input
            type="submit"
            className="back"
            // onclick="location.href='master-mosaic-list.html'"
            value="戻る"
          />
        </span>
        <span>
          <input
            type="submit"
            className="confirm"
            data-modal-trigger="modal-confirm"
            value="確認"
          />
        </span>
      </div>
    </>
  );
};

export default Home;
