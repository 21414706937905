import useApi from "../useApi";
import PlanBought from "../../types/PlanBought";

type DataType = PlanBought[];
type Params = {
  userId: number;
};

const path = "/planbought";
const method = "get";
const eagerLoad = true;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
