import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import usePlan from "../../../api/plan/get";
import useUser from "../../../api/user/get";
import useJoinPlan from "../../../api/plan/add";
import Button, { ButtonPrev } from "../../../parts/Button";
import Container from "../../../parts/Container";
import Text from "../../../parts/Text";
import Img from "../../../parts/Img";
import { Link } from "react-router-dom";

const LinkedButton = Button.withComponent(Link);

const Content: React.FC = () => {
  const { id = "" } = useParams<{ id: string }>();

  const navigate = useNavigate();
  console.log(id);

  const planId = parseInt(id);
  const plan = usePlan({ pathParams: { id: planId.toString() } });
  const user = useUser({ pathParams: { id: "me" } });
  const joinPlan = useJoinPlan();
  const balance = user.data?.balance || 0;
  const price = plan.data?.price || 0;

  const handlePurchase = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const result = await joinPlan.execute({ planId });
    if (result instanceof Error) {
      console.log(result);
      if (result.message === "insufficient balance")
        return alert("FFコインが足りません");
      if (result.message === "dupulicate: purchase")
        return alert("購入済みです");
      return alert("エラーが発生しました");
    }
    navigate(`/plan`);
  };

  const isPurchasable = balance - price >= 0;

  return (
    <Container width70L width95>
      <Text>注文確認</Text>
      <Container card round>
        <Text center>ご注文内容</Text>
        <Container row noPadding>
          <Img
            src={plan.data?.thumbnail || ""}
            square
            cover
            width={150}
            borderd
          />
          <Container noPadding>
            <Text>{plan.data?.name}</Text>
            <Text small>{price}C</Text>
            <Text>現在のFFコイン</Text>
            <Text>{balance}C</Text>
            <Text>決済後のFFコイン</Text>
            <Text>{balance - price}C</Text>
          </Container>
        </Container>
      </Container>
      <Container row justifyCenter>
        <ButtonPrev>戻る</ButtonPrev>
        {!isPurchasable ? (
          <LinkedButton to="/charge">コインをチャージする</LinkedButton>
        ) : (
          <Button onClick={handlePurchase} loading={user.loading}>
            購入確定
          </Button>
        )}
      </Container>
    </Container>
  );
};

export default Content;
