import React from "react";
import styled, { css } from "styled-components";

interface Props extends React.HTMLAttributes<HTMLLabelElement> {
  name: string;
  srcInitial?: string;
  icon?: boolean;
}

const ImageInputLabel = styled.label`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
`;
const ImagePreview = styled.span<{ src: string; icon?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 60%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
  border-radius: 5px;
  ${(props) =>
    props.icon &&
    css`
      padding-top: 100%;
      border-radius: 50%;
      width: 100px;
      height: 100px;
      padding: 0;
    `}
`;

const ImageInput: React.FC<Props> = (p) => {
  const { srcInitial, name, ...props } = p;
  const [src, setSrc] = React.useState<string>("");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const file = e.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      const src = e.target?.result;
      setSrc(src as string);
    };
    reader.readAsDataURL(file);
  };
  return (
    <ImageInputLabel {...props}>
      {src ? (
        <ImagePreview src={src} icon={props.icon} />
      ) : srcInitial ? (
        <ImagePreview src={srcInitial} icon={props.icon} />
      ) : (
        <>タップして画像を追加</>
      )}

      <input
        type="file"
        name={name}
        hidden
        onChange={handleChange}
        accept="image/*"
      />
    </ImageInputLabel>
  );
};

export default ImageInput;
