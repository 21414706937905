import useApi from "../useApi";

type DataType = {};
interface Params {
  key: string;
  subject: string;
  content: string;
}

const path = "/mail";
const method = "put";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
