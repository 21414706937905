import { useApiFormData } from "../useApi";
import Product from "../../types/Product";
import { Params as AddParams } from "./add";

type DataType = Product;
interface Params extends Partial<AddParams> {
  id: number;
}

const path = "/productplan";
const method = "put";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApiFormData<DataType, Params>({
  path,
  method,
  eagerLoad,
});
