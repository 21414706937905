import React from "react";
import { Link } from "react-router-dom";
import { ButtonPrev } from "../../../parts/Button";

const Content: React.FC = () => {
  return (
    <>
      <div className="wrap">
        <div className="main">
          <h2>販売方式登録編集</h2>
          <p>販売方式A</p>
        </div>
      </div>
      <div className="btn">
        <ButtonPrev>戻る</ButtonPrev>

        <Link to={`/creator/sales-method/edit`}>
          <input type="submit" value="変更" />
        </Link>
      </div>
    </>
  );
};

export default Content;
