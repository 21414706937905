import useApi from "../useApi";
import ProductWithUser from "../../types/ProductWithUser";

type DataType = ProductWithUser[];
interface Params {
  latest?: boolean;
  limit?: number;
  mine?: boolean;
  userId?: number | string;
  bought?: boolean;
}

const path = "/product";
const method = "get";
const eagerLoad = true;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
