import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Header: React.FC = () => (
  <>
    <section className="pc menu">
      <div className="headline">
        <h1>
          <a href="/">
            <img src="/asset/img/logo.png" alt="ロゴ" title="ロゴ" />
          </a>
        </h1>
      </div>

      <div className="header-button">
        <StyledLink to="/landing/creator">
          <button className="transition-button  creator-button">
            クリエイターについて
          </button>
        </StyledLink>

        <StyledLink to="/landing">
          <button className="transition-button  member-button">
            一般会員について
          </button>
        </StyledLink>

        <StyledLink to="/register">
          <button className="transition-button register-button">
            一般会員登録
          </button>
        </StyledLink>

        <StyledLink to="/login">
          <button className="login-button">
            <i className="fa fa-user" aria-hidden="true" />
            ログイン
          </button>
        </StyledLink>
      </div>
    </section>

    <section className="sp">
      <table>
        <tr>
          <th className="header-logo">
            <a href="/">
              <h1 className="header-logo">
                <img src="/asset/img/logo.png" alt="ロゴ" title="ロゴ" />
              </h1>
            </a>
          </th>
         
          <th className="header-register">
          <StyledLink to="/register">
            <button className="transition-button register-button">
            一般会員登録
            </button>
            </StyledLink>
          </th>

          <th className="header-login">
            <a href="/login">
              <img
                src="/asset/img/login-sp.png"
                alt="ログイン"
                title="ログインボタン"
              />
            </a>
          </th>
        </tr>
      </table>
      <table className="header-sp02">
        <tr>
          <th className="header-sp02">
            <StyledLink to="/landing/creator">
              <button className="transition-button  creator-button">
                クリエイターについて
              </button>
            </StyledLink>{" "}
          </th>

          <th className="header-sp02">
            <StyledLink to="/landing">
              <button className="transition-button  member-button">
                一般会員について
              </button>
            </StyledLink>
          </th>
        </tr>
      </table>
    </section>
  </>
);
export default Header;
