import useNotifies from "../../../api/notify/list";
import React from "react";
import { Link } from "react-router-dom";
import Container from "../../../parts/Container";
import Text from "../../../parts/Text";
import moment from "moment";
import { ButtonPrev } from "parts/Button";
import styled from "styled-components";


const Notice1 = styled.div`
display:flex;
flex-direction: column;
width:90%;
margin:20px;
border-radius:0px;
`
const LinkedContainer = styled(Container.withComponent(Link))`
border: 1px solid #ccc;
& + & {
  border-top: none;
}
`;

const Content: React.FC = () => {
  const notifications = useNotifies();



  return (
    <Notice1>
         <Text large>通知</Text>
      <Notice1>
     
        {notifications.data
          ?.sort((a, b) => -moment(a.createdAt).diff(moment(b.createdAt)))
          ?.map((notification) => (
            <LinkedContainer
              key={notification.id}
              to={`/notify/${notification.id}`}
            >
              <Text>
                {moment(notification.createdAt).format("YYYY/MM/DD HH:mm")}
              </Text>
              <Text>{notification.title}</Text>
            </LinkedContainer>
          ))}
      </Notice1>
      <Container row justifyCenter>
        <ButtonPrev />
      </Container>
    </Notice1>
  );
};

export default Content;
