import { useApiFormData as useApi } from "../useApi";

type DataType = {};
type Params = {
  email: string;
};

const path = "/user/passwordreset";
const method = "post";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
