import { useApiFormData } from "../useApi";

type DataType = {
  paymentId: string;
};
interface Params {
  coin: number;
}

const path = "/payment";
const method = "post";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApiFormData<DataType, Params>({
  path,
  method,
  eagerLoad,
});
