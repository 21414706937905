import useApi from "../useApi";
import Result from "../../types/Product";

type DataType = Result[];
interface Params {
  searchType: "product-tag";
  tag: string;
  page: number;
}

const path = "/search";
const method = "get";
const eagerLoad = true;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
