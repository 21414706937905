import styled from "styled-components";

interface Props {
  src?: string;
}
const Icon = styled.span<Props>`
  display: flex;
  width: 100%;
  position: relative;
  &::before {
    display: flex;
    content: "";
    width: 100%;
    padding-top: 100%;
  }
  &::after {
    display: flex;
    content: "";
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    background-image: url(${(props) => props.src});
    background-size: cover;
    background-position: center;
    border: 1px solid #ccc;
  }
`;
export default Icon;
