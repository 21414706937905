import useApi from "../useApi";
import User from "../../types/User";

type DataType = User[];
interface Params {
  keyword: string;
  searchType: "user";
}

const path = "/search";
const method = "get";
const eagerLoad = true;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
