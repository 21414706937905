import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import usePlan from "../../../api/plan/get";
import useLeavePlan from "../../../api/plan/delete";
import Button from "../../../parts/Button";
import Container from "../../../parts/Container";
import Text from "../../../parts/Text";
import styled from "styled-components";

const Alert = styled(Container)`
  margin: 20px 0;
  padding: 20px;
  color: #f58eb8;
  background-color: #fff1c3;
  border-radius: 10px;
`;

const PlanDetail = styled(Container)`
  margin: 20px 0;
  padding: 20px;
  border-width: 2px;
  border-style: solid;
  border-color: #f58eb8;
  border-radius: 10px;
`;

const ButtonLeave = styled(Button)`
  background-color: #dddddd;
  color: white;
  cursor: not-allowed;
  // 下記はチェックボックスにチェックされているときのスタイル
  label:has(input:checked) + & {
    cursor: pointer;
    background-color: #85cde7;
  }
`;
const ButtonCancel = styled(Button)`
  background-color: inherit;
  color: inherit;
`;

const Content: React.FC = () => {
  const { id = "" } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const planId = parseInt(id);
  const plan = usePlan({ pathParams: { id: planId.toString() } });
  const leavePlan = useLeavePlan({
    params: { leave: true },
    pathParams: { id: planId.toString() },
  });

  const handleLeave = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const result = await leavePlan.execute();
    if (result instanceof Error) {
      return;
    }
    navigate(`/plan`);
  };

  return (
    <Container fullWidth>
      <Container card round>
        <Text center>ブランを退会する</Text>
        <Alert>このページからプランを退会することができます。</Alert>
        <Text blue>無料プランの退会</Text>
        <Text>XXXXXXX</Text>
        <Text blue>無料プランの退会</Text>
        <Text>XXXXXXX</Text>

        <PlanDetail>
          <Text large>退会するプラン</Text>
          <Text>
            {plan.data?.name}/月額{plan.data?.price}円
          </Text>
          <Text>{plan.data?.user?.profile?.nickname}</Text>
        </PlanDetail>

        <Container round card>
          <Text>本当に退会しますか？</Text>
          {leavePlan.error && <Text red>エラーが発生しました</Text>}
          <label>
            <input type="checkbox" />
            上記内容に同意する
          </label>
          <ButtonLeave onClick={handleLeave}>プランの退会を申請</ButtonLeave>
          <ButtonCancel>退会をキャンセル</ButtonCancel>
        </Container>
      </Container>
    </Container>
  );
};

export default Content;
