import React from "react";
import { ButtonPrev } from "../../../parts/Button";

const Content: React.FC = () => {
  return (
    <>
      <div className="wrap">
        <div className="main">
          <h2>会員メール管理</h2>

          <div className="more-button">
            <a href="creator-mail-list.html" className="btn-flat-more">
              <i className="fa fa-caret-right"></i> 会員メール一覧
            </a>
          </div>

          <h2>会員メールテンプレート管理</h2>

          <div className="more-button">
            <a href="creator-template-list.html" className="btn-flat-more">
              <i className="fa fa-caret-right"></i> 会員メールテンプレート一覧
            </a>
          </div>

          <h2>メッセージ管理</h2>

          <div className="more-button">
            <a href="creator-message-list.html" className="btn-flat-more">
              <i className="fa fa-caret-right"></i> メッセージ一覧へ
            </a>
          </div>

          <h2>サイト内メッセージテンプレ追加・編集</h2>

          <div className="more-button">
            <a href="creator-message-template.html" className="btn-flat-more">
              <i className="fa fa-caret-right"></i>
              サイト内メッセージテンプレート一覧
            </a>
          </div>
        </div>

        <div id="creator-side-menu"></div>
      </div>

      <div className="btn">
        <ButtonPrev>戻る</ButtonPrev>
      </div>
    </>
  );
};

export default Content;
