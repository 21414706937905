import React from "react";
import { Link } from "react-router-dom";
import useListProduct from "../../api/productplan/list";
import useUser from "../../api/user/get";
import Container from "../../parts/Container";
import Text from "../../parts/Text";
import Img from "../../parts/Img";
import styled from "styled-components";
import User from "../../parts/User";
import UserLinks from "../../parts/UserLinks";

const ProductPlan = styled(Container.withComponent(Link))`
  padding: 10px;
  width: calc(25% - 7.5px);
  flex-basis: calc(25% - 7.5px);
  flex-grow: 0;
  background: #edf8ff;
  justify-content: start;
  gap: 10px;
  border-radius: 10px;

  @media (max-width: 768px) {
    width: calc(50% - 5px);
    flex-basis: calc(50% - 5px);
  }
  position: relative;
`;

const Title = styled(Text)``;
const Date = styled(Text)``;
const Plans = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
`;
const Plan = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 5px;
  background: #ffffff;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  height: 20px;
`;

const ProductPlans = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
`;

interface IProductPlan {
  userId: number | string;
}

const Home: React.FC<IProductPlan> = (p) => {
  const { userId } = p;
  const products = useListProduct({ params: { userId: `${userId}` } });
  const user = useUser({ pathParams: { id: `${userId}` } });

  return (
    <Container fullWidth>
      <User user={user.data} />
      <UserLinks userId={Number(userId)} />

      <ProductPlans>
        {products?.data?.map((product) => (
          <ProductPlan
            quarter
            to={`/productplan/${product.id}`}
            background="light-blue"
            round
          >
            <Img src={product.thumbnail} square fullWidth cover />
            <Title>{product.name}</Title>
            <Date>{product.createdAt}</Date>
            <Plans>
              {product?.plans?.map?.((plan) => (
                <Plan>{plan.name}</Plan>
              ))}
            </Plans>
          </ProductPlan>
        ))}
      </ProductPlans>
      <Container row></Container>
    </Container>
  );
};

export default Home;
