import React from "react";
import useListProduct from "../../api/product/list";
import useUser from "../../api/user/get";
import { useSearchParams, Link } from "react-router-dom";
import Container from "../../parts/Container";
import styled from "styled-components";
import User from "../../parts/User/User";
import UserLinks from "../../parts/UserLinks";
import ShareButton from "../../parts/ShareButton";

const Products = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
  box-sizing: border-box;
  padding: 20px;
`;

const ProductContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: calc((100% - 60px) / 4);
  width: calc((100% - 60px) / 4);
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 10px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
  box-sizing: border-box;
  padding: 20px;
  text-decoration: none;
`;
const ProductImage = styled.div<{ src: string }>`
  width: 100%;
  padding-top: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: #ccc;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
  box-sizing: border-box;
`;
const ProductName = styled.div`
  width: 100%;
  font-size: 20px;
  line-height: 20px;
  height: 20px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const ProductDescription = styled.div`
  width: 100%;
  height: 32px;
  line-height: 16px;
  font-size: 14px;
  color: #666;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Content: React.FC = () => {
  let [searchParams] = useSearchParams();
  const userId = parseInt(searchParams.get("userId") || "");

  const produtcs = useListProduct({ params: { userId } });
  const user = useUser({ pathParams: { id: `${userId}` } });

  return (
    <Container width70L width95>
      <User user={user.data} />
      <UserLinks userId={userId} />
      <ShareButton>共有する</ShareButton>

      <Products>
        {produtcs.data?.map((product) => (
          <ProductContainer key={product.id} to={`/product/${product.id}`}>
            <ProductImage src={product.thumbnail} />
            <ProductName>{product.name}</ProductName>
            <ProductDescription>{product.description}</ProductDescription>
          </ProductContainer>
        ))}
      </Products>
    </Container>
  );
};

export default Content;
