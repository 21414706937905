import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useUpdateProduct from "../../../api/product/update";
import useProduct from "../../../api/product/get";
import useListCategory from "../../../api/category/list";
import Button, { ButtonPrev } from "../../../parts/Button";
import TagInput from "../../../parts/TagInput";
import Text from "../../../parts/Text";
import { cautionThumbnail } from "../../../consts/texts";
import styled from "styled-components";
import ImageInput from "../../../parts/ImageInput";
import FileContainer from "../../../parts/FileContainer";

type TFiles =
  | "file1"
  | "file2"
  | "file3"
  | "file4"
  | "file5"
  | "file6"
  | "file7"
  | "file8"
  | "file9"
  | "file10"
  | "file11"
  | "file12"
  | "file13"
  | "file14"
  | "file15"
  | "file16"
  | "file17"
  | "file18"
  | "file19"
  | "file20";
const Caution = styled.p`
  font-size: 0.8rem;
  color: #6d6d6d;
  white-space: pre-wrap;
  text-wrap: normal;
`;

const Form = styled.form`
  width: 95%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    width: 70%;
  }
`;

const Content: React.FC = () => {
  const { id = "0" } = useParams<{ id?: string }>();
  const __id = parseInt(id);
  const navigate = useNavigate();

  const [name, setName] = React.useState<string>("");
  const [price, setPrice] = React.useState<number>(0);
  const [description, setDescription] = React.useState<string>("");
  const [type, setType] = React.useState<"image" | "video" | "pdf">("image");
  const [video, setVideo] = React.useState<File | undefined>(undefined);
  const [sampleVideo, setSampleVideo] = React.useState<File | undefined>(
    undefined
  );
  const [category1, setCategory1] = React.useState<number | undefined>(
    undefined
  );
  const [tags, setTags] = React.useState<string[]>([]);

  const updateProduct = useUpdateProduct();
  const product = useProduct({ pathParams: { id } });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const file1 = e.currentTarget?.file1?.files?.[0];
    const file2 = e.currentTarget?.file2?.files?.[0];
    const file3 = e.currentTarget?.file3?.files?.[0];
    const file4 = e.currentTarget?.file4?.files?.[0];
    const file5 = e.currentTarget?.file5?.files?.[0];
    const file6 = e.currentTarget?.file6?.files?.[0];
    const file7 = e.currentTarget?.file7?.files?.[0];
    const file8 = e.currentTarget?.file8?.files?.[0];
    const file9 = e.currentTarget?.file9?.files?.[0];
    const file10 = e.currentTarget?.file10?.files?.[0];
    const file11 = e.currentTarget?.file11?.files?.[0];
    const file12 = e.currentTarget?.file12?.files?.[0];
    const file13 = e.currentTarget?.file13?.files?.[0];
    const file14 = e.currentTarget?.file14?.files?.[0];
    const file15 = e.currentTarget?.file15?.files?.[0];
    const file16 = e.currentTarget?.file16?.files?.[0];
    const file17 = e.currentTarget?.file17?.files?.[0];
    const file18 = e.currentTarget?.file18?.files?.[0];
    const file19 = e.currentTarget?.file19?.files?.[0];
    const file20 = e.currentTarget?.file20?.files?.[0];

    const thumbnail = e.currentTarget?.thumbnail?.files?.[0];

    const result = await updateProduct.execute({
      id: __id,
      name,
      price,
      description,
      thumbnail,
      tags,
      category1,
      type,
      file1,
      file2,
      file3,
      file4,
      file5,
      file6,
      file7,
      file8,
      file9,
      file10,
      file11,
      file12,
      file13,
      file14,
      file15,
      file16,
      file17,
      file18,
      file19,
      file20,
      video,
      sampleVideo,
    });
    console.log(result);
    if (result instanceof Error) return;
    alert("商品を修正しました");
    navigate("/creator/product");
  };
  const onChangeFile =
    (n: number | "video" | "sample") =>
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (n === "sample") setSampleVideo(file);
      if (n === "video") setVideo(file);
    };

  const listCategory = useListCategory();

  useEffect(() => {
    if (!product.data) return;
    if (!product.called) return;
    if (product.loading) return;
    if (product.error) return;
    setName(product.data.name);
    setPrice(product.data.price);
    setDescription(product.data.description);
    setCategory1(product.data?.category1?.id);
    setTags(product.data.tags?.map((tag) => tag.name) ?? []);
    setType(product.data.type);
  }, [product.loading, product.data, product.called, product.error]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    const isTextarea = (e?.target as HTMLElement)?.tagName === "TEXTAREA";
    if (isTextarea) return;
    const isEnter = e.key === "Enter";
    if (isEnter) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  if (product.loading) return <div>loading...</div>;
  return (
    <Form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
      <div>
        <div className="main">
          <h2 className="page-title title-blank">買い切り商品修正</h2>

          <div className="information-wrap">
            <div className="information-box information-border information-contents">
              <h3>
                商品名 <span className="required">必須</span>
              </h3>
              <input
                size={20}
                type="text"
                className="wide"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="information-box information-border information-contents">
              <h3>
                サムネイル画像<span className="required">必須</span>
              </h3>
              <div>
                <ImageInput
                  name="thumbnail"
                  srcInitial={product.data?.thumbnail}
                />
                <br />
                <Caution>{cautionThumbnail}</Caution>
              </div>
            </div>
            <div className="information-box information-border information-contents">
              <h3>
                商品説明<span className="required">必須</span>
              </h3>
              <textarea
                id="product-text"
                name="product-text"
                rows={5}
                cols={33}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
            <div className="information-box information-border information-contents">
              <h3>
                商品カテゴリ<span className="required">必須</span>
              </h3>
              <div className="cp_ipselect cp_sl">
                <select
                  required
                  value={category1}
                  onChange={(e) => setCategory1(parseInt(e.target.value))}
                >
                  <option value="" hidden>
                    選択
                  </option>
                  {listCategory.data?.map((category, index) => (
                    <option value={category.id} key={index}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="information-box information-border information-contents">
              <h3>
                商品タグ<span className="required">必須</span>
              </h3>
              <TagInput tags={tags} setTags={setTags} />
            </div>

            <div className="information-box information-contents">
              <h3>
                商品タイプ<span className="required">必須</span>
              </h3>
              <div className="cp_ipselect cp_sl">
                <div></div>
                <select
                  required
                  value={type}
                  onChange={(e) => {
                    const t =
                      e.target.value === "image"
                        ? ("image" as const)
                        : e.target.value === "pdf"
                        ? ("pdf" as const)
                        : ("video" as const);
                    setType(t);
                  }}
                >
                  <option value={"image"}>画像</option>
                  <option value={"pdf"}>pdf</option>
                  <option value={"video"}>動画</option>
                </select>
              </div>
            </div>
            <div className="information-border information-contents caution-contents">
              <span className="caution">
                ※画像5枚以上投稿の場合はpdfにて投稿してください
              </span>
            </div>

            <FileContainer>
              {type === "image" &&
                [...new Array(20)].map((_, index) => (
                  <div
                    className="information-box information-border information-contents"
                    key={index}
                  >
                    <h3> ファイル{index + 1}</h3>
                    <ImageInput
                      name={`file${index + 1}`}
                      srcInitial={
                        product.data?.[`file${index + 1}` as TFiles] as string
                      }
                    />
                  </div>
                ))}
              {type === "pdf" &&
                [...new Array(20)].map((_, index) => (
                  <div className="information-box information-border information-contents">
                    <h3> ファイル{index + 1}</h3>
                    <input
                      type="file"
                      accept="application/pdf"
                      name={`file${index + 1}`}
                    />
                  </div>
                ))}
              {type === "video" && (
                <>
                  <div className="information-box information-border information-contents">
                    <h3> サンプル動画</h3>
                    <input
                      type="file"
                      accept="video/*"
                      onChange={onChangeFile("sample")}
                    />
                  </div>
                  <div className="information-box information-border information-contents">
                    <h3> 動画</h3>
                    <input
                      type="file"
                      accept="video/*"
                      onChange={onChangeFile("video")}
                    />
                  </div>
                </>
              )}
            </FileContainer>
            <div className="information-box information-border information-contents">
              <h3>
                価格設定<span className="required">必須</span>
              </h3>
              <input
                size={20}
                type="number"
                className="wide"
                value={price.toString()}
                onChange={(e) =>
                  !Number.isNaN(e.target.value) &&
                  setPrice(Number(e.target.value) - 0)
                }
              />
            </div>
          </div>
        </div>
      </div>

      {updateProduct.error && <Text red> エラーが発生しました。</Text>}

      <div className="btn">
        <ButtonPrev>戻る</ButtonPrev>
        <Button loading={updateProduct.loading}>確定</Button>
      </div>
    </Form>
  );
};

export default Content;
