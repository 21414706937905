import { useApiFormData } from "../useApi";
import UserType from "../../types/UserType";

type DataType = {
  id: number;
  nickname?: string;
  email?: string;
  password?: string;
  role?: UserType;
  icon: File;
};
interface Params {}

const path = "/user";
const method = "post";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApiFormData<DataType, Params>({
  path,
  method,
  eagerLoad,
});
