import useApi from "../useApi";
import Message from "../../types/Message";

type DataType = Message;
interface Params {}

const path = "/message/:id";
const method = "delete";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
