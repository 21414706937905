import useApi from "../useApi";
import Mail from "../../types/Mail";

type DataType = Mail;
interface Params {}

const path = "/mail/:id";
const method = "get";
const eagerLoad = true;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
