import React from "react";
import { Link } from "react-router-dom";
import CategoryList from "../CategoryList";
import TagList from "../TagList";

const Content: React.FC = () => {
  return (
    <>
      <div className="wrap">
        <div className="main">
          <div className="tabs">
            <input id="tab01" type="radio" name="tab_item" checked />
            <label className="tab_item" htmlFor="tab01">
              カテゴリ
            </label>

            <input id="tab02" type="radio" name="tab_item" />
            <label className="tab_item" htmlFor="tab02">
              タグ
            </label>

            <div className="tab_content" id="tab01_content">
              <CategoryList />
            </div>
            <div className="tab_content" id="tab02_content">
              <TagList />
            </div>
          </div>
      
          <div className="more-button">
         
          </div>
        
        </div>

        <div id="creator-side-menu"></div>
      </div>

      <div className="btn">
        <Link to={"/creator/setting"}>
          <input type="submit" className="back" value="戻る" />
        </Link>
      </div>
    </>
  );
};

export default Content;
