import React from "react";
//import SideBar from "../../../parts/SideBar/SideBarMember";
import styled from "styled-components";
import Modal, {
  ModalTitle,
  ModalText,
  ModalButton,
} from "../../../parts/Modal";
import { Link, useNavigate } from "react-router-dom";
import Button from "parts/Button";
import useUpdateUser from "../../../api/user/update";

const Wrapper = styled.div`
  //  display: flex;
  // flex-direction: row;
  // width: 100%;
  // justify-content: start;
  // align-items: start;
`;
const Container = styled.div`
  //  display: flex;
  // flex-direction: column;
  // width: 100%;
  // justify-content: start;
  // align-items: center;
`;

const Content: React.FC = () => {
  const navigate = useNavigate();
  const [modal, setModal] = React.useState<boolean>(false);
  const handleOpenModal = () => setModal(true);
  const handleCloseModal = () => setModal(false);

  const updateUser = useUpdateUser();
  const handleUpdateUser = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const result = await updateUser.execute({
      id: 1,
      approveProgress: true,
    });
    if (result instanceof Error) {
      alert("エラーが発生しました。");
      return;
    }
    navigate("/member");
  };

  return (
    <>
      <Wrapper>
        {/* <SideBar />*/}
        <Container>
          <div className="wrap">
            <div className="main">
              <h2>認証登録確認</h2>
              <p>
                ステータス
                <br />
                未認証
              </p>
            </div>
          </div>
          <div className="btn">
            <Link to="/member">
              <input
                type="submit"
                className="back"
                // onclick="location.href='unapproved-top.html'"
                value="戻る"
              />
            </Link>
            <span>
              <input
                type="submit"
                onClick={handleOpenModal}
                // onclick="location.href='unapproved-certification-confirm.html'"
                value="認証登録確認"
              />
            </span>
          </div>
        </Container>
      </Wrapper>
      <Modal open={modal}>
        <ModalTitle>認証登録確認</ModalTitle>
        <ModalText>認証確定へ</ModalText>
        <ModalButton>
          <Button color="info" onClick={handleCloseModal}>
            戻る
          </Button>
          <Button loading={updateUser.loading} onClick={handleUpdateUser}>
            確認
          </Button>
        </ModalButton>
      </Modal>
    </>
  );
};

export default Content;
