import React from "react";
import { Link } from "react-router-dom";
import Button, { ButtonPrev } from "../../parts/Button";
import Container from "../../parts/Container";
import Text from "../../parts/Text";
import useUser from "../../api/user/get";
const LinkButton = Button.withComponent(Link);

const Content: React.FC = () => {
  const coins = [
    { coin: 500, price: 500 },
    { coin: 1000, price: 1000 },
    { coin: 5050, price: 5000 },
    { coin: 10200, price: 10000 },
  ];

  const user = useUser({ pathParams: { id: "me" } });

  return (
    <Container fullWidth maxWidth750>
      <Container>
        <Text center large>
          チャージ金額を選択
        </Text>
      </Container>
      <Container card round paddingX={50} paddingY={30} row wrap>
        {coins.map((coin, index) => (
          <LinkButton
            key={index}
            to={`/charge/${coin.coin}`}
            // 横幅を50%からgap分10px引いた値にする
            flexBasis={"calc(50% - 10px)"}
            shrink
            flex
            gap={10}
          >
            {coin.coin?.toLocaleString()}C
            <Text red inline>
              ({coin.price}円)
            </Text>
          </LinkButton>
        ))}
      </Container>
      <Container card round paddingX={50}>
        現在残高：{user.data?.balance}C
      </Container>
      <Container row justifyCenter alignCenter>
        <ButtonPrev />
      </Container>
    </Container>
  );
};

export default Content;
