import Layout from "../../themplate/Layout";
import Container from "../../parts/Container";
import styled from "styled-components";
import { Link } from "react-router-dom";
import useMails from "../../api/mail/list";

const Header = styled.header`
  margin: 0;
`;
const MaillistContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0;
  padding: 20px;
  gap: 20px;
  @media (min-width: 768px) {
    width: 70%;
  }
`;
const MailItem = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  gap: 10px;
`;
const MailSubject = styled.div`
  font-weight: bold;
  width: 100%;
  font-size: 16px;
  margin: 0;
  line-height: 1;
  height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const MailContent = styled.div`
  width: 100%;
  font-size: 16px;
  margin: 0;
  line-height: 1;
  height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
  text-align: left;
`;

const Page: React.FC = () => {
  const mails = useMails();
  return (
    <Layout type="master">
      <MaillistContainer>
        <Header>メール一覧</Header>
        {mails.data?.map?.((mail) => (
          <MailItem to={`/master/mail/${mail.key}`} key={mail.id}>
            <MailSubject>{mail.subject}</MailSubject>
            <MailContent>{mail.content}</MailContent>
          </MailItem>
        ))}
      </MaillistContainer>
    </Layout>
  );
};

export default Page;
