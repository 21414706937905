import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../libs/auth";
import styled from "styled-components";
import LogoImage from "../../imgs/logo-master.png";

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
`;
const HeaderItemContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0;
  padding: 0;
  margin: 0;
`;
const HeaderItem = styled(Link)`
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: none;
  width: 10%;
  flex-grow: 1;
  flex-basis: 10%;
  flex-shrink: 1;
  align-items: start;
  justify-content: start;
  background-color: #c2d1db;
  color: #1b4059;
  transition: all 0.3s;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.05em;
  opacity: 1;
  min-width: 90px;
  overflow: hidden;
  max-height: 60px;
  &:hover {
    max-height: 10000px;
    height: fit-content;
  }
`;
const HeaderSubItemContainer = styled.div`
  flex-direction: column;
  gap: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  background-color: #c2d1db;
  color: #1b4059;
  filter: none;
`;
const HeaderSubItem = styled(Link)`
  display: flex;
  background-color: #265574;
  height: 60px;
  position: relative;
  transition: all 0.3s;
  color: #fff;
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: center;
  position: relative;
  text-decoration: none;
  &:after {
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
    content: "";
    display: block;
    height: 6px;
    position: absolute;
    right: 15px;
    top: calc(50% - 5px);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    width: 6px;
  }
  opacity: 1;
`;
const HeaderTop = styled.div`
  display: flex;
  padding: 2% 4%;
  text-align: end;
`;
const Logo = styled.span<{ src: string }>`
  position: relative;
  overflow: hidden;
  height: fit-content;
  width: 15%;
  &:before {
    content: "";
    display: block;
    padding-top: ${(622 / 2740) * 100}%;
    width: 100%;
  }
  &:after {
    z-index: 1;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${(p) => p.src});
    background-size: cover;
    background-position: center;
  }
`;
const HeaderItemLabel = styled.span`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 60px;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  color: inherit;
`;
const Logout = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100px;
  border: none;
  background-color: inherit;
  color: #70b4cc;
  font-weight: 700;
  padding: 0.25em 0.5em;
  position: relative;
  text-decoration: none;
  transition: 0.4s;
  &:before {
    display: inline-block;
    content: "\f0da";
    color: #82afcc;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
  }
`;
const Spacer = styled.span`
  flex-grow: 1;
  flex-basis: auto;
  flex-shrink: 1;
`;

const items = [
  {
    label: "ユーザー管理",
    path: "/master/user",
    child: [
      {
        label: "管理ユーザー一覧",
        path: "/master/user?type=admin",
      },
      {
        label: "販売ユーザー一覧",
        path: "/master/user?type=creator",
      },
      {
        label: "会員ユーザー一覧",
        path: "/master/member",
      },
    ],
  },
  {
    label: "モザイク処理チェック",
    path: "/master/mosaic",
    child: [
      {
        label: "商品画像動画",
        path: "/master/mosaic/product",
      },
      {
        label: "継続商品画像動画",
        path: "/master/mosaic/productplan",
      },
      {
        label: "アカウント画像",
        path: "/master/mosaic/user",
      },
      {
        label: "販売ページ",
        path: "/master/mosaic/sales",
      },
    ],
  },
  {
    label: "全購入管理",
    path: "/master/purchase",
  },
  {
    label: "ポイント購入履歴",
    path: "/master/payment",
  },
  {
    label: "ポイント編集",
    path: "/master/point",
  },
  {
    label: "全売上管理",
    path: "/master/earning",
  },
  {
    label: "メッセージ管理",
    path: "/master/message",
  },
  {
    label: "お知らせ管理",
    path: "/master/notify",
  },
  {
    label: "メール管理",
    path: "/master/mail",
  },
  {
    label: "カテゴリ管理",
    path: "/master/category",
  },
];

const Header: React.FC = () => {
  const { logout } = useAuth();
  return (
    <HeaderContainer>
      <HeaderTop>
        <Logo src={LogoImage} />
        <Spacer />
        <Logout onClick={logout}>ログアウト</Logout>
      </HeaderTop>
      <HeaderItemContainer>
        {items.map((item) => (
          <HeaderItem to={item.path}>
            <HeaderItemLabel>{item.label}</HeaderItemLabel>

            <HeaderSubItemContainer>
              {item.child?.map((child) => (
                <HeaderSubItem to={child.path}>{child.label}</HeaderSubItem>
              ))}
            </HeaderSubItemContainer>
          </HeaderItem>
        ))}
      </HeaderItemContainer>
    </HeaderContainer>
  );
};
export default Header;
