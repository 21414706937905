import React from "react";
import { Link } from "react-router-dom";
import useProductlist from "../../api/product/list";
import styled from "styled-components";
import Text from "../../parts/Text";

const ProductImage = styled.div<{ src: string; text: string }>`
  width: 80%;
  position: relative;
  background: url(${({ src }) => src});
  background-size: cover;

  &:after {
    content: "${({ text }) => text}";
    display: block;
    background: skyblue;
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    font-size: 15px;
    line-height: 1;
    padding: 5px 10px;
  }
  &:before {
    content: "";
    display: block;
    padding-top: 80%;
  }
`;
const ProductListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ProductlistItem = styled(Link)`
 // margin: 0.5em ;
  padding: 0;
  border-radius: 5%;
  width: 24%;
  background: #edf8ff;
  box-shadow: 0 4px 8px rgb(124 137 158 / 8%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;  
  justify-content: start;
  gap: 10px;

}
 

`;

interface ProductListProps {
  type?: "new" | "pickup" | "default";
  length?: number;
}
const ProductList: React.FC<ProductListProps> = (props) => {
  const { length = 4, type } = props;
  const { data } = useProductlist({ params: { latest: true } });
  return (
    <ProductListContainer className="item-wrap">
      {/* {JSON.stringify(data)} */}
      {data?.slice(0, length)?.map((product, index) => (
        <ProductlistItem
          className="item-list item-link"
          to={`/product/${product.id}`}
        >
          <ProductImage src={product.thumbnail} text={type==="default"?"商品" :type || "new"} />
          <h3 className="item-title">{product.name}</h3>
          <Text>￥{product.price}</Text>
          <p className="item-text-tax">{product.description}</p>
        </ProductlistItem>
      ))}
    </ProductListContainer>
  );
};

export default ProductList;
