import React, { useEffect, useRef } from "react";
import Input from "../../../parts/Input";
import Text from "../../../parts/Text";
import Button, { ButtonPrev } from "../../../parts/Button";
import Container from "../../../parts/Container";
import useEditPlan from "../../../api/plan/update";
import usePlan from "../../../api/plan/get";
import { useNavigate, useParams } from "react-router-dom";
const MultilineText = Input.withComponent("textarea");
const Form = Container.withComponent("form");

const Width = "200px";

const Content: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const formRef = useRef<HTMLFormElement>(null);
  const plan = usePlan({ pathParams: { id: id as string } });

  const navigate = useNavigate();

  const editPlan = useEditPlan();

  useEffect(() => {
    if (plan.loading) return;
    if (plan.error) return;
    if (!plan.data) return;
    const { name, description, price } = plan.data;
    if (!formRef.current) return;
    const form = formRef.current;
    form.planName.value = name;
    form.description.value = description;
    form.price.value = price;
    // form.file.value = thumbnail;
  }, [plan]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const _id = Number(id);
    console.log(e.currentTarget);
    const name = e.currentTarget?.planName?.value;
    const description = e.currentTarget?.description?.value;
    const thumbnail = e.currentTarget?.file?.files?.[0];
    if (isNaN(_id)) return alert("idが不正です");
    const result = await editPlan.execute({
      id: _id,
      name,
      description,
      thumbnail,
      // price,
    });
    if (result instanceof Error) {
      alert("エラーが発生しました");
      return;
    }
    navigate("/creator/plan");
  };
  return (
    <Form width70L width95 onSubmit={handleSubmit} ref={formRef}>
      <h2 className="page-title">プランの編集</h2>
      <Container card round>
        <Container row justifyCenter alignCenter>
          <Text flexBasis={Width}>プラン名</Text>
          <Input type="text" grow name="planName" />
        </Container>
        <Container row justifyCenter alignCenter>
          <Text flexBasis={Width}>{"プランの説明\n具体的な会員特典など"}</Text>
          <MultilineText grow name="description" />
        </Container>
        <Container row justifyCenter alignCenter>
          <Text flexBasis={Width}>サムネイル画像</Text>
          <Input className="file-input" grow type="file" name="file" />
        </Container>
        <Container row justifyCenter alignCenter>
          <Text flexBasis={Width}>月額</Text>
          <Input grow type="text" name="price" disabled />
        </Container>
      </Container>
      <Container row justifyCenter>
        <ButtonPrev>戻る</ButtonPrev>
        <Button loading={editPlan.loading}>確定</Button>
      </Container>
    </Form>
  );
};

export default Content;
