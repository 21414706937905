import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import useUser from "../../../api/user/get";

const Wrapper = styled.div`
  //display: flex;
  //flex-direction: row;
  // width: 100%;
  // justify-content: start;
  // align-items: start;
`;
const Container = styled.div`
  //display: flex;
  //flex-direction: column;
  // width: 100%;
  // justify-content: start;
  // align-items: center;
`;

const Content: React.FC = () => {
  const user = useUser({ pathParams: { id: "me" } });
  if (user.loading) return <>loading...</>;
  return (
    <>
      <Wrapper>
        {/*  <SideBar />*/}
        <Container>
          <div className="wrap">
            <div className="main">
              <h2>会員ステータス</h2>
              <p>
                未認証です。
                <br />
                会員情報を登録して認証登録を確認してください。
              </p>
              <div className="more-button">
                <Link to="/member/auth-confirm" className="btn-flat-more">
                  <i className="fa fa-caret-right"></i> 認証登録確認
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </Wrapper>
    </>
  );
};

export default Content;
