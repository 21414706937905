import useApi from "../useApi";
import ProductPlanWithUser from "../../types/ProductPlanWithUser";

type DataType = ProductPlanWithUser;
interface Params {
  latest?: boolean;
  limit?: number;
  mine?: boolean;
}

const path = "/productplan/:id";
const method = "get";
const eagerLoad = true;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
