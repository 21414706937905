import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Modal, {
  ModalTitle,
  ModalText,
  ModalButton,
} from "../../../parts/Modal";
import useProductList from "../../../api/productplan/list";
import useDeleteProduct from "../../../api/productplan/delete";
import styles from "./Product.module.scss";
import Button, { ButtonPrev } from "../../../parts/Button";
import Container from "../../../parts/Container";
import { v4 as uuid } from "uuid";

const LinkedButton = Button.withComponent(Link);

const Content: React.FC = () => {
  const [modal, setModal] = React.useState<number | undefined>(undefined);
  const products = useProductList({
    params: { mine: true, limit: 999, session: uuid() },
  });
  const deleteProduct = useDeleteProduct();
  const close = () => setModal(undefined);
  const handleShare =
    (id: number) => async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      const path = `/productplan/${id}`;
      const url = `${window.location.origin}${path}`;
      await navigator.clipboard.writeText(url);
      alert("URLをコピーしました");
    };
  const handleDelete =
    (id: string) => async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (!window.confirm("削除しますか？")) return;
      const result = await deleteProduct.execute(undefined, { id });
      if (result instanceof Error) {
        alert("削除に失敗しました");
      } else {
        alert("削除しました");
      }
      products.execute({ mine: true, limit: 999 });
    };
  useEffect(() => {
    products.execute({ mine: true, limit: 999 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container width70L width95>
        <div className="main">
          <h2 className="page-title title-blank">継続プラン商品一覧</h2>
          <div className="information-button">
            <Link to="/creator/productplan/add">
              <input type="submit" className="add" value="＋新規追加" />
            </Link>
          </div>

          <div className="information-box">
            <div className={styles.product_container}>
              {products?.data?.map((product, index) => (
                <div key={index} className={styles.product}>
                  <img
                    className={styles.product_image}
                    src={product.thumbnail}
                    alt={product.name}
                  />
                  <div className={styles.product_info}>
                    <div className={styles.product_name}>{product.name}</div>
                    <div className={styles.buttons}>
                      <LinkedButton to={`/creator/productplan/${product.id}`}>
                        編集
                      </LinkedButton>

                      <Button
                        color="secondary"
                        onClick={handleShare(product.id)}
                      >
                        共有
                      </Button>

                      <Button
                        color="secondary"
                        className="delete"
                        loading={deleteProduct.loading}
                        onClick={handleDelete(`${product.id}`)}
                      >
                        削除
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>

      <Container row className="btn">
        <ButtonPrev>戻る</ButtonPrev>
        <span>
          <input
            type="submit"
            // onclick="location.href='creator-pick-up.html'"
            value="ピックアップ商品編集"
          />
        </span>
      </Container>
      <Modal onClose={close} open={modal !== undefined}>
        <ModalTitle>商品を複製しますか？</ModalTitle>
        <ModalText>
          {products.data?.find((product) => product.id === modal)?.name}
        </ModalText>
        <ModalButton>
          <span>
            <input
              type="submit"
              className="back "
              onClick={close}
              value="いいえ"
            />
          </span>
          <span>
            <input
              type="submit"
              value="はい"
              //  onclick="koshin()"
            />
          </span>
        </ModalButton>
      </Modal>
    </>
  );
};

export default Content;
