import React from "react";
import "./Landing.scss";
import topimg from "./img/topimg.png";
import btn1 from "./img/btn1.png";
import ffimage1 from "./img/ffimage1.png";
import girlimg1 from "./img/girlimg1.png";
import topimg_sp from "./img/topimg_sp.png";
import girlimg2 from "./img/girlimg2.png";
import girlimg3 from "./img/girlimg3.png";
import model1 from "./img/model1.png";
import model2 from "./img/model2.png";
import model3 from "./img/model3.png";
import model4 from "./img/model4.png";
import orag from "./img/orag.png";

const Landing: React.FC = () => (
  <div className="landing">
    <div className="mainimg">
      <div className="mainimg2">
        <img src={topimg} alt="メイン画像" />
        <div className="topbtn">
          <a href="/contact">
            <img src={btn1} alt="登録はお問い合わせまで" />
          </a>
        </div>
      </div>
    </div>
    <div className="mainimgsp">
      <a href="/contact">
        <img src={topimg_sp} alt="メイン画像" />
      </a>
    </div>
    <main>
      <div className="catch">
        <div className="mainimg">
          <div className="card-container">
            <div className="card-text">
              <p>
                Funny Fans［ファニーファンズ］は、
                あなたを応援したいファンからさまざまな支援を受けることができるサービスです。
              </p>

              <p>
                イラストレーターやコスプレイヤーなど、
                創作活動を軸に活躍するクリエイターの応援や
                コニュニティー作りに最適な場所です。
              </p>

              <p>どなたでも無料でご利用いただけます。</p>
            </div>
            <div className="card-img">
              <img src={ffimage1} alt="" />
            </div>
          </div>
        </div>

        <div className="mainimgsp">
          <div className="card-containersp">
            <div className="card-textsp">
              <p>
                Funny Fans［ファニーファンズ］は、
                あなたを応援したいファンから継続的な支援を
                受けることができるサービスです。
              </p>

              <p>
                イラストレーターやコスプレイヤーなど、
                創作活動を軸に活躍するクリエイターの応援や
                コニュニティー作りに最適な場所です。
              </p>

              <p>どなたでも無料でご利用いただけます。</p>
            </div>
            <div className="card-imgsp">
              <img src={ffimage1} alt="" />
            </div>
          </div>
        </div>
      </div>
      <section id="1" className="gray-back">
        <h2 className="center creator">ご利用イメージ</h2>
        <div className="container center">
          <div className="goriyou">
            <div className="goriyouflame">
              <div className="goriyou1">
                <div className="gornum">
                  <h3 className="gorinum">
                    <span>1</span>
                  </h3>
                </div>
                <div className="gortxt">
                  <h5 className="gorttl">商品を登録</h5>
                  <p>
                    買い切り商品、継続プランを登録します。
                    支援者に提供する商品や特典を決めて、 商品を登録します。
                  </p>
                </div>
                <div className="gorimg">
                  <img src={girlimg1} alt="ここに商品" />
                </div>
              </div>
            </div>
            <div className="goriyouflame">
              <div className="goriyou1">
                <div className="gornum">
                  <h3 className="gorinum">
                    <span>2</span>
                  </h3>
                </div>
                <div className="gortxt">
                  <h5 className="gorttl">記事で情報や特典を配信</h5>
                  <p>
                    特典記事や限定コンテンツを、自分のペースで投稿。
                    ブログ感覚で書けます。
                  </p>
                </div>
                <div className="gorimg">
                  <img src={girlimg2} alt="ここに商品" />
                </div>
              </div>
            </div>
            <div className="goriyouflame">
              <div className="goriyou1">
                <div className="gornum">
                  <h3 className="gorinum">
                    <span>3</span>
                  </h3>
                </div>
                <div className="gortxt">
                  <h5 className="gorttl">コメントや応援が届く</h5>
                  <p>投稿した記事に、支援者から応援コメントが届きます。</p>
                </div>
                <div className="gorimg">
                  <img src={girlimg3} alt="ここに商品" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="2">
        <h2 className="center creator">モデルケース</h2>
        <div className="modelcase">
          <div className="md1">
            <div className="mdtop">
              <div className="mdimg">
                <img src={model1} alt="" />
              </div>
              <div className="mdttl">
                <span className="tgf">モデルケース1</span>
                <span className="tgf2">イラストレーター</span>
              </div>
            </div>
            <div className="mdinner">
              <p className="ex creator plan">継続プランのモデル例</p>
              <h6 className="mdtxt1">無料プラン</h6>
              <span>新作やラフ絵イラスト公開</span>
              <h6 className="mdtxt1">500円プラン</h6>
              <span>
                上記プラン加えて次回作の先行公開
                Twitter等で投稿した作品に、枚数をプラスして公開
              </span>
              <h6 className="mdtxt1">1000円プラン</h6>
              <span>上記プラン加えてFunny Fans限定公開</span>

              <p className="ex">買い切り商品も販売可能！</p>
              <h6 className="mdtxt2">イラスト集・デジタルデータ</h6>
              <span>プラン販売とは別に、単品商品の購入が可能</span>
            </div>
          </div>

          <div className="md1">
            <div className="mdtop">
              <div className="mdimg">
                <img src={model2} alt="" />
              </div>
              <div className="mdttl">
                <span className="tgf">モデルケース2</span>
                <span className="tgf2">コスプレイヤー</span>
              </div>
            </div>
            <div className="mdinner">
              <p className="ex creator plan">継続プランのモデル例</p>
              <h6 className="mdtxt1">無料プラン</h6>
              <span>活動に関する告知や日記</span>
              <h6 className="mdtxt1">500円プラン</h6>
              <span>
                上記プラン加えて次回コスプレの先行公開
                Twitter等で投稿した写真に、枚数をプラスして公開
              </span>
              <h6 className="mdtxt1">1000円プラン</h6>
              <span>上記プラン加えてFunny Fans限定公開 リクエスト写真公開</span>
              <p className="ex">買い切り商品販売例</p>
              <h6 className="mdtxt2">写真集・デジタルデータ販売</h6>
              <span>プラン販売とは別に、単品商品の購入が可能</span>
            </div>
          </div>
        </div>
        <div className="modelcase">
          <div className="md1">
            <div className="mdtop">
              <div className="mdimg">
                <img src={model3} alt="" />
              </div>
              <div className="mdttl">
                <span className="tgf">モデルケース3</span>
                <span className="tgf2">モデル</span>
              </div>
            </div>
            <div className="mdinner">
              <p className="ex creator plan">継続プランのモデル例</p>
              <h6 className="mdtxt1">無料プラン</h6>
              <span>活動に関する告知や日記</span>
              <h6 className="mdtxt1">500円プラン</h6>
              <span>
                上記プラン加えて次回作の先行公開
                Twitter等で投稿した写真に、枚数をプラスして公開
              </span>
              <h6 className="mdtxt1">5000円プラン</h6>
              <span>上記プラン加えてFunny Fans限定公開コンテンツ</span>
              <p className="ex">買い切り商品販売例</p>
              <h6 className="mdtxt2">サイン付き写真販売</h6>
              <span>プラン販売とは別に、単品商品の購入が可能</span>
            </div>
          </div>

          <div className="md1">
            <div className="mdtop">
              <div className="mdimg">
                <img src={model4} alt="" />
              </div>
              <div className="mdttl">
                <span className="tgf">モデルケース4</span>
                <span className="tgf2">配信者、Vtuber</span>
              </div>
            </div>
            <div className="mdinner">
              <p className="ex creator plan">継続プランのモデル例</p>
              <h6 className="mdtxt1">無料プラン</h6>
              <span>活動に関する告知や日記</span>
              <h6 className="mdtxt1">500円プラン</h6>
              <span>
                上記プラン加えてボイスのリクエスト受付
                シチュエーションボイスの配布
              </span>
              <h6 className="mdtxt1">3000円プラン</h6>
              <span>上記プラン加えてFunny Fans限定動画 先行公開など</span>
              <p className="ex">買い切り商品販売例</p>
              <h6 className="mdtxt2">シチュエーションボイス販売</h6>
              <span>プラン販売とは別に、単品商品の購入が可能</span>
            </div>
          </div>
        </div>
      </section>

      <section id="3" className="gray-back">
        <h2 className="center creator">売り上げについて</h2>
        <div className="container orag">
          <span className="uragtxt">
            月売上の支払いは売上が発生した月の翌々月末に販売ユーザー指定口座に振込み致します。
            <br />
            手数料はポイント売上別からの変動制
            <br />
            手数料はポイント売上別の10%から13%の変動制になります。
          </span>
          <div className="md2">
            <img src={orag} alt="" />
          </div>
          <span className="uragtxt">
            ※売上金額が3万円を満たさない場合は基本的に支払いができません。予めご了承ください。
            <br />
            ※売上には販売コイン額より下記手数料を引いた金額が表示されます。
            <br />
            ・月売上50万円未満まで12％
            <br />
            ・月売上50万円以上100万円未満まで11％
            <br />
            ・月売上100万円以上から10%がさし引かれます。
          </span>
        </div>
      </section>

      <section id="4">
        <div className="pagelast">
          <span className="oragtxt">
            Funny Fansでクリエイター活動を充実させましょう
          </span>

          <a href="/contact">
            <img src={btn1} alt="登録はお問い合わせまで" />
          </a>
        </div>
      </section>
    </main>
  </div>
);

export default Landing;
