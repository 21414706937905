import React from "react";

const Home: React.FC = () => {
  return (
    <>
      <div className="wrap">
        <h2>販売ページ確認(WIP)</h2>

        <div className="information-box">
          <table>
            <tr>
              <th>
                <h3>ユーザーアカウント名</h3>
                <p>やまちゃん</p>
              </th>
              <th>
                <h3>ユーザーNo</h3>
                <p>XXXXX</p>
              </th>

              <th>
                <h3>ヘッダー画像</h3>
                <img className="icon" src="../asset/img/kari06.png" alt="" />
              </th>

              <th>
                <h3>ページアイコン画像</h3>
                <img className="icon" src="../asset/img/kari05.png" alt="" />
              </th>

              <th>
                <h3>ページ全体背景画像</h3>
                <img className="icon" src="../asset/img/kari06.png" alt="" />
              </th>

              <th>
                <div className="cp_ipselect cp_sl">
                  <select required>
                    <option value="" hidden>
                      未確認
                    </option>
                    <option value={1}>問題あり</option>
                    <option value={2}>差替指示済</option>
                    <option value={2}>承認</option>
                  </select>
                </div>
              </th>
            </tr>

            <tr>
              <th>
                <h3>ユーザーアカウント名</h3>
                <p>やまちゃん</p>
              </th>
              <th>
                <h3>ユーザーNo</h3>
                <p>XXXXX</p>
              </th>

              <th>
                <h3>ヘッダー画像</h3>
                <img className="icon" src="../asset/img/kari06.png" alt="" />
              </th>

              <th>
                <h3>ページアイコン画像</h3>
                <img className="icon" src="../asset/img/kari05.png" alt="" />
              </th>

              <th>
                <h3>ページ全体背景画像</h3>
                <img className="icon" src="../asset/img/kari06.png" alt="" />
              </th>

              <th>
                <div className="cp_ipselect cp_sl">
                  <select required>
                    <option value="" hidden>
                      未確認
                    </option>
                    <option value={1}>問題あり</option>
                    <option value={2}>差替指示済</option>
                    <option value={2}>承認</option>
                  </select>
                </div>
              </th>
            </tr>
          </table>

          <div className="btn message">
            <span>
              <input
                type="submit"
                className="confirm"
                // onclick="location.href='master-mosaic-sale.html'"
                value="更新"
              />
            </span>
          </div>
        </div>

        <h2>メッセージ送信</h2>
        <h3>ユーザー情報</h3>
        <input
          size={20}
          type="text"
          className="wide"
          style={{ width: "20%" }}
        />
        <div className="message-form">
          <textarea
            id="product-text"
            name="product-text"
            rows={5}
            cols={33}
          ></textarea>
        </div>
      </div>

      <div className="btn">
        <span>
          <input
            type="submit"
            className="back"
            // onclick="location.href='master-mosaic-list.html'"
            value="戻る"
          />
        </span>

        <span>
          <input
            type="submit"
            className="confirm"
            data-modal-trigger="modal-confirm"
            value="確認"
          />
        </span>
      </div>
    </>
  );
};

export default Home;
