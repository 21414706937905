import React from "react";
import { useCookies } from "react-cookie";
import Button from "../../parts/Button";
import classes from "./R18Verification.module.scss";

const Content: React.FC = () => {
  const [, setCookie] = useCookies(["r18-verification"]);
  const setR18Verify = () =>
    setCookie("r18-verification", "true", { path: "/" });
  return (
    <div className={classes.wrap}>
      <h2 className="pop">年齢認証</h2>
      <img className="pop" src="../asset/img/logo.png" alt="" />

      <img src="/favicon.png" alt="logo" className={classes.image} />

      <h2 className={classes.text}>あなたは18歳以上ですか？</h2>
      <p className="pop">
        ここから先は、アダルト商品を扱うアダルトサイトとなります。
        <br />
        18歳未満の方のアクセスは固くお断りします。
      </p>
      <div className={classes.buttons}>
        <Button
          className={classes.no}
          as="a"
          href="https://google.com"
          style={{ backgroundColor: "#b6bec1" }}
        >
          いいえ
        </Button>
        <Button onClick={setR18Verify} className={classes.yes}>
          はい
        </Button>
      </div>
    </div>
  );
};

export default Content;
