import React from "react";
import { Link } from "react-router-dom";
import ProductList from "./ProductList";
import Slider from "./Slider";
import styled from "styled-components";
const StyledLink=styled(Link)`text-decoration: none;`

const Button = styled.button
`margin: 5% auto 0 auto  ;`

interface Props {
  type?: "new" | "pickup" | "default";
}
const Home: React.FC<Props> = () => {
  // const {} = props;
  return (
    <>
      <Slider />
      <div id="wrapper">
        <h2>商品一覧</h2>

        <ProductList type="default" length={8} />

        <div className="add-button">
          <StyledLink to="/register">
          <Button
              className="add-button"
              // onclick="location.href='../member/register.html'"
            >
              新規登録はこちら
            </Button>
          </StyledLink>
        </div>
      </div>
    </>
  );
};

export default Home;
