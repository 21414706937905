import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useProducts from "../../../api/product/list";

const Home: React.FC = () => {
  // const notifications = [
  //   { date: "2021/08/01", content: "〇〇〇〇〇〇〇〇" },
  //   { date: "2021/08/01", content: "〇〇〇〇〇〇〇〇" },
  //   { date: "2021/08/01", content: "〇〇〇〇〇〇〇〇" },
  //   { date: "2021/08/01", content: "〇〇〇〇〇〇〇〇" },
  // ];
  // const messages = [
  //   { date: "2021/08/01", username: "ユーザー名", content: "〇〇〇〇〇〇〇〇" },
  //   { date: "2021/08/01", username: "ユーザー名", content: "〇〇〇〇〇〇〇〇" },
  //   { date: "2021/08/01", username: "ユーザー名", content: "〇〇〇〇〇〇〇〇" },
  //   { date: "2021/08/01", username: "ユーザー名", content: "〇〇〇〇〇〇〇〇" },
  // ];
  // const products = [
  //   {
  //     id: 1,
  //     name: "商品名",
  //     image: "/asset/img/kari05.png",
  //   },
  //   {
  //     id: 2,
  //     name: "商品名",
  //     image: "/asset/img/kari05.png",
  //   },
  //   {
  //     id: 3,
  //     name: "商品名",
  //     image: "/asset/img/kari05.png",
  //   },
  //   {
  //     id: 4,
  //     name: "商品名",
  //     image: "/asset/img/kari05.png",
  //   },
  // ];
  const products = useProducts({ params: { mine: true, limit: 999 } });
  useEffect(() => {
    console.log(products);
  }, [products]);
  return (
    <div className="wrap">
      <div className="main">
        {/*  <h2 style={{ display: "none" }}>新着お知らせ</h2>
        <table style={{ display: "none" }}>
          {notifications.map(({ date, content }) => (
            <tr>
              <td>{date}</td>
              <td>{content}</td>
            </tr>
          ))}
        </table>
        <div style={{ display: "none" }} className="more-button">
          <Link to={"#"} className="btn-flat-more">
            <i className="fa fa-caret-right"></i> お知らせ一覧
          </Link>
        </div>

        <h2 style={{ display: "none" }}>新着メッセージ</h2>

        <table style={{ display: "none" }}>
          {messages.map(({ date, username, content }) => (
            <tr>
              <td>{date}</td>
              <td>{username}</td>
              <td>{content}</td>
            </tr>
          ))}
        </table>
        <div style={{ display: "none" }} className="more-button">
          <Link to={"#"} className="btn-flat-more">
            <i className="fa fa-caret-right"></i> メッセージ一覧
          </Link>
        </div>*/}

        <h2 style={{ display: "none" }}>新着商品一覧</h2>

        <div className="item-wrap">
          {products?.data?.map((product, i) => (
            <section key={i} className="item-list">
              <Link className="item-link" to={`/creator/product/${product.id}`}>
                <figure className="item-figure">
                  <img src={product.thumbnail} alt="" />
                </figure>
                <h3 className="item-title">{product.name}</h3>
              </Link>
            </section>
          ))}
        </div>
        <div className="more-button">
          <Link to="#" className="btn-flat-more">
            <i className="fa fa-caret-right" /> 商品一覧
          </Link>
        </div>

        {/* <h2>ピックアップユーザー</h2>
        <div className="item-wrap">
          {products?.data?.map(({ id, name }) => (
            <section className="item-list">
              <Link className="item-link" to={`/creator/product/${id}`}>
                <figure className="item-figure">
                  <img src={""} alt="" />
                </figure>
                <h3 className="item-title">{name}</h3>
              </Link>
            </section>
          ))}
        </div>
        <div className="more-button">
          <Link to="#" className="btn-flat-more">
            <i className="fa fa-caret-right" /> 商品一覧
          </Link>
        </div>

        <h2>販売中商品</h2>
        <div className="item-wrap">
          {products?.data?.map(({ id, name }) => (
            <section className="item-list">
              <Link className="item-link" to={`/creator/product/${id}`}>
                <figure className="item-figure">
                  <img src={""} alt="" />
                </figure>
                <h3 className="item-title">{name}</h3>
              </Link>
            </section>
          ))}
        </div>
        <div className="more-button">
          <Link to="#" className="btn-flat-more">
            <i className="fa fa-caret-right" /> 商品一覧
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default Home;
