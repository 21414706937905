import React from "react";
import { Link } from "react-router-dom";

const Home: React.FC = () => {
  return (
    <div className="wrap">
      <h2>商品画像動画</h2>

      <div className="more-button">
        <Link to="/master/mosaic/product" className="btn-flat-member">
          <i className="fa fa-caret-right"></i> 商品画像動画確認
        </Link>
      </div>

      <div className="more-button">
        <Link to="/master/mosaic/productplan" className="btn-flat-member">
          <i className="fa fa-caret-right"></i> プラン商品画像動画確認
        </Link>
      </div>

      <h2>アカウント画像</h2>

      <div className="more-button">
        <Link to="/master/mosaic/user" className="btn-flat-member">
          <i className="fa fa-caret-right"></i> アカウント画像確認
        </Link>
      </div>

      <h2>販売ページ</h2>

      <div className="more-button">
        <Link to="/master/mosaic/sales" className="btn-flat-member">
          <i className="fa fa-caret-right"></i>販売ページ確認
        </Link>
      </div>

      <div className="btn">
        <span>
          <input
            type="submit"
            className="back"
            // onclick="location.href='master-top.html'"
            value="戻る"
          />
        </span>
      </div>
    </div>
  );
};

export default Home;
