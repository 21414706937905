import styles from "./Button.module.scss";
import clsx from "clsx";
import styled from "styled-components";

interface Props {
  loading?: boolean;
  color?: "primary" | "secondary" | "info" | "tertiary" |"blackBlue";
  disabled?: boolean;
  grow?: boolean;
  shrink?: boolean;
  flexBasis?: number | "auto" | string;
  flex?: boolean;
  gap?: number;
  round?: number;
}
const Button = styled("button").attrs<Props>((p) => ({
  className: clsx(
    p.className,
    styles.button,
    p.loading && styles.loading,
    p?.disabled && styles.disabled,
    p.color === "secondary" && styles.secondary,
    p.color === "tertiary" && styles.tertiary,
    p.color === "info" && styles.info,
    p.color === "blackBlue" && styles.blackBlue,
    p.grow && styles.grow,
    p.shrink && styles.shrink,
    p.flex && styles.flex
  ),
}))<Props>`
  ${(p) => p.flexBasis && `width:auto;flex-basis: ${p.flexBasis};`}
  ${(p) => p.gap && `gap: ${p.gap}px;`}
  ${(p) => p.round && `border-radius: ${p.round}px;`}
`;

export default Button;
