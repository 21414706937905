import { Link } from "react-router-dom";

const Footer: React.FC = () => (
  <footer>
    <div className="footer">
      <div className="footer-wrap">
        <div className="box">
          <h3>ファニーファンズ[Funny Fans]</h3>
          <p>
            ファニーファンズ[Funny
            Fans]は、イラストレーターやコスプレイヤーなど、創作活動を軸に活躍するクリエイターの応援やコニュニティー作りに最適な場所です。誰でも簡単に無料登録でき、クリエイターを応援することができます。
          </p>
        </div>
        <div className="box">
          <h3>ご利用について</h3>
          <ul>
          <li>
              <Link to="/landing/creator">クリエイターについて</Link>
            </li>
            <li>
              <Link to="/landing">一般会員について</Link>
            </li>
            <li>
              <Link to="/contact">お問い合わせ</Link>
            </li>
            <li>
              <Link to="/QA">よくある質問</Link>
            </li>
            <li>
              <Link to="/term">利用規約</Link>
            </li>
            <li>
              <Link to="/company">運営会社</Link>
            </li>
            <li>
              <Link to="/privacypolicy">プライバシーポリシー</Link>
            </li>
            <li>
              <Link to="/trade">特定商取引法開示</Link>
            </li>
            <li>
              <Link to="/login">ログイン</Link>
            </li>
          </ul>
        </div>

        
      </div>
      <p className="copyright">2022 &copy; Funny Fans</p>
      {/* </div> */}
    </div>
  </footer>
);

export default Footer;
