import React from "react";
import Input from "../../../parts/Input";
import Text from "../../../parts/Text";
import Button, { ButtonPrev } from "../../../parts/Button";
import Container from "../../../parts/Container";
import useAddPlan from "../../../api/plan/add";
import { useNavigate } from "react-router-dom";
const MultilineText = Input.withComponent("textarea");

const Width = "200px";

const Content: React.FC = () => {
  const [name, setName] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [thumbnail, setThumbnail] = React.useState<File | null>(null);
  const [price, setPrice] = React.useState<number>(0);

  const navigate = useNavigate();

  const addPlan = useAddPlan();

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      if (!thumbnail) return alert("サムネイルを追加してください");
      const result = await addPlan.execute({
        name,
        description,
        thumbnail,
        price,
      });
      if (result instanceof Error) {
        alert("エラーが発生しました");
        return;
      }
      navigate("/creator/plan");
    } catch (e) {
      alert("エラーが発生しました");
    }
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target?.files?.[0];
      if (!file) return;
      setThumbnail(file);
    }
  };
  const handleChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const value = e.target?.value;
      const numberValue = Number(value);
      if (isNaN(numberValue)) throw new Error("数値を入力してください");
      setPrice(numberValue);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Container width70L width95>
      <h2 className="page-title">プランの追加</h2>
      <Container card round>
        <Container row justifyCenter alignCenter>
          <Text flexBasis={Width}>プラン名</Text>
          <Input
            type="text"
            grow
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Container>
        <Container row justifyCenter alignCenter>
          <Text flexBasis={Width}>{"プランの説明\n具体的な会員特典など"}</Text>
          <MultilineText
            grow
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Container>
        <Container row justifyCenter alignCenter>
          <Text flexBasis={Width}>サムネイル画像</Text>
          <Input
            className="file-input"
            grow
            type="file"
            onChange={handleFileChange}
          />
        </Container>
        <Container row justifyCenter alignCenter>
          <Text flexBasis={Width}>月額</Text>
          <Input
            grow
            type="number"
            value={price.toString()}
            onChange={handleChangePrice}
          />
        </Container>
      </Container>
      <Container row justifyCenter>
        <ButtonPrev>戻る</ButtonPrev>
        <Button onClick={handleSubmit} loading={addPlan.loading}>
          確定
        </Button>
      </Container>
    </Container>
  );
};

export default Content;
