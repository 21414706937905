import styles from "./Input.module.scss";
import clsx from "clsx";
import styled from "styled-components";

interface Props {
  row?: boolean;
  fullWidth?: boolean;
  noPadding?: boolean;
  sideMargin?: boolean;
  grow?: boolean;
  overflowYscroll?: boolean;
  overflowXscroll?: boolean;
}
const Container = styled("input").attrs<Props>((p) => ({
  className: clsx(
    styles.input,
    p.fullWidth && styles.fullWidth,
    p.grow && styles.grow,
    p.className
  ),
}))<Props>``;

export default Container;
