import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSaveBank from "../../../api/bank/save";
import useBank from "../../../api/bank/get";
import Button from "../../../parts/Button";

const Content: React.FC = () => {
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [branch, setBranch] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const validate = (v: string) => {
    const regex = /[^0-9]/g;
    const result = v.replace(regex, "");
    const result2 = result.slice(0, 7);
    setNumber(result2);
  };
  const [holder, setHolder] = useState<string>("");

  const saveBank = useSaveBank();
  const bank = useBank();
  useEffect(() => {
    if (!bank.data) return;
    setName(bank.data.name);
    setBranch(bank.data.branch);
    setType(bank.data.type);
    setNumber(bank.data.number);
    setHolder(bank.data.holder);
  }, [bank.data]);

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement | HTMLInputElement>
  ) => {
    e.preventDefault();
    const r = await saveBank.execute({ name, branch, type, number, holder });
    if (r instanceof Error) return;
    navigate(-1);
  };

  // const category = [{ id: 1, name: "カテゴリ1" }];
  if (bank.loading) return <div>loading...</div>;

  return (
    <>
      <div className="wrap">
        <div className="main">
          <h2>入金口座編集</h2>
          {saveBank.error && <p>エラーが発生しました</p>}

          <div className="borderbox">
            <h3>銀行名編集</h3>
            <input
              size={20}
              type="text"
              className="wide"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="borderbox">
            <h3>支店名編集</h3>
            <input
              size={20}
              type="text"
              className="wide"
              value={branch}
              onChange={(e) => setBranch(e.target.value)}
            />
          </div>
          <div className="borderbox">
            <h3>預金種類編集</h3>
            <input
              size={20}
              type="text"
              className="wide"
              value={type}
              onChange={(e) => setType(e.target.value)}
            />
          </div>
          <div className="borderbox">
            <h3>口座番号編集</h3>
            <input
              size={20}
              type="text"
              className="wide"
              value={number}
              onChange={(e) => validate(e.target.value)}
            />
          </div>
          <div className="borderbox">
            <h3>口座名義編集</h3>
            <input
              size={20}
              type="text"
              className="wide"
              value={holder}
              onChange={(e) => setHolder(e.target.value)}
            />
          </div>
        </div>

        <div id="creator-side-menu"></div>
      </div>

      <div className="btn">
        <span>
          <input
            type="submit"
            className="back"
            // onclick="location.href='creator-earnings.html'"
            value="戻る"
          />
        </span>
        <Button onClick={handleSubmit} loading={saveBank.loading}>
          保存する
        </Button>
      </div>
    </>
  );
};

export default Content;
