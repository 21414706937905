import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import useAddContact from "../../api/inquiry/add";
import Container from "../../parts/Container";
import Button from "../../parts/Button";

const Content: React.FC = () => {
  const addContact = useAddContact();
  const formRef = useRef<HTMLFormElement>(null);
  const [, count] = useState<number>(0);
  const reload = () => count((prev) => prev + 1);

  const clear = () => {
    const el = formRef.current;
    if (el?.nameKanji) el.nameKanji.value = "";
    if (el?.nameKana) el.nameKana.value = "";
    if (el?.email) el.email.value = "";
    if (el?.content) el.content.value = "";
    if (el?.phone) el.phone.value = "";
    if (el?.business) el.business.value = "";
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const name = e.currentTarget?.nameKanji?.value;
    const furigana = e.currentTarget?.nameKana?.value;
    const email = e.currentTarget?.email?.value;
    const content = e.currentTarget?.content?.value;
    const phone = e.currentTarget?.phone?.value;
    const business = e.currentTarget?.business?.value;
    const bankname = e.currentTarget?.bankname?.value;
    const bankbranch = e.currentTarget?.bankbranch?.value;
    const banktype = e.currentTarget?.banktype?.value;
    const bankNumber = e.currentTarget?.bankNumber?.value;
    const bankMeigi = e.currentTarget?.bankMeigi?.value;

    const params = {
      name,
      email,
      content,
      phone,
      business,
      furigana,
      bankname,
      bankbranch,
      banktype,
      bankNumber,
      bankMeigi,
    };

    console.log(params);

    const result = await addContact.execute(params);
    if (result instanceof Error) {
      alert("エラーが発生しました");
      return;
    }

    clear();

    alert("送信しました");
  };

  return (
    <Container width70L width95>
      <div className="main">
        <h2 className="panel-title">お問い合わせ</h2>

        <div className="box_contact">
          <form onSubmit={handleSubmit} ref={formRef}>
            <ul className="formTable">
              <li>
                <p className="title">
                  <em>ご用件</em>
                </p>
                <div className="box_det">
                  <select
                    className="business"
                    name="business"
                    onChange={reload}
                  >
                    <option value="">選択してください</option>
                    <option value="ご質問・お問い合わせ">
                      ご質問・お問い合わせ
                    </option>
                    <option value="クリエイター登録について">
                      クリエイター登録について
                    </option>
                    <option value="退会について">退会について</option>
                    <option value="リンクについて">リンクについて</option>
                  </select>
                </div>
              </li>
              <li>
                <p className="title">
                  <em>
                    お名前<span>必須</span>
                  </em>
                </p>
                <div className="box_det">
                  <input
                    size={20}
                    type="text"
                    className="wide"
                    name="nameKanji"
                    required
                  />
                </div>
              </li>
              <li>
                <p className="title">
                  <em>ふりがな</em>
                </p>
                <div className="box_det">
                  <input
                    size={20}
                    type="text"
                    className="wide"
                    name="nameKana"
                    required
                  />
                </div>
              </li>
              <li>
                <p className="title">
                  <em>
                    電話番号（半角）<span>必須</span>
                  </em>
                </p>
                <div className="box_det">
                  <input
                    size={30}
                    type="tel"
                    pattern="\d{9,13}"
                    className="wide"
                    name="phone"
                    required
                  />
                  <p className="check">
                    ※ハイフン（-）はなしでご入力ください。
                  </p>
                </div>
              </li>
              <li>
                <p className="title">
                  <em>
                    Mail（半角）<span>必須</span>
                  </em>
                </p>
                <div className="box_det">
                  <input size={30} type="email" className="wide" name="email" />
                </div>
              </li>
              <li>
                <p className="title">
                  <em>お問い合わせ内容</em>
                </p>
                <div className="box_det">
                  <textarea
                    name="content"
                    cols={50}
                    rows={5}
                    className="contact"
                  />
                </div>
              </li>
              {formRef.current?.business?.value ===
                "クリエイター登録について" && (
                <>
                  <li>
                    <p className="title">
                      <em>銀行名</em>
                    </p>
                    <div className="box_det">
                      <input
                        size={30}
                        type="text"
                        className="wide"
                        name="bankname"
                      />
                    </div>
                  </li>
                  <li>
                    <p className="title">
                      <em>支店名</em>
                    </p>
                    <div className="box_det">
                      <input
                        size={30}
                        type="text"
                        className="wide"
                        name="bankbranch"
                      />
                    </div>
                  </li>
                  <li>
                    <p className="title">
                      <em>口座種類(普通・定期など)</em>
                    </p>
                    <div className="box_det">
                      <input
                        size={30}
                        type="text"
                        className="wide"
                        name="banktype"
                      />
                    </div>
                  </li>
                  <li>
                    <p className="title">
                      <em>口座番号</em>
                    </p>
                    <div className="box_det">
                      <input
                        size={30}
                        type="text"
                        className="wide"
                        name="bankNumber"
                      />
                    </div>
                  </li>
                  <li>
                    <p className="title">
                      <em>口座名義</em>
                    </p>
                    <div className="box_det">
                      <input
                        size={30}
                        type="text"
                        className="wide"
                        name="bankMeigi"
                      />
                    </div>
                  </li>
                </>
              )}
            </ul>
            <div className="box_check">
              <label>
                <input
                  type="checkbox"
                  name="privacy"
                  value={1}
                  aria-invalid="false"
                  className="agree"
                  required
                />
                <span className="check">
                  <Link to="/privacypolicy">プライバシーポリシー</Link>
                  に同意する
                </span>
              </label>
            </div>
            <p className="btn">
              <Button loading={addContact.loading}>送信</Button>
            </p>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default Content;
