import React from "react";
import { Link } from "react-router-dom";
import Button, { ButtonPrev } from "../../../parts/Button";
import Text from "../../../parts/Text";
import Img from "../../../parts/Img";
import Container from "../../../parts/Container";
import usePlans from "../../../api/plan/list";
import styled from "styled-components";
const LinkButton = Button.withComponent(Link);

const TabItem = styled(Container)<{ active: boolean }>`
  display: flex;
  flex: 1 1 50%;
  text-align: center;
  border-bottom-width: 5px;
  border-bottom-style: solid;
  border-bottom-color: ${({ active }) => (active ? "#70b4cc" : "#DDDDDD")};
  background-color: ${({ active }) => (active ? "rgb(247,253,255)" : "white")};
`;
const TabContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0px;
`;

const Content: React.FC = () => {
  const plans = usePlans({ params: { joined: true } });
  const [free, setFree] = React.useState(false);
  const handleFree = (free: boolean) => () => {
    setFree(free);
  };
  return (
    <Container width95 width70L>
      <TabContainer>
        <TabItem onClick={handleFree(false)} active={!free}>
          有料
        </TabItem>
        <TabItem onClick={handleFree(true)} active={free}>
          無料
        </TabItem>
      </TabContainer>

      <Container fullWidth>
        {plans.data
          ?.filter(
            (plan) => (free || plan.price > 0) && !(free && plan.price > 0)
          )
          ?.map((plan) => (
            <Container key={plan.id} card round>
              <Container row>
                <Img src={plan.thumbnail} square width={100} cover round />
                <Container>
                  <Text>プラン名</Text>
                  <Text>
                    {plan.name}(￥{plan.price}/月)
                  </Text>
                  <Link to={`/plan?userId=${plan.user.id}`}>プランを変更</Link>
                </Container>
              </Container>
              <Text>{plan.description}</Text>
              <LinkButton to={`/plan/${plan.id}`} color="primary">
                ファンクラブページへ
              </LinkButton>
            </Container>
          ))}
      </Container>
      <Container row justifyCenter>
        <ButtonPrev>戻る</ButtonPrev>
      </Container>
    </Container>
  );
};

export default Content;
