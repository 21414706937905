import React from "react";
// import { Link } from "react-router-dom";
import Container from "../../../parts/Container/Container";
import Text from "../../../parts/Text";
import usePurchase from "../../../api/purchase/list";
import useEarnings from "../../../api/earning/list";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../libs/auth";
import { ButtonPrev } from "../../../parts/Button";

interface EarningItemProps {
  name: string;
  count: string | number;
  price: string | number;
}
const EarningItem: React.FC<EarningItemProps> = (p) => {
  return (
    <Container row noPadding borderBottom>
      <Container grow shrink noPadding>
        {p.name}
      </Container>
      <Container basis={100} alignCenter justifyCenter noPadding>
        {p.count}
      </Container>
      <Container basis={100} alignCenter justifyCenter noPadding>
        {typeof p.price === "number" && "￥"}
        {p.price}
      </Container>
    </Container>
  );
};

const Content: React.FC = () => {
  const params = useParams<{ year: string; month: string }>();
  const year = parseInt(params?.year || "2023") ?? 2023;
  const month = parseInt(params?.month || "1") ?? 1;
  const purchase = usePurchase({ params: { year, month } });
  const earnings = useEarnings();
  const total =
    earnings?.data?.find((earning) => earning.date === `${year}/${month}`)
      ?.coins || 0;
  const auth = useAuth();

  return (
    <Container fullWidth maxWidth750>
      <Container card round>
        <Text bold large>
          {auth.user?.data?.profile?.nickname}の売上詳細
        </Text>
        <Container row>
          <Text bold large>
            {year}年{month}月 売上
          </Text>
          <Container grow />
          <Text bold>￥{total}</Text>
        </Container>
        <Text bold large>
          内訳
        </Text>
        <Container gap={0}>
          <EarningItem name={"商品名"} count={"数量"} price={"小計"} />

          {purchase?.data?.map((purchase) =>
            purchase.products.map((product) => {
              return (
                <EarningItem
                  name={product.name}
                  count={1}
                  price={product.price}
                />
              );
            })
          )}
        </Container>

        {/*     <div className="btn">
            <ButtonPrev className="earnings-btn" >戻る</ButtonPrev>
          </div>*/}
      </Container>
      <Container row fullWidth justifyCenter>
        <ButtonPrev />
      </Container>
    </Container>
  );
};

export default Content;
