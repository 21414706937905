import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../libs/auth";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { login } = useAuth();
  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const d = await login.execute({ email, password });
    if (d instanceof Error) return;
    if (!d?.isAdmin) return;
    navigate("/master?reload=true");
  };
  return (
    <div id="wrapper">
      <div className="login-page">
        <div className="form">
          <p className="form-text">管理画面ログイン</p>
          {(login.error ||
            (login.called && !login.loading && !login.data?.isAdmin)) && (
            <div id="login-error" className="error-message">
              ログインに失敗しました
            </div>
          )}
          <form
            className="login-form"
            onSubmit={(e) => e.preventDefault()}
            action="/api/user/login"
            method="POST"
          >
            <input hidden value="admin" name="type" />
            <input
              name="email"
              type="text"
              placeholder="メールアドレス"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              name="password"
              type="password"
              placeholder="パスワード"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <button type="submit" onClick={handleSubmit}>
              ログイン
            </button>

            <p className="message">
              パスワードを忘れた方は <a href="../password_reset.html">こちら</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Home;
