import React from "react";
import Container from "../../parts/Container";
import classes from "./QA.module.scss";
import { Link } from "react-router-dom";

const Content: React.FC = () => {
  return (
    <Container fullWidth>
      <div id="wrapper">
        <h1 className={classes.title}>よくある質問</h1>

        <div className="information-box information-border ">
          <h2 className="article-heading common-title">
            どんなサービスですか？
          </h2>
          <p>このサービスは、クリエイターとファンをつなぐサービスです。</p>
        </div>
        <div className="information-box information-border ">
          <h2 className="article-heading common-title">
            ファニーファンズに新規登録するためにはどうしたらいいですか？
          </h2>
          <p>
            ファニーファンズに新規登録するためには、{" "}
            <Link to="/register" target="_blank">
              新規登録ページ
            </Link>
            にアクセスしてユーザー登録をしてください。
          </p>
        </div>
        <div className="information-box information-border ">
          <h2 className="article-heading common-title">
            ファニーファンズを利用するのは無料ですか？無料プランへの入会は料金が発生しますか？
          </h2>
          <p>
            ファニーファンズの無料会員登録、および無料プランをご利用いただくだけなら無料でご利用いただく事ができます。
            <br />
            ファニーファンズでご利用料金が発生するのは次のような場合です（2023年2月現在）
            <br /> <br />{" "}
          </p>
          <p>
            {" "}
            <strong>・有料プランに入会する場合</strong>
            <br />
            各ファンクラブには有料プランが設定されている場合があり、
            <br />
            有料プランに入会する場合には、該当プラン分の料金のお支払いが発生します。
            <br />
            有料プランに入会すると、入会後に閲覧期限が設定されている限定コンテンツを閲覧することができます。
            <br />
            ※ファンクラブ毎に公開内容は異なります。
            <br />
            公開内容につきましては、ファンクラブのクリエイター様にお問い合わせください。
            <br />
            また、クレジットカードで入会した場合、入会日に関わらず翌月1日に翌月分のご利用料金が発生いたします。
            <br />
            バックナンバーが販売されていた場合、過去の限定コンテンツを購入することもできます。
            <br />
            <br />
            <strong> ・商品を購入する場合</strong>
            <br />
            有料プランとは別に、商品販売を行っているファンクラブがあります。
            <br />
            個別に販売されている商品等を購入する場合には、料金が発生します。
            <br />
          </p>
        </div>
        <div className="information-box information-border ">
          <h2 className="article-heading common-title">
            クリエイターと一般会員は何が違いますか？
          </h2>
          <p>
            クリエイターとは自身のファンクラブを開設できるサービスが利用できます。
            <br />
            一般会員のサービスはどちらも共通して利用できます。
          </p>
        </div>
        <div className="information-box information-border ">
          <h2 className="article-heading common-title">
            バックナンバーとはなんですか？
          </h2>
          <p>
            過去の限定コンテンツを月ごとにまとめて購入することができます。<br />
            バックナンバーを購入することで、あとから加入したファンの方でも、<br />入会期限が切れた限定コンテンツを閲覧できるようになります。
          </p>
        </div>
        <div className="information-box information-border ">
          <h2 className="article-heading common-title">
            ファニーファンズを退会したい
          </h2>
          <p>
            退会するには、ログインした状態でのお手続きが必要です。
            <br />
            一度ファニーファンズを退会すると、再度アカウントをご登録いただいても、これまでに投稿したコンテンツや、ファンクラブの会員状態を復元することはできなくなります。
          </p>
          <br />{" "}
          <p>
            【クリエイターの方】
            <br />
            まず、「閉鎖申請ページ」からファンクラブの閉鎖申請をお願いいたします。
            閉鎖手続きが完了しましたら、「退会ページ」から退会してください。
            <br />
            <br />
            【ファンの方】 <br />
            「退会ページ」から退会してください。{" "}
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Content;
