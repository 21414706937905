import Home from "../components/Home/HomeProductList";
import Layout from "../themplate/Layout";

const HomePage: React.FC = () => (
  <Layout>
    <Home type="default" />
  </Layout>
);

export default HomePage;
