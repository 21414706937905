import styles from "./Border.module.scss";
import clsx from "clsx";
import styled from "styled-components";

interface Props {
  fullWidth?: boolean;
  marginX?: number;
}
const Container = styled("hr").attrs<Props>((p) => ({
  className: clsx(styles.root, p.fullWidth && styles.fullWidth, p.className),
}))<Props>`
  ${(p) =>
    p.marginX && `margin-left:${p.marginX}px;margin-right:${p.marginX}px;`}
`;

export default Container;
