import React from "react";
import { Link } from "react-router-dom";
import styles from "./Commingsoon.module.scss";

const Content: React.FC = () => {
  return (
    <div className="wrap">
      <img src="/asset/img/coming-soon.png" alt="" className={styles.image} />
      <Link to={"/"}>ホームへ</Link>
    </div>
  );
};

export default Content;
