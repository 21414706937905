import React from "react";
import { Link } from "react-router-dom";
import useGetUser from "../../../api/user/get";
import Img from "../../../parts/Img";
import Container from "parts/Container/Container";

const Content: React.FC = () => {
  const user = useGetUser({ pathParams: { id: "me" } });
  return (
    <Container width70L width95>
      <div className="main">
        <h2 className="page-title">ショップ情報</h2>

        <div className="information-wrap">
          <div className="information-box information-border account-icon information-contents">
            <h3>ショップ名</h3>
            <p>{user.data?.shopProfile?.name}</p>
          </div>
          <div className="information-box information-border account-icon information-contents">
            <h3>ショップアイコン</h3>
            {user.data?.shopProfile?.icon ? (
              <Img
                src={user.data?.shopProfile?.icon}
                alt=""
                round
                square
                cover
                width={100}
              />
            ) : (
              <p>アイコンが設定されていません</p>
            )}
          </div>
          <div className="information-box information-border account-icon information-contents">
            <h3>紹介文</h3>
            <p>{user.data?.shopProfile?.introduction}</p>
          </div>
        </div>
      </div>
      <div className="btn">
        <Link to="/">
          <input type="submit" className="back" value="戻る" />
        </Link>
        <Link to="/creator/shop/edit">
          <input type="submit" value="ショップ管理・編集" />
        </Link>
      </div>
    </Container>
  );
};

export default Content;
