import React from "react";
import { useSearchParams, Link } from "react-router-dom";
import Button, { ButtonPrev } from "../../../parts/Button";
import Text from "../../../parts/Text";
import Img from "../../../parts/Img";
import Container from "../../../parts/Container";
import usePlans from "../../../api/plan/list";
import styled from "styled-components";
import User from "../../../parts/User";
import UserLinks from "../../../parts/UserLinks";
import useUser from "../../../api/user/get";
import ShareButton from "../../../parts/ShareButton";

const TextTwo = styled.p`
  font-size: 14px;
  padding: 5px;
  line-height: 1.4em;
`;

const PlanImage = styled(Img)`
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 100px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
`;
const PlanTexts = styled(Container)`
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  width: auto;
  height: auto;
`;

const LinkButton = styled(Button.withComponent(Link))`
  height: auto;
  white-space: pre-wrap;
  font-size: 12px;
`;
const LeaveText = styled(Text.withComponent(Link))`
  width: 100%;
  text-align: center;
  font-size: 12px;
`;
const BackNumber = styled(Text.withComponent(Link))``;
const PlanContainer = styled(Container)<{ active?: boolean }>`
  background-color: ${(p) => (p.active ? "rgb(230, 250, 255)" : "white")};
  border-radius: 10px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
`;

const Container2 = styled(Container)`
  flex-direction: row;
  width: 100%;
  // gap: 0;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Left = styled(Container)`
  width: 70%;
  flex-grow: 7;
  flex-shrink: 7;
  flex-basis: 0;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Right = styled(Container)`
  width: 30%;
  flex-grow: 3;
  flex-shrink: 3;
  flex-basis: 0;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Content: React.FC = () => {
  const [q] = useSearchParams();
  const id = q.get("userId");
  if (!id) throw new Error("id is required");
  const userId = Number(id);
  const user = useUser({
    pathParams: {
      id: `${userId}`,
    },
  });
  const plans = usePlans({ params: { userId } });
  return (
    <Container width70L width95>
      <User user={user.data} />
      <UserLinks userId={userId} />
      <Container2>
        <Left>
          {" "}
          <ShareButton>共有する</ShareButton>
          {plans.data?.map((plan) => (
            <PlanContainer key={plan.id} active={!!plan.joined}>
              <PlanImage src={plan.thumbnail} />
              <PlanTexts>
                <Text>{plan.name}</Text>
                <Text>￥{plan.price}</Text>
                <BackNumber to={`/plan/${plan.id}`}>
                  バックナンバーを見る
                </BackNumber>
              </PlanTexts>
              <Text>{plan.description}</Text>
              <LinkButton
                to={plan.joined ? "#" : `/plan/${plan.id}`}
                disabled={plan.joined}
                color="tertiary"
              >
                {plan.joined ? (
                  <>
                    加入中{"\n"}
                    {plan.price}円/月
                  </>
                ) : (
                  "プラン加入"
                )}
              </LinkButton>
              {plan.joined && (
                <LeaveText to={`/plan/${plan.id}/leave`}>
                  プランの退会
                </LeaveText>
              )}
            </PlanContainer>
          ))}
        </Left>
        <Right>
          <Container card round>
            <Text center bold large>
              入会・退会に関するご注意
            </Text>
            <Text blue bold>
              プランに入会する場合
            </Text>
            <TextTwo>
              ■ 限定コンテンツをすぐに楽しむことができます。
              ※入会期限日を過ぎたコンテンツは閲覧できません。
            </TextTwo>
            <TextTwo>
              ■ 月の途中で入会した場合でも1ヶ月分の料金が発生します。
              当月分は日割り計算になりません。
            </TextTwo>
            <Text blue bold>
              プランをアップグレードする場合
            </Text>
            <TextTwo>
              ■ アップグレード後のプランの限定コンテンツをすぐに
              楽しむことができます。※入会期限日を過ぎたコンテンツは
              閲覧できません。
            </TextTwo>
            <TextTwo>
              ■ 上位のプランに変更した時点で、
              加入中のプランの金額との差額を登録のクレジットカード
              から引き落とさせていただきます。
            </TextTwo>
            <TextTwo>
              ■ アップグレード後も現在加入中のプランは
              引き続き閲覧することができます。
            </TextTwo>
            <Text blue bold>
              プランをダウングレードする場合
            </Text>
            <TextTwo>
              ■ ダウングレード前は閲覧が可能だった
              限定コンテンツを含め、ダウングレード後のプランより
              上位のプランはダウングレードが完了した段階で閲覧が
              できなくなります。ダウングレード後のプラン以下の
              プランは引き続き閲覧することができます。
            </TextTwo>
            <TextTwo>
              ■ ダウングレードした場合は、
              加入期間がリセットされますのでご注意ください。
              入会期限日を過ぎたコンテンツは閲覧できなくなります。
            </TextTwo>
            <Text blue bold>
              プランから退会する場合
            </Text>
            <TextTwo>
              ■ 退会した時点で、限定コンテンツの閲覧権を喪失します。
            </TextTwo>
            <TextTwo>
              ■ 再度入会した場合においても、
              加入期間がリセットされますのでご注意ください。入会期限日を過ぎたコンテンツは閲覧できなくなります。
            </TextTwo>
            <TextTwo>
              ■ 月の途中で退会した場合でも1ヶ月分の料金が発生します。
              当月分は日割り計算になりません。
            </TextTwo>
          </Container>
        </Right>
      </Container2>
      <Container row justifyCenter>
        <ButtonPrev>戻る</ButtonPrev>
      </Container>
    </Container>
  );
};

export default Content;
