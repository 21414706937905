import useApi from "../useApi";

interface DataType {
  token: string;
  isCreator: boolean;
  isAdmin: boolean;
  approved: boolean;
}
interface Params {}
// export default useApi<>();

const path = "/user/login";
const method = "post";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
