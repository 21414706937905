import useNotify from "../../../api/notify/get";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Container from "../../../parts/Container";
import Text from "../../../parts/Text";
import { ButtonPrev } from "parts/Button";
import { useCookies } from "react-cookie";
import { useMemo } from "react";
import styled from "styled-components";

const Content: React.FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const notify = useNotify({ pathParams: { id } });

  const [cookie, setCookie] = useCookies(["notify"]);

  console.log(cookie.notify);

  const notifyCookie = useMemo(() => {
    return (cookie.notify as number[]) ?? [];
  }, [cookie.notify]);

  
  useEffect(() => {
    const newCookie = [...notifyCookie, Number(id)];
    setCookie("notify", JSON.stringify(newCookie));
  }, [id, notifyCookie, setCookie]);

  const Notise2 = styled.div`
  margin:100px;
  padding:100px;
  border:1px solid #ccc;
  `;

  const Title = styled(Text)`
  font-size:30px;
  margin-bottom:15px;
  `;

  const Message = styled(Text)`
  font-size:15px;
  `;

  return (
    <Container fullWidth>
      <Notise2 >
        <Title large>{notify.data?.title}</Title>
        <Message>{notify.data?.message}</Message>
      </Notise2>
      <Container row justifyCenter>
        <ButtonPrev />
      </Container>
    </Container>
  );
};

export default Content;
