import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import UserType from "types/UserType";
import useAddUser from "../../../api/user/add";
import { AxiosError } from "axios";

const Home: React.FC = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const userType = (searchParams.get("type") as UserType) || "member";

  const [confirm, setConfirm] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [icon, setIcon] = useState<File | null>(null);

  const addUser = useAddUser();

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (password !== passwordConfirm)
      return alert("パスワードが一致しません。");
    if (!icon) return alert("アイコンを選択してください。");

    if (!confirm) {
      setConfirm(true);
      return;
    }

    const result = await addUser.execute({
      email,
      password,
      nickname: username,
      role: userType,
      icon,
    });
    if (result instanceof Error) {
      if (result instanceof AxiosError && result.response?.status === 409)
        return alert("既に登録されているメールアドレスです。");
      return alert("エラーが発生しました。");
    }
    navigate(-1);
  };
  const handlePrev = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (confirm) setConfirm(false);
    else navigate(-1);
  };
  const handleAddIcon = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.target.files) return console.log("filenotfound");
    const icon = e.target.files[0];
    setIcon(icon);
  };

  return (
    <div id="wrapper">
      <div className="wrap">
        <h2>
          {userType === "master"
            ? "管理ユーザー追加"
            : userType === "creator"
            ? "クリエーターユーザー追加"
            : userType === "member"
            ? "ユーザー追加"
            : ""}
        </h2>
        <form
          className="information-box user-edit-form"
          id="user-edit"
          onSubmit={(e) => e.preventDefault()}
        >
          <div id="content">
            {confirm ? (
              <div className="confirm">
                <div className="information-box">
                  <h3>アカウント名</h3>
                  <p>{username}</p>
                </div>
                <div className="information-box">
                  <h3>アカウントアイコンアップロード</h3>
                  <img
                    className="icon"
                    src={icon ? window.URL.createObjectURL(icon) : ""}
                    alt="error"
                    style={{ height: "100px", width: "100px" }}
                  />
                </div>
                <div className="information-box">
                  <h3>メールアドレス</h3>
                  <p>{email}</p>
                </div>
                <div className="information-box">
                  <h3>パスワード</h3>
                  <p>********</p>
                </div>
              </div>
            ) : (
              <div className="edit">
                <div className="information-box">
                  <h3>管理アカウントNo</h3>
                  <p />
                </div>
                <div className="information-box">
                  <h3>アカウント名</h3>
                  <input
                    size={20}
                    type="text"
                    className="wide"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="information-box">
                  <h3>アカウントアイコンアップロード</h3>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleAddIcon}
                  />
                  {icon && (
                    <img
                      src={window.URL.createObjectURL(icon)}
                      alt="error"
                      style={{ height: "100px", width: "100px" }}
                    />
                  )}
                </div>
                <div className="information-box">
                  <h3>メールアドレス</h3>
                  <input
                    size={20}
                    type="text"
                    className="wide"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="information-box">
                  <h3>パスワード</h3>
                  <input
                    size={20}
                    type="text"
                    className="wide"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="information-box">
                  <h3>パスワード確認</h3>
                  <input
                    size={20}
                    type="text"
                    className="wide"
                    name="password2"
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="btn">
            <span>
              <input type="hidden" name="backstate" />
              <button type="submit" className="back" onClick={handlePrev}>
                戻る
              </button>
            </span>

            <span>
              <button type="submit" name="submit" onClick={handleSubmit}>
                確認
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Home;
