import { useApiFormData } from "../useApi";
import Product from "../../types/Product";

type DataType = Product;
interface Params {
  id: number;
  name?: string;
  // price: number;
  description?: string;
  thumbnail?: File;
}

const path = "/plan";
const method = "put";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApiFormData<DataType, Params>({
  path,
  method,
  eagerLoad,
});
