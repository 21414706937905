import Content from "../components/PaymentDone";
import Layout from "../themplate/Layout";

const Page: React.FC = () => (
  <Layout type="member">
    <Content />
  </Layout>
);

export default Page;
