import useApi from "../useApi";
import Product from "../../types/Product";

type DataType = Product;
interface Params {
  id: number;
  isActive?: boolean;
  isRejected?: boolean;
}

const path = "/product";
const method = "put";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
