import { useApiFormData } from "../useApi";
import Earning from "../../types/Earning";

type DataType = Earning[];
interface Params {}

const path = "/earning";
const method = "get";
const eagerLoad = true;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApiFormData<DataType, Params>({
  path,
  method,
  eagerLoad,
});
