import useApi from "../useApi";
import Bank from "../../types/Bank";

type DataType = Bank;
interface Params {}

const path = "/bank";
const method = "get";
const eagerLoad = true;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
