import { useApiFormData } from "../useApi";

type DataType = {
  title: string;
  message: string;
  to: "all" | number;
  parent?: number;
};

interface Params {}

const path = "/message";
const method = "post";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApiFormData<DataType, Params>({
  path,
  method,
  eagerLoad,
});
