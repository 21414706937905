import React, { useState } from "react";
import Layout from "../../themplate/Layout";
import styled from "styled-components";
import Button from "../../parts/Button";
import Input from "../../parts/Input";
import useUpdateUesr from "../../api/user/update";
import usePayments from "../../api/payment/list";
import { Link } from "react-router-dom";
import moment from "moment";

const LinkedButton = Button.withComponent(Link);

const Header = styled.div``;
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  @media (min-width: 768px) {
    width: 70%;
  }
  gap: 20px;
  padding: 20px;
`;
const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
`;
const PaymentItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  padding: 10px;
`;
const ID = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 80px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
`;
const UserId = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 80px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
`;

const UserName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 100px;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Price = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
`;
const Point = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
`;
const PurchaseDate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
`;

const IsPaid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
`;

const Page: React.FC = () => {
  const payments = usePayments();

  return (
    <Layout type="master">
      <Container>
        <Header>ポイント管理</Header>
        <PaymentContainer>
          <PaymentItem>
            <ID>ID</ID>
            <UserId>ユーザーID</UserId>
            <UserName>ユーザー名</UserName>
            <Price>価格</Price>
            <Point>ポイント</Point>
            <IsPaid>購入状況</IsPaid>
            <PurchaseDate>カート作成日時</PurchaseDate>
          </PaymentItem>
          {payments?.data?.map((e) => (
            <PaymentItem>
              <ID>{e?.id}</ID>
              <UserId>{e?.user?.id}</UserId>
              <UserName>{e?.user?.profile?.nickname}</UserName>
              <Price>{e?.price}円</Price>
              <Point>{e?.amount}C</Point>
              <IsPaid>{e.isPaid ? "購入済み" : "未購入"}</IsPaid>
              <PurchaseDate>
                {moment(e?.createdAt).format("YYYY/MM/DD HH:mm:ss")}
              </PurchaseDate>
            </PaymentItem>
          ))}
        </PaymentContainer>
      </Container>
    </Layout>
  );
};

export default Page;
