import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useGetUser from "../../../api/user/get";
import useUpdateShopprofile from "../../../api/shop/update";
import Modal, {
  ModalTitle,
  ModalText,
  ModalButton,
} from "../../../parts/Modal";
import { ButtonPrev } from "../../../parts/Button";
import styled, { css } from "styled-components";

const ShopIcon = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 100px;
`;

const Item = styled.div`
  display: flex;
  border-bottom: dotted 1px #d3d3d3;
  padding: 2% 0;
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 5px;
  }
`;
const Key = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  flex-basis: 100px;
  flex-shrink: 0;
  flex-grow: 0;
  @media (max-width: 768px) {
    width: 100%;
    flex-basis: auto;
  }
`;
const Value = styled.div`
  flex-basis: auto;
  flex-shrink: 1;
  flex-grow: 1;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const inputCss = css`
  width: 100% !important;
  max-width: 100% !important;
  box-sizing: border-box;
  border: 2px solid skyblue !important;
  border-radius: 5px;
  padding: 10px;
`;
const Textarea = styled.textarea`
  ${inputCss}
  height: 100px;
`;
const Input = styled.input`
  ${inputCss}
  height: 40px;
`;

const CreatorAccountEdit: React.FC = () => {
  const navigate = useNavigate();
  const user = useGetUser({ pathParams: { id: "me" } });
  const updateShopprofile = useUpdateShopprofile();
  const [modal, setModal] = React.useState<boolean>(false);
  const closeModal = () => setModal(false);
  const [icon, setIcon] = React.useState<File | undefined>(undefined);
  const [introduction, setIntroduction] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const openModal = () => {
    if (!icon && !introduction && !name) {
      alert("アイコンか紹介文、名前を入力してください。");
      return;
    }
    setModal(true);
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement | HTMLInputElement>
  ) => {
    e.preventDefault();
    const result = await updateShopprofile.execute({
      ...(icon && { icon }),
      ...(introduction && { introduction }),
      ...(name && { name }),
    });
    if (result instanceof Error) return;
    navigate(`/creator/shop?reload=true`);
  };

  const onChangeIcon = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setIcon(file);
  };

  useEffect(() => {
    if (user.loading) return;
    if (!user.data) return;

    setName(user.data?.shopProfile?.name || "");
    setIntroduction(user.data?.shopProfile?.introduction || "");
  }, [user]);
  return (
    <>
      <div className="wrap">
        <div className="main">
          <h2 className="page-title">ショップ管理</h2>
          {/* 以下のコードがないと、自動補完が走ってしまう */}
          <input
            type="text"
            name="dammy_id"
            style={{
              height: 0,
              padding: 0,
              border: "none",
              outline: "none",
            }}
          />
          <input
            type="password"
            name="dammy_pass"
            style={{
              height: 0,
              padding: 0,
              border: "none",
              outline: "none",
            }}
          />

          <div className="information-wrap">
            <Item>
              <Key>ショップ名</Key>
              <Value>
                <Input value={name} onChange={(e) => setName(e.target.value)} />
              </Value>
            </Item>
            <Item>
              <Key>アイコン</Key>
              <Value as="label">
                <ShopIcon
                  src={
                    icon
                      ? URL.createObjectURL(icon)
                      : user.data?.shopProfile?.icon
                  }
                  alt=""
                />
                <input
                  type="file"
                  accept="image/*"
                  onChange={onChangeIcon}
                  hidden
                />
              </Value>
            </Item>
            <Item>
              <Key>ショップ紹介</Key>
              <Value>
                <Textarea
                  value={introduction}
                  onChange={(e) => setIntroduction(e.target.value)}
                />
              </Value>
            </Item>
          </div>
        </div>
        <div className="btn">
          <ButtonPrev>戻る</ButtonPrev>
          <span>
            <input type="submit" onClick={openModal} value="変更" />
          </span>
        </div>
      </div>
      <Modal open={modal} onClose={closeModal}>
        <ModalTitle>以下を変更します</ModalTitle>
        {icon && (
          <ModalText>
            アイコン:
            <ShopIcon src={URL.createObjectURL(icon)} alt="" />
          </ModalText>
        )}
        {introduction && <ModalText>自己紹介: {introduction}</ModalText>}
        {name && <ModalText>ショップ名: {name}</ModalText>}
        <ModalButton>
          <span>
            <input
              type="submit"
              className="back"
              onClick={closeModal}
              value="戻る"
            />
          </span>
          <span>
            <input type="submit" onClick={handleSubmit} value="変更" />
          </span>
        </ModalButton>
      </Modal>
    </>
  );
};

export default CreatorAccountEdit;
