import React from "react";
import { Link } from "react-router-dom";
import Container from "../../../parts/Container/Container";
import Button from "../../../parts/Button";
import useEarning from "../../../api/earning/list";
import useGenrateEarning from "../../../api/earning/batch";
import Earning from "../../../types/Earning";
import moment from "moment";

const LinkedButton = Button.withComponent(Link);

interface EarningItemProps {
  userId: number | string;
  username: string;
  totalEarnings: number | string;
  latestEarnings: number | string;
  lastMonthEarnings: number | string;
  averageEarnings: number | string;
  noButton?: boolean;
}
const EarningItem: React.FC<EarningItemProps> = (p) => {
  return (
    <Container row alignCenter>
      <Container noPadding basis={80} className="master-list">
        {p?.userId}
      </Container>
      <Container noPadding basis={100} className="master-list">
        {p?.username}
      </Container>
      <Container noPadding basis={100} className="master-list">
        ￥{p.totalEarnings.toLocaleString()}
      </Container>
      <Container noPadding basis={100} className="master-list">
        ￥{p.latestEarnings.toLocaleString()}
      </Container>
      <Container noPadding basis={100} className="master-list">
        ￥{p.lastMonthEarnings.toLocaleString()}
      </Container>
      <Container noPadding basis={100} className="master-list">
        ￥{p.averageEarnings.toLocaleString()}
      </Container>
      {!p?.noButton && (
        <LinkedButton color="blackBlue" to={`/master/earning/${p.userId}`}>
          詳細
        </LinkedButton>
      )}
    </Container>
  );
};

const Content: React.FC = () => {
  const earning = useEarning();
  const generateEarning = useGenrateEarning();

  interface E {
    userId: number;
    username: string;
    totalEarnings: number;
    latestEarnings: number;
    lastMonthEarnings: number;
    averageEarnings: number;
    count: number;
  }

  const executeBatch = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    await generateEarning.execute({
      year: moment().year(),
      month: moment().month(),
    });
    await generateEarning.execute({
      year: moment().year(),
      month: moment().month() + 1,
    });
    await earning.execute();
  };

  return (
    <Container fullWidth maxWidth750>
      <Container>
        <h2>クリエイター売上管理</h2>

        <Container row alignCenter>
          <Container noPadding basis={80} className="master-list  title">
            ユーザーID
          </Container>
          <Container noPadding basis={100} className="master-list  title">
            ユーザー名
          </Container>
          <Container noPadding basis={100} className="master-list  title">
            累計売上
          </Container>
          <Container noPadding basis={100} className="master-list  title">
            最新月売上
          </Container>
          <Container noPadding basis={100} className="master-list  title">
            先月売上
          </Container>
          <Container noPadding basis={100} className="master-list  title">
            月平均売上
          </Container>
        </Container>
        {earning?.data
          ?.reduce((a: E[], c: Earning) => {
            const userId = c?.user?.id;
            const username = c.user?.profile?.nickname || "";
            const lastMonthEarnings = c.coins;
            const foundIndex = a.findIndex((a) => a.userId === userId);
            if (foundIndex === -1) {
              const newE: E = {
                userId,
                username,
                lastMonthEarnings: 0,
                totalEarnings: lastMonthEarnings,
                latestEarnings: lastMonthEarnings,
                averageEarnings: lastMonthEarnings,
                count: 1,
              };
              return [...a, newE] as E[];
            }
            a[foundIndex].totalEarnings += lastMonthEarnings;
            a[foundIndex].lastMonthEarnings = a[foundIndex].latestEarnings;
            a[foundIndex].lastMonthEarnings = lastMonthEarnings;
            a[foundIndex].count += 1;
            a[foundIndex].averageEarnings =
              a[foundIndex].totalEarnings / a[foundIndex].count;
            return a;
          }, [])
          ?.map((e) => (
            <EarningItem {...e} />
          ))}
      </Container>
      <div className="btn">
        <Link to="/master">
          <input type="submit" className="back" value="戻る" />
        </Link>
        <Button onClick={executeBatch}>バッチ処理</Button>
      </div>
    </Container>
  );
};

export default Content;
