import { useApiFormData as useApi } from "../useApi";

type DataType = {
  id: number;
  icon?: File;
  introduction?: string;
};
interface Params {}

const path = "/shop";
const method = "put";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
