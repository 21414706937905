import React from "react";
import { Link } from "react-router-dom";

const Content: React.FC = () => {
  return (
    <div className="wrap">
      <h2>未承認会員一覧</h2>

      <div className="more-button">
        <Link to="/master/user-unapproved" className="btn-flat-member">
          <i className="fa fa-caret-right"></i> 未承認会員一覧
        </Link>
      </div>

      <h2>会員認証承認状態</h2>

      <div className="more-button">
        <Link to="/master/user-approval" className="btn-flat-member">
          <i className="fa fa-caret-right"></i> 会員認証承認状態
        </Link>
      </div>

      <h2>会員一覧</h2>

      <div className="more-button">
        <Link to="/master/user?type=member" className="btn-flat-member">
          <i className="fa fa-caret-right"></i> 会員一覧
        </Link>
      </div>

      <div className="btn">
        <Link to={"/"}>
          <input
            type="submit"
            className="back"
            // onclick="location.href='master-top.html'"
            value="戻る"
          />
        </Link>
      </div>
    </div>
  );
};

export default Content;
