import { useApiFormData } from "../useApi";

interface DataType {
  token: string;
  isCreator: boolean;
  isAdmin: boolean;
  approved: boolean;
}
interface Params {
  email: string;
  password: string;
  nickname: string;
  name_kanji: string;
  name_kana: string;
  sex: string;
  birthday: string;
  zip: string;
  state: string;
  address: string;
  call: string;
  // file: File;
}
// export default useApi<>();

const path = "/user/signup";
const method = "post";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApiFormData<DataType, Params>({
  path,
  method,
  eagerLoad,
});
