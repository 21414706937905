import { useApiFormData } from "../useApi";

type Params = {
  planId: number;
  year: number;
  month: number;
};

type DataType = {};

const path = "/plan/backnumber";
const method = "post";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApiFormData<DataType, Params>({
  path,
  method,
  eagerLoad,
});
