import styles from "./Text.module.scss";
import clsx from "clsx";
import styled from "styled-components";

interface Props {
  fullWidth?: boolean;
  bold?: boolean;
  center?: boolean;
  flexBasis?: number | "auto" | string;
  grow?: boolean;
  shrink?: boolean;
  white?: boolean;
  gray?: boolean;
  blue?: boolean;
  red?: boolean;
  pink?: boolean;
  small?: boolean;
  large?: boolean;
  inline?: boolean;
  paddingX?: number;
  background?: "light-blue";
  height?: number;
  flex?: boolean;
  justifyCenter?: boolean;
  alignCenter?: boolean;
  newLine?: boolean;
}
const Text = styled("p").attrs<Props>((p) => ({
  className: clsx(
    styles.text,
    p.small && styles.small,
    p.large && styles.large,
    p.fullWidth && styles.fullWidth,
    p.bold && styles.bold,
    p.center && styles.center,
    p.grow && styles.grow,
    p.white && styles.white,
    p.blue && styles.blue,
    p.gray && styles.gray,
    p.red && styles.red,
    p.pink && styles.pink,
    p.shrink && styles.shrink,
    p.inline && styles.inline,
    p.background === "light-blue" && styles.backgroundBlue,
    p.justifyCenter && styles.justifyCenter,
    p.alignCenter && styles.alignCenter,
    p.flex && styles.flex,
    p?.newLine && styles.newLine,
    p.className
  ),
}))<Props>`
  ${(p) => p.flexBasis && `flex-basis: ${p.flexBasis};`}
  ${(p) =>
    p.paddingX && `padding-left:${p.paddingX}px;padding-right:${p.paddingX}px;`}
  ${(p) => p.height && `height: ${p.height}px;`}
`;

export default Text;
