import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Content: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="wrap">
        <div className="main">
          <h2>販売方式</h2>

          <div className="more-button">
            <Link to="/creator/plan" className="btn-flat-more">
              <i className="fa fa-caret-right"></i> プラン登録・編集
            </Link>
          </div>

          {/* <h2>基本送料</h2>

          <div className="more-button">
            <Link to="/creator/postage" className="btn-flat-more">
              <i className="fa fa-caret-right"></i> 基本送料編集
            </Link>
          </div>

          <h2>カテゴリ・タグ</h2>

          <div className="more-button">
            <Link to="/creator/categorytag" className="btn-flat-more">
              <i className="fa fa-caret-right"></i>カテゴリ・タグ編集
            </Link>
          </div> */}
        </div>
      </div>

      <div className="btn">
        <span>
          <input
            type="submit"
            className="back"
            onClick={() => navigate(-1)}
            value="戻る"
          />
        </span>
      </div>
    </>
  );
};

export default Content;
