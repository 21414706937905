import styles from "./Img.module.scss";
import clsx from "clsx";
import styled from "styled-components";

interface Props {
  fullWidth?: boolean;
  square?: boolean;
  src: string;
  alt?: string;
  cover?: boolean;
  width?: number;
  height?: number;
  round?: boolean;
  borderd?: boolean;
}
const Container = styled("span").attrs<Props>((p) => ({
  className: clsx(
    styles.img,
    p.fullWidth && styles.fullWidth,
    p.square && styles.square,
    p.cover && styles.cover,
    p.width && styles.width,
    p.round && styles.round,
    p.borderd && styles.borderd,
    p.className
  ),
}))<Props>`
  ${(p) => p?.width && `width: ${p.width}px;`}
  ${(p) => p?.width && p?.square && `height: ${p.width}px;padding-bottom: 0;`}
  background-image: url("${(p) => p.src}");
`;

export default Container;
