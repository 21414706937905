import React, { useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useUpdateUser from "../../../api/user/update";
import Modal, {
  ModalTitle,
  ModalText,
  ModalButton,
} from "../../../parts/Modal";
import { useAuth } from "../../../libs/auth";
import classes from "./CreatorAccountEdit.module.scss";
import Button, { ButtonPrev } from "../../../parts/Button";
import Container from "../../../parts/Container";

const CreatorAccountEdit: React.FC = () => {
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const { userId } = useAuth();
  const updateUser = useUpdateUser();
  const [modal, setModal] = React.useState<boolean>(false);
  const closeModal = () => setModal(false);
  const handleCloseModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setModal(false);
  };
  const [icon, setIcon] = React.useState<File | undefined>(undefined);
  const [passwordShown, setPasswordShown] = React.useState<boolean>(false);
  const [passwordConfirmationShown, setPasswordConfirmationShown] =
    React.useState<boolean>(false);
  const openModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const name = formRef.current?.nickname.value;
    const email = formRef.current?.email.value;
    const password = formRef.current?.password.value;
    const passwordConfirmation = formRef.current?.password2.value;

    if (password !== passwordConfirmation) {
      formRef.current?.password2?.setCustomValidity?.(
        "パスワードが一致しません"
      );
      formRef.current?.password2?.reportValidity?.();
      return;
    }

    if (!name && !password && !email && !icon) {
      alert("ユーザーID、パスワード、メールアドレスを入力してください。");
      return;
    }
    setModal(true);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const name = e.currentTarget?.nickname.value;
    const email = e.currentTarget?.email.value;
    const password = e.currentTarget?.password.value;

    const result = await updateUser.execute({
      ...(name && { nickname: name }),
      ...(email && { email }),
      ...(password && { password }),
      ...(icon && { icon }),
    });
    if (result instanceof Error) return;
    navigate("/creator/account?reload=true");
  };

  const onChangeIcon = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setIcon(file);
  };

  const modalContent = useMemo(
    () => (
      <>
        {formRef.current?.nickname.value && (
          <ModalText>ユーザー名: {formRef.current?.nickname.value}</ModalText>
        )}
        {formRef.current?.email.value && (
          <ModalText>メールアドレス: {formRef.current?.email.value}</ModalText>
        )}
        {formRef.current?.password.value && (
          <ModalText>パスワード: ********</ModalText>
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modal]
  );

  const handleClearValidity = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target?.setCustomValidity?.("");
  };

  return (
    <>
      <form className="wrap" ref={formRef} onSubmit={handleSubmit}>
        <div className="main">
          <h2 className="page-title">アカウント管理</h2>
          {/* 以下のコードがないと、自動補完が走ってしまう */}
          <input
            type="text"
            name="dammy_id"
            style={{
              height: 0,
              padding: 0,
              border: "none",
              outline: "none",
            }}
          />
          <input
            type="password"
            name="dammy_pass"
            style={{
              height: 0,
              padding: 0,
              border: "none",
              outline: "none",
            }}
          />

          <div className="information-wrap">
            <div className="information-box information-border information-contents">
              <h3>ユーザーID</h3>
              <p>No.{userId}</p>
            </div>

            <div className="information-box information-border information-contents">
              <h3>ユーザー名</h3>
              <input
                size={20}
                autoComplete="none"
                type="text"
                className="wide"
                name="nickname"
              />
            </div>

            <div className="information-box information-border information-contents">
              <h3>アイコン</h3>
              <input
                type="file"
                accept="image/*"
                onChange={onChangeIcon}
                name="file"
              />
            </div>

            <div className="information-box information-border information-contents">
              <h3>メールアドレス</h3>
              <input
                size={20}
                autoComplete="none"
                type="text"
                className="wide"
                name="email"
                onChange={handleClearValidity}
              />
            </div>
            <div className="information-box information-border information-contents">
              <h3>パスワード</h3>
              <input
                size={20}
                autoComplete="none"
                type={passwordShown ? "text" : "password"}
                className="wide"
                name="password"
                onChange={handleClearValidity}
              />
              <p className="pass-text">
                パスワードを表示
                <input
                  type="checkbox"
                  style={{
                    margin: 10,
                  }}
                  checked={passwordShown}
                  onChange={(e) => setPasswordShown(e.target.checked)}
                ></input>
              </p>
            </div>
            <div className="information-box information-contents ">
              <h3>パスワード確認</h3>
              <input
                size={20}
                autoComplete="none"
                type={passwordConfirmationShown ? "text" : "password"}
                className="wide"
                name="password2"
                onChange={handleClearValidity}
              />
              <p className="pass-text">
                パスワードを表示
                <input
                  type="checkbox"
                  checked={passwordConfirmationShown}
                  onChange={(e) =>
                    setPasswordConfirmationShown(e.target.checked)
                  }
                  style={{
                    margin: 10,
                  }}
                />
              </p>
            </div>
          </div>
        </div>
        <Container row className="btn">
          <ButtonPrev>戻る</ButtonPrev>
          <Button onClick={openModal}>変更</Button>
        </Container>
        <Modal open={modal} onClose={closeModal}>
          <ModalTitle>以下を変更します</ModalTitle>
          {modalContent}
          {icon && (
            <ModalText>
              アイコン:
              <img
                src={URL.createObjectURL(icon)}
                alt=""
                className={classes.icon}
              />
            </ModalText>
          )}
          <ModalButton>
            <Button color="info" onClick={handleCloseModal}>
              戻る
            </Button>
            <Button>変更</Button>
          </ModalButton>
        </Modal>
      </form>
    </>
  );
};

export default CreatorAccountEdit;
