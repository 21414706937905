import React from "react";
import { Link } from "react-router-dom";

const Content: React.FC = () => {
  return (
    <div className="wrap">
      ページが見つかりません<Link to={"/"}>ホームへ</Link>
    </div>
  );
};

export default Content;
