import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal, {
  ModalTitle,
  ModalButton,
  ModalText,
} from "../../../parts/Modal";
import useAddCategory from "../../../api/category/add";
import Container from "../../../parts/Container";
import Title from "../../../parts/Title";
import Button, { ButtonPrev } from "../../../parts/Button";
import Text from "../../../parts/Text";
import Input from "../../../parts/Input";

const Content: React.FC = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState<string>("");
  const [modal, setModal] = useState<boolean>(false);
  const closeModal = () => setModal(false);
  const openModal = () => {
    if (!category) {
      alert("カテゴリ名を入力してください。");
      return;
    }
    setModal(true);
  };
  const addCategory = useAddCategory();

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement | HTMLInputElement>
  ) => {
    e.preventDefault();
    const r = await addCategory.execute({ name: category });
    if (r instanceof Error) return;
    navigate(-1);
  };

  // const category = [{ id: 1, name: "カテゴリ1" }];

  return (
    <>
      <Container fullWidth>
        <Title>カテゴリ新規追加</Title>
        <Container card round padding50>
          <Text>カテゴリを入力</Text>
          <Input
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
          <Container row fullWidth>
            <ButtonPrev>戻る</ButtonPrev>
            <Button onClick={openModal}>追加</Button>
          </Container>
        </Container>
      </Container>

      <Modal open={modal} onClose={closeModal}>
        <ModalTitle>カテゴリを追加します。</ModalTitle>
        <ModalText>ユーザー名: {category}</ModalText>
        <ModalButton>
          <Button onClick={closeModal} color="info">
            戻る
          </Button>
          <Button onClick={handleSubmit}>追加</Button>
        </ModalButton>
      </Modal>
    </>
  );
};

export default Content;
