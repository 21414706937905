import Layout from "../themplate/Layout";
import Container from "../parts/Container";
import Button, { ButtonPrev } from "../parts/Button";
import useDeleteAccount from "../api/user/delete";
import { useAuth } from "../libs/auth";

const Page: React.FC = () => {
  const deleteAccount = useDeleteAccount();
  const auth = useAuth();

  const handleDelete = async () => {
    const confirmed = window.confirm("アカウントを削除します。");
    if (!confirmed) return;
    await deleteAccount.execute();
    auth.logout?.();
    window.alert("アカウントを削除しました。");
  };
  return (
    <Layout>
      <Container>
        アカウントを削除します。よろしいですか？ ※削除した場合、復元できません。
        <Container row>
          <ButtonPrev />
          <Button onClick={handleDelete}>削除する</Button>
        </Container>
      </Container>
    </Layout>
  );
};
export default Page;
