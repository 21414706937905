import classes from "./TagInput.module.scss";
import React from "react";
import { TiDelete } from "react-icons/ti";

interface Props {
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
}
const TagInput: React.FC<Props> = (props) => {
  const { tags, setTags } = props;
  const [composing, setComposition] = React.useState(false);
  const startComposition = () => setComposition(true);
  const endComposition = () => setComposition(false);
  const removeTags = (index: number) => (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
  };

  const addTag = (e: HTMLInputElement) => {
    console.log(e.value);
    if (!e.value) return;
    if (typeof e.value !== "string") return;
    if (tags.includes(e.value)) return;
    const tag = e.value.replaceAll(" ", "");
    if (tag === "") return;
    setTags((prev) => [...prev, tag]);
    e.value = "";
  };

  const handleFocusout = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    addTag(e.currentTarget);
  };

  const handleAddTag = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (composing) return;
    if (e.key !== "Enter" && e.key !== " ") return;
    addTag(e.currentTarget);
  };
  return (
    <label className={classes.container}>
      {tags.map((tag, index) => (
        <span key={index} className={classes.tag} onClick={removeTags(index)}>
          {tag}
          <TiDelete className={classes.xicon} />
        </span>
      ))}
      <input
        size={20}
        type="text"
        className={classes.input}
        onKeyUp={handleAddTag}
        onCompositionStart={startComposition}
        onCompositionEnd={endComposition}
        onBlur={handleFocusout}
      />
    </label>
  );
};

export default TagInput;
