import React from "react";
import useProducts from "../../../api/product/list";
import useUpdateProduct from "../../../api/product/updateAdmin";
import Button from "../../../parts/Button";

const Home: React.FC = () => {
  const products = useProducts();
  const updateProduct = useUpdateProduct();
  const handleApprove =
    (id: number, action: "accept" | "reject") =>
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      await updateProduct.execute({
        id,
        ...(action === "accept" ? { isActive: true } : { isRejected: true }),
      });
      products.execute();
    };
  return (
    <>
      <div className="wrap">
        <h2>商品画像動画確認</h2>

        <div className="information-box">
          <table>
            {products.data?.map((product) => (
              <tr key={product.id}>
                <th>
                  <h3>ユーザーアカウント名</h3>
                  <p>{product.user?.profile?.nickname}</p>
                </th>
                <th>
                  <h3>ユーザーNo</h3>
                  <p>{product.user.id}</p>
                </th>
                <th>
                  <h3>商品No</h3>
                  <p>{product.id}</p>
                </th>
                <th>
                  <h3>サムネイル画像</h3>
                  <img className="icon" src={product.thumbnail} alt="" />
                </th>
                <th>
                  <h3>ステータス</h3>
                  <p>
                    {product.isActive
                      ? "承認済"
                      : product.isRejected
                      ? "差替指示済み"
                      : "未承認"}
                  </p>
                </th>
                <th>
                  <Button
                    onClick={handleApprove(product.id, "accept")}
                    loading={updateProduct.loading}
                  >
                    承認
                  </Button>
                </th>
                <th>
                  <Button
                    onClick={handleApprove(product.id, "reject")}
                    loading={updateProduct.loading}
                  >
                    差替指示
                  </Button>
                </th>
              </tr>
            ))}
          </table>

          <div className="btn message">
            <span>
              <input
                type="submit"
                className="confirm"
                // onclick="location.href='master-mosaic-product.html'"
                onClick={() => products.execute()}
                value="更新"
              />
            </span>
          </div>
        </div>

        <h2>メッセージ送信</h2>
        <h3>ユーザー情報</h3>
        <input
          size={20}
          type="text"
          className="wide"
          style={{ width: "20%" }}
        />
        <div className="message-form">
          <textarea
            id="product-text"
            name="product-text"
            rows={5}
            cols={33}
          ></textarea>
        </div>
      </div>

      <div className="btn">
        <span>
          <input
            type="submit"
            className="back"
            // onclick="location.href='master-mosaic-list.html'"
            value="戻る"
          />
        </span>
        <span>
          <input
            type="submit"
            className="confirm"
            data-modal-trigger="modal-confirm"
            value="確認"
          />
        </span>
      </div>
    </>
  );
};

export default Home;
