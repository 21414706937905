import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Button from "../../parts/Button";
import Container from "../../parts/Container";
import Text from "../../parts/Text";
import moment from "moment";
import styled from "styled-components";
import useProductplans from "../../api/productplan/list";
import { Link } from "react-router-dom";
import useUser from "../../api/user/get";
import User from "../../parts/User";
import UserLinks from "parts/UserLinks";

const LinkedButton = Button.withComponent(Link);

const Products = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 30px;
  flex-direction: row;
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const MonthSelectorContainer = styled.div`
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  display: flex;
  flex-direction: row;
`;

const MonthSelector = styled(Link)`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-right: 2px solid #ccc;
  cursor: pointer;
  color: #70b4cc;
  &:last-child {
    border-right: none;
  }
`;

const ProductContainer = styled(Link)`
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-basis: 25%;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
`;
const ProductImage = styled.div<{ src: string }>`
  width: 80%;
  padding-top: 80%;
  background-color: #ccc;
  // border-radius: 10px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
const ProductTitle = styled.div`
  overflow-y: hidden;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
`;
const ProductDescription = styled.div`
  overflow-y: hidden;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const Content: React.FC = () => {
  const defaultYear = moment().format("YYYY");
  const defaultMonth = moment().format("MM");

  const { userId } = useParams<{
    userId: string;
  }>();

  const [searchParams] = useSearchParams();
  const year = searchParams.get("year") || defaultYear;
  const month = searchParams.get("month") || defaultMonth;

  const prev = moment(`${year}-${month}-01`).subtract(1, "month");
  const next = moment(`${year}-${month}-01`).add(1, "month");
  const prevYear = prev.format("YYYY");
  const prevMonth = prev.format("MM");
  const nextYear = next.format("YYYY");
  const nextMonth = next.format("MM");

  const user = useUser({
    pathParams: {
      id: userId || "",
    },
  });

  const productplans = useProductplans({
    params: {
      userId: Number(userId),
      year: Number(year),
      month: Number(month),
    },
  });

  useEffect(() => {
    productplans.execute({
      userId: Number(userId),
      year: Number(year),
      month: Number(month),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month, userId]);

  if (!userId) return <div>userIdがありません</div>;

  return (
    <Container fullWidth maxWidth750 gap={30}>
      <User user={user.data} />
      <UserLinks userId={Number(userId)} />
      <Products>
        <MonthSelectorContainer>
          <MonthSelector
            to={`/backnumber/${userId}?year=${prevYear}&month=${prevMonth}`}
          >
            前月
          </MonthSelector>
          <MonthSelector
            to={`/backnumber/${userId}?year=${year}&month=${month}`}
          >
            {year}年{month}月
          </MonthSelector>
          <MonthSelector
            to={`/backnumber/${userId}?year=${nextYear}&month=${nextMonth}`}
          >
            次月
          </MonthSelector>
        </MonthSelectorContainer>
        {productplans.data?.length === 0 && (
          <Text>バックナンバーがありません</Text>
        )}
        {productplans.data?.map?.((productplan) => (
          <ProductContainer to={`/productplan/${productplan.id}`}>
            <ProductImage src={productplan.thumbnail} />
            <ProductTitle>{productplan.name}</ProductTitle>
            <ProductDescription>{productplan.description}</ProductDescription>
          </ProductContainer>
        ))}
      </Products>
      <Container>
        <LinkedButton to="/" color="info">
          TOPへ戻る
        </LinkedButton>
      </Container>
    </Container>
  );
};

export default Content;
