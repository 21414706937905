import React from "react";
import { useNavigate, Link } from "react-router-dom";
import useProductAdd from "../../../api/productplan/add";
import useListCategory from "../../../api/category/list";
import usePlans from "../../../api/plan/list";
import TagInput from "../../../parts/TagInput";
import Button, { ButtonPrev } from "../../../parts/Button";
import Container from "../../../parts/Container";
import styled, { css } from "styled-components";
import ImageInput from "../../../parts/ImageInput";
import { cautionThumbnail } from "../../../consts/texts";
import FileContainer from "../../../parts/FileContainer";

const PlanNotExists = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  margin: 0;
  background-color: #e6faff;
  border: 1px solid #70b4cc;
  border-radius: 5px;
`;
const PlanLink = styled(Link)``;
const PlanContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  margin: 0;
  &:before {
    content: "";
    display: none;
  }
`;
const PlanItem = styled.div<{ active: boolean }>`
  display: flex !important;
  background-color: #e6faff;
  flex-basis: 20px;
  height: 20px;
  margin: 0 !important;
  padding: 0 10px;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  width: 100% !important;
  box-sizing: border-box;
  cursor: pointer;

  ${(p) =>
    p.active &&
    css`
      background-color: #70b4cc;
      color: white;
    `}
`;

const Form = styled.form`
  width: 95%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    width: 70%;
  }
`;

const Caution = styled.p`
  font-size: 0.8rem;
  color: #6d6d6d;
  white-space: pre-wrap;
  text-wrap: normal;
`;

const Content: React.FC = () => {
  const navigate = useNavigate();

  const [name, setName] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [category1, setCategory1] = React.useState<number | undefined>(
    undefined
  );
  const [tags, setTags] = React.useState<string[]>([]);
  const [planIds, setPlanIds] = React.useState<number[]>([]);
  const plans = usePlans({ params: { userId: "me" } });
  const [type, setType] = React.useState<"image" | "video" | "pdf" | "text">(
    "image"
  );

  const addProdcut = useProductAdd();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    console.log("submit");
    e.preventDefault();
    e.stopPropagation();
    if (!name) return alert("商品名を入力してください");
    if (!description) return alert("商品説明を入力してください");
    if (!category1) return alert("カテゴリを選択してください");
    if (!tags) return alert("タグを入力してください");
    if (!planIds || planIds.length === 0)
      return alert("プランを選択してください");
    const file1 = e.currentTarget?.file1?.files?.[0];
    const file2 = e.currentTarget?.file2?.files?.[0];
    const file3 = e.currentTarget?.file3?.files?.[0];
    const file4 = e.currentTarget?.file4?.files?.[0];
    const file5 = e.currentTarget?.file5?.files?.[0];
    const file6 = e.currentTarget?.file6?.files?.[0];
    const file7 = e.currentTarget?.file7?.files?.[0];
    const file8 = e.currentTarget?.file8?.files?.[0];
    const file9 = e.currentTarget?.file9?.files?.[0];
    const file10 = e.currentTarget?.file10?.files?.[0];
    const file11 = e.currentTarget?.file11?.files?.[0];
    const file12 = e.currentTarget?.file12?.files?.[0];
    const file13 = e.currentTarget?.file13?.files?.[0];
    const file14 = e.currentTarget?.file14?.files?.[0];
    const file15 = e.currentTarget?.file15?.files?.[0];
    const file16 = e.currentTarget?.file16?.files?.[0];
    const file17 = e.currentTarget?.file17?.files?.[0];
    const file18 = e.currentTarget?.file18?.files?.[0];
    const file19 = e.currentTarget?.file19?.files?.[0];
    const file20 = e.currentTarget?.file20?.files?.[0];

    const thumbnail = e.currentTarget?.thumbnail?.files?.[0];
    if (!thumbnail) return alert("サムネイルを選択してください");

    const video = e.currentTarget?.video?.files?.[0];
    const sampleVideo = e.currentTarget?.samplevideo?.files?.[0];

    const result = await addProdcut.execute({
      name,
      description,
      thumbnail,
      tags,
      type,
      category1,
      planIds,
      ...(file1 && { file1 }),
      ...(file2 && { file2 }),
      ...(file3 && { file3 }),
      ...(file4 && { file4 }),
      ...(file5 && { file5 }),
      ...(file6 && { file6 }),
      ...(file7 && { file7 }),
      ...(file8 && { file8 }),
      ...(file9 && { file9 }),
      ...(file10 && { file10 }),
      ...(file11 && { file11 }),
      ...(file12 && { file12 }),
      ...(file13 && { file13 }),
      ...(file14 && { file14 }),
      ...(file15 && { file15 }),
      ...(file16 && { file16 }),
      ...(file17 && { file17 }),
      ...(file18 && { file18 }),
      ...(file19 && { file19 }),
      ...(file20 && { file20 }),
      ...(video && { video }),
      ...(sampleVideo && { sampleVideo }),
    });
    if (result instanceof Error) {
      alert("エラーが発生しました");
      return;
    }
    navigate("/creator/productplan");
  };

  const listCategory = useListCategory();

  const handlePlanItemClicked =
    (id: number) => (e: React.MouseEvent<HTMLDivElement>) => {
      if (planIds.includes(id))
        setPlanIds(planIds.filter((planId) => planId !== id));
      else setPlanIds([...planIds, id]);
    };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    const isTextarea = (e?.target as HTMLElement)?.tagName === "TEXTAREA";
    if (isTextarea) return;
    const isEnter = e.key === "Enter";
    if (isEnter) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
        {plans.data?.length === 0 && (
          <PlanNotExists>
            プランを未作成のためプラン商品を作成できません。
            <PlanLink to={`/creator/plan`}>こちらから作成できます</PlanLink>
          </PlanNotExists>
        )}
        <div className="main">
          <h2 className="page-title title-blank">継続プラン商品新規追加</h2>
          <div className="information-wrap">
            <div className="information-box information-border information-contents">
              <h3>
                プラン設定<span className="required">必須</span>
                <span className="caution">
                  <br />
                  ※登録前に下記よりプランを設定する必要があります。
                </span>
              </h3>
              <PlanContainer className="cp_ipselect cp_sl">
                {plans.data?.map((plan) => (
                  <PlanItem
                    key={plan.id}
                    onClick={handlePlanItemClicked(plan.id)}
                    active={planIds.includes(plan.id)}
                  >
                    {plan.name}
                  </PlanItem>
                ))}
              </PlanContainer>
            </div>

            <div className="information-box information-border information-contents">
              <h3>プラン編集</h3>
              <p className="message">
                <Link to="/creator/plan">プラン編集はこちら</Link>
              </p>
            </div>
            <div className="information-box information-border information-contents">
              <h3>
                商品名 <span className="required">必須</span>
              </h3>
              <input
                size={20}
                type="text"
                className="wide"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="information-box information-border information-contents">
              <h3>
                サムネイル画像<span className="required">必須</span>
              </h3>
              <div style={{ width: "60%" }}>
                <ImageInput name="thumbnail" style={{ width: "100%" }} />
                <br />
                <Caution>{cautionThumbnail}</Caution>
              </div>
            </div>

            <div className="information-box information-border information-contents">
              <h3>
                商品説明<span className="required">必須</span>
              </h3>
              <textarea
                id="product-text"
                name="product-text"
                rows={5}
                cols={33}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>

            <div className="information-box information-border information-contents">
              <h3>
                商品カテゴリ選択<span className="required">必須</span>
              </h3>
              <div className="cp_ipselect cp_sl">
                <select
                  required
                  value={category1}
                  onChange={(e) => setCategory1(parseInt(e.target.value))}
                >
                  <option value="" hidden>
                    選択
                  </option>
                  {listCategory.data?.map((category, index) => (
                    <option value={category.id} key={index}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="information-box information-border information-contents">
              <h3>
                商品タグ選択<span className="required">必須</span>
              </h3>

              <TagInput tags={tags} setTags={setTags} />
            </div>

            {/* <div className="information-box information-border add-title">
              <h3>ファン限定コンテンツ</h3>
            </div>
            <div className="information-box information-border">
              「ファン限定コンテンツ」は、有料プランのファンのみに公開するなど、
              公開する範囲 を設定できます。<br></br>
              上位プランとして設定する場合は下位プランも選択すると、上位プランで下位プランの商品も閲覧等可能になります
            </div> */}

            <div className="information-box information-border  information-contents">
              <h3>
                商品タイプ<span className="required">必須</span>
              </h3>
              <div className="cp_ipselect cp_sl">
                <select
                  required
                  value={type}
                  onChange={(e) => {
                    const t =
                      e.target.value === "image"
                        ? ("image" as const)
                        : e.target.value === "pdf"
                        ? ("pdf" as const)
                        : e.target.value === "text"
                        ? ("text" as const)
                        : ("video" as const);
                    setType(t);
                  }}
                >
                  <option value={"image"}>画像</option>
                  <option value={"pdf"}>pdf</option>
                  <option value={"video"}>動画</option>
                  <option value={"text"}>文章</option>
                </select>
              </div>
            </div>

            <FileContainer>
              {type === "image" && (
                <>
                  {[...new Array(20)].map((_, index) => (
                    <div className="information-box information-border information-contents">
                      <h3> 画像ギャラリー{index + 1}</h3>
                      <ImageInput name={`file${index + 1}`} />
                    </div>
                  ))}
                </>
              )}

              {type === "pdf" &&
                [...new Array(20)].map((_, index) => (
                  <div className="information-box information-border information-contents">
                    <h3> ファイル{index + 1}</h3>
                    <input
                      type="file"
                      accept="application/pdf"
                      name={`file${index + 1}`}
                    />
                  </div>
                ))}

              {type === "video" && (
                <>
                  <div className="information-box information-border information-contents">
                    <h3>サンプル動画</h3>
                    <input type="file" name="samplevideo" accept="video/*" />
                  </div>
                  <div className="information-box information-border information-contents">
                    <h3> 動画</h3>
                    <input type="file" name="video" accept="video/*" />
                  </div>
                </>
              )}
            </FileContainer>
          </div>
        </div>
        <Container row className="btn">
          <ButtonPrev>戻る</ButtonPrev>
          <Button loading={addProdcut.loading}>新規追加確認</Button>
        </Container>
      </Form>
    </>
  );
};

export default Content;
