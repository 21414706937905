import React from "react";
import { useSearchParams } from "react-router-dom";
import PlansJoined from "./PlansJoined";
import PlansUserId from "./PlansUserId";

const Content: React.FC = () => {
  const [q] = useSearchParams();
  const id = q.get("userId");
  if (!id) return <PlansJoined />;
  return <PlansUserId />;
};

export default Content;
