import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Modal, {
  ModalTitle,
  ModalButton,
  ModalText,
} from "../../../parts/Modal";
import useGetCategory from "../../../api/category/get";
import useUpdateCategory from "../../../api/category/update";

const Content: React.FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [category, setCategory] = useState<string>("");
  const [modal, setModal] = useState<boolean>(false);
  const closeModal = () => setModal(false);
  const openModal = () => {
    if (!category) {
      alert("カテゴリ名を入力してください。");
      return;
    }
    setModal(true);
  };
  const updateCategory = useUpdateCategory();
  const getCategory = useGetCategory({ pathParams: { id } });

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement | HTMLInputElement>
  ) => {
    e.preventDefault();
    const r = await updateCategory.execute({
      id: parseInt(id),
      name: category,
    });
    if (r instanceof Error) return;
    navigate(-1);
  };

  useEffect(() => {
    if (!getCategory.data) return;
    if (!getCategory.called) return;
    if (getCategory.loading) return;
    if (getCategory.error) return;
    setCategory(getCategory.data.name);
  }, [
    getCategory.data,
    getCategory.called,
    getCategory.loading,
    getCategory.error,
  ]);

  // const category = [{ id: 1, name: "カテゴリ1" }];

  return (
    <>
      <div className="wrap">
        <div className="main">
          <h2>商品カテゴリ編集</h2>

          <div className="information-box">
            <h3>カテゴリ名</h3>
            <input
              size={20}
              type="text"
              className="wide category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
          </div>
        </div>

        <div id="creator-side-menu"></div>
      </div>
      <div className="btn">
        <Link to="/creator/product/category">
          <input type="submit" className="back" value="戻る" />
        </Link>
        <span>
          <input
            type="submit"
            onClick={openModal}
            value="商品カテゴリ編集確認"
          />
        </span>
      </div>
      <Modal open={modal} onClose={closeModal}>
        <ModalTitle>カテゴリ名を変更します</ModalTitle>
        <ModalText>ユーザー名: {category}</ModalText>
        <ModalButton>
          <span>
            <input
              type="submit"
              className="back"
              onClick={closeModal}
              value="戻る"
            />
          </span>
          <span>
            <input type="submit" onClick={handleSubmit} value="変更" />
          </span>
        </ModalButton>
      </Modal>
    </>
  );
};

export default Content;
