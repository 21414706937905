import React from "react";
import useProducts from "../../../api/product/list";
import { Link } from "react-router-dom";
import Container from "../../../parts/Container";
import classes from "./TopApproved.module.scss";

const Product = Container.withComponent(Link);

const Content: React.FC = () => {
  const products = useProducts({ params: { limit: 8 } });
  return (
    <Container className="wrap">
      <h2>商品一覧</h2>
      <Container row overflowYscroll className={classes.products}>
        {products?.data?.map((product) => (
          <Product to={`/product/${product.id}`} className={classes.product}>
            <div className={classes.imgContainer}>
              <img className={classes.img} alt="" src={product.thumbnail} />
            </div>
            <h3 className={classes.name}>{product.name}</h3>
          </Product>
        ))}
      </Container>
    </Container>
  );
};

export default Content;
