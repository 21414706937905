import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import User from "types/User";
import UserType from "types/UserType";
import useUserlist from "../../../api/user/list";

const Content: React.FC = () => {
  const [searchParams] = useSearchParams();
  const userType = (searchParams.get("type") as UserType) || "member";
  const userlist = useUserlist({ params: { type: userType } });

  return (
    <div className="wrap">
      <h2>会員認証承認状態ページ</h2>

      <div className="information-box">
        <table>
          {userlist.data?.map((user: User) => (
            <tr>
              <th>NO.{user.id}</th>
              <td>未読（デフォルト）</td>
              <td>再提出</td>
              <td>上長確認</td>
              <td>上長承認</td>
            </tr>
          ))}
        </table>

        <div className="btn">
          <Link to="/master/member">
            <input
              type="submit"
              className="back"
              // onclick="location.href='master-member-top.html'"
              value="戻る"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Content;
