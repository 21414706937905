import React from "react";
// import { Link } from "react-router-dom";
import useUsers from "../../../api/user/list";
import useUpdateIconState from "../../../api/profile/updateIconStatus";
import Button from "../../../parts/Button";

const Home: React.FC = () => {
  const users = useUsers({ params: { limit: 1000 } });
  const updateUserIconState = useUpdateIconState();
  const handleApprove =
    (id: number, action: "accept" | "reject") =>
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      await updateUserIconState.execute({
        id,
        ...(action === "accept"
          ? { iconAccepted: true, iconRejected: false }
          : { iconAccepted: false, iconRejected: true }),
      });
      users.execute();
    };
  return (
    <>
      <div className="wrap">
        <h2>アカウント画像動画確認</h2>

        <div className="information-box">
          <table>
            {users.data?.map((user) => (
              <tr>
                <th>
                  <h3>ユーザー種別</h3>
                  <p>
                    {user?.roles?.find((role) => role.name === "admin")
                      ? "管理者"
                      : user?.roles?.find((role) => role.name === "creator")
                      ? "クリエーター"
                      : "メンバー"}
                  </p>
                  {/* <div className="cp_ipselect cp_sl">
                    <select required>
                      <option value="" hidden>
                        選択
                      </option>
                      <option value="1">販売ユーザー</option>
                      <option value="2">会員</option>
                    </select>
                  </div> */}
                </th>
                <th>
                  <h3>ユーザーアカウント名</h3>
                  <p>{user?.profile?.nickname}</p>
                </th>
                <th>
                  <h3>ユーザーNo</h3>
                  <p>{user.id}</p>
                </th>
                <th>
                  <h3>アイコン画像</h3>
                  <img
                    className="icon"
                    src={user?.profile?.icon || ""}
                    alt=""
                  />
                </th>
                <th>
                  <h3>ステータス</h3>
                  <p>
                    {user?.profile?.iconAccepted
                      ? "承認"
                      : user?.profile?.iconRejected
                      ? "却下"
                      : "未チェック"}
                  </p>
                </th>
                {user?.profile?.id && (
                  <>
                    <th>
                      <Button
                        onClick={handleApprove(user?.profile.id, "accept")}
                        loading={updateUserIconState.loading}
                      >
                        承認
                      </Button>
                    </th>
                    <th>
                      <Button
                        onClick={handleApprove(user?.profile.id, "reject")}
                        loading={updateUserIconState.loading}
                      >
                        却下
                      </Button>
                    </th>
                  </>
                )}
              </tr>
            ))}
          </table>

          <div className="btn message">
            <span>
              <input
                type="submit"
                className="confirm"
                onClick={() => users.execute()}
                value="更新"
              />
            </span>
          </div>
        </div>

        <h2>メッセージ送信</h2>
        <h3>ユーザー情報</h3>
        <input
          size={20}
          type="text"
          className="wide"
          style={{ width: "20%" }}
        />
        <div className="message-form">
          <textarea
            id="product-text"
            name="product-text"
            rows={5}
            cols={33}
          ></textarea>
        </div>
      </div>

      <div className="btn">
        <span>
          <input
            type="submit"
            className="back"
            // onclick="location.href='master-mosaic-list.html'"
            value="戻る"
          />
        </span>

        <span>
          <input
            type="submit"
            className="confirm"
            data-modal-trigger="modal-confirm"
            value="確認"
          />
        </span>
      </div>
    </>
  );
};

export default Home;
