import React from "react";
import { Link } from "react-router-dom";
import useListTag from "../../../api/tag/list";

const Content: React.FC = () => {
  const tags = useListTag();

  return (
    <>
      <div className="wrap">
        <div className="main">

     
          <div className="btn list-add">
          <h2 className="page-title">タグー覧</h2>
        <Link to="/creator/tag/add">
          <input
            type="submit"
            className="add"
            value="+タグ追加"
          />
        </Link>
      </div>
          <div className="information-box">
            <table>
              {tags.data?.map((tag) => (
                <tr>
                  <th className="product-name">
                    <p>{tag.name}</p>
                  </th>
                  <td>
                    <Link to={`/creator/tag/${tag.id}`}>
                      <input type="submit" className="edit" value="編集" />
                    </Link>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>

        <div id="creator-side-menu"></div>
      </div>
      <div className="btn">
        <Link to="/creator/categorytag">
          <input
            type="submit"
            className="back"
            // onclick="location.href='creator-category-tag.html'"
            value="戻る"
          />
        </Link>
       
      </div>
    </>
  );
};

export default Content;
