import React, { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import useGetProduct from "../../api/productplan/get";
import useListProduct from "../../api/productplan/list";
import Container from "../../parts/Container";
import { Link } from "react-router-dom";
import classes from "./Product.module.scss";
import black from "../../imgs/black.png";
import User from "../../parts/User/User";
import UserLinks from "../../parts/UserLinks";
import Button from "../../parts/Button";
import Modal from "../../parts/Modal";
import moment from "moment";
import styled from "styled-components";
import { useAuth } from "../../libs/auth";
import Image from "../../parts/Image";
import clsx from "clsx";
import ShareButton from "../../parts/ShareButton";

const LinkButton = Button.withComponent("a");

const File = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  filter: blur(10px);
  .active & {
    filter: blur(0px);
  }
  z-index: 1;
`;

const TumbnailContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Thumbnail = styled(Image)`
  width: 100%;
  max-width: 400px;
  width: 400px;
`;

const ImgContainer = styled.label`
  display: flex;
  position: relative;
  width: 50%;
  flex: 0 0 calc(50% - 10px);
  overflow: hidden;
  border: 1px solid #ccc;
  box-sizing: border-box;
  transition: 0.3s;
  &:before {
    content: " ";
    display: block;
    padding-top: 100%;
  }
`;
const ImgExpandContainer = styled.div`
  display: none;
  position: fixed;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw - 40px);
  height: calc(100vh - 40px);
  max-width: 800px;
  max-height: 800px;
  z-index: 100;

  &:before {
    content: "×";
    display: block;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    right: 0;
  }

  .active input:checked ~ & {
    display: block;
  }
`;
const ImgExpanded = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  box-sizing: border-box;
  border: 1px solid #ccc;
`;
const LinkedButton = Button.withComponent(Link);

const Overlay = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 2;
`;

const Content: React.FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const product = useGetProduct({ pathParams: { id } });
  const products = useListProduct();
  const idRef = React.useRef<string>("");
  const [modal, setModal] = React.useState<boolean>(false);

  const auth = useAuth();

  const [userId, setUserId] = React.useState<number | undefined>(undefined);

  // const userIdRef = React.useRef<number | undefined>(undefined);
  const getProductList = useCallback(
    async (userId: number) => {
      const result = await products.execute({ userId, latest: true });
      if (result instanceof Error) return;
    },
    [products]
  );
  useEffect(() => {
    if (!id) return;
    if (idRef.current === id) return;
    idRef.current = id;
    if (product.loading) return;
    product.execute(undefined, { id: `${id}` });
  }, [id, product.loading, product]);

  const handleOpenModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setModal(true);
  };
  const handleCloseModal = () => {
    setModal(false);
  };

  const getYear = () => moment(product?.data?.createdAt).format("YYYY");
  const getMonth = () => moment(product?.data?.createdAt).format("MM");
  const year = useCallback(getYear, [product]);
  const month = useCallback(getMonth, [product]);

  useEffect(() => {
    if (!product.called) return;
    if (product.loading) return;
    if (!product.data) return;
    // if (!userId) return;
    const currentUserId = product?.data?.user?.id;
    if (userId === currentUserId) return;
    setUserId(currentUserId);
    getProductList(currentUserId);
  }, [product, getProductList, products.execute, userId]);
  return (
    <div className="wrap">
      <Container className={classes.user} gap={30}>
        <User user={product?.data?.user || null} />
        <UserLinks userId={Number(userId)} />
      </Container>
      <div className={classes.container}>
        <div className={classes.product}>
          <div className={classes.post}>
            <Link
              to={`/productplan/${product.data?.after}`}
              style={{
                color: !product.data?.after ? "gray" : undefined,
                pointerEvents: !product.data?.after ? "none" : undefined,
              }}
            >
              次の投稿
            </Link>
            <Link to={`/productplan?userId=${userId}`}>投稿一覧</Link>
            <Link
              to={`/productplan/${product.data?.before}`}
              style={{
                color: !product.data?.before ? "gray" : undefined,
                pointerEvents: !product.data?.before ? "none" : undefined,
              }}
              // className={clsx({ disabled: !product.data?.before })}
            >
              前の投稿
            </Link>
          </div>
          <h4 className={classes.name}>{product?.data?.name}</h4>

          <TumbnailContainer>
            <Thumbnail src={product?.data?.thumbnail} />
          </TumbnailContainer>
          <ShareButton>共有する</ShareButton>
          <text>商品は以下です</text>
          <div className={classes.description}>
            {product?.data?.description}
          </div>

          {product.data?.type === "video" && product.data?.sampleVideo && (
            <LinkButton href={product.data?.sampleVideo}>サンプル</LinkButton>
          )}

          {product.data?.type === "video" &&
            product.data?.video &&
            product.data?.isPurchased && (
              <LinkButton href={product.data?.video}>ダウンロード</LinkButton>
            )}

          {!auth.isLogin && (
            <LinkedButton to={`/login`}>ログインして購入する</LinkedButton>
          )}
          {auth.isLogin && !product?.data?.isPurchased && (
            <Button onClick={handleOpenModal}>バックナンバーを購入する</Button>
          )}
          <Modal open={modal} onClose={handleCloseModal}>
            {product.data?.plans?.map?.((plan) => (
              <LinkedButton
                to={`/backnumber-buy/${
                  plan.id
                }?year=${year()}&month=${month()}`}
              >
                {plan.name} {year()}年{month()}月分を購入する {plan.price}FC
              </LinkedButton>
            ))}
          </Modal>

          <div className={classes.files}>
            {[
              product?.data?.file1,
              product?.data?.file2,
              product?.data?.file3,
              product?.data?.file4,
              product?.data?.file5,
            ]
              .filter((file?: string) => !!file)
              .map((file?: string) => (
                <ImgContainer
                  className={clsx({ active: !!product?.data?.isPurchased })}
                >
                  {!product?.data?.isPurchased && (
                    <Overlay src={black} alt="" />
                  )}
                  <File src={file} alt="" />
                  <input type="checkbox" hidden />
                  <ImgExpandContainer>
                    <ImgExpanded src={file} />
                  </ImgExpandContainer>
                </ImgContainer>
              ))}
          </div>
        </div>

        <div className={classes.related}>
          <div className={classes.text}>最新商品</div>
          <div className={classes.products}>
            {userId &&
              products.data?.map((product) => (
                <Link
                  to={`/productplan/${product.id}`}
                  className={classes.product}
                  style={{ position: "relative" }}
                >
                  <img
                    src={product.thumbnail}
                    alt=""
                    className={classes.thumbnail}
                  />
                  <p className={classes.text}> {product.name}</p>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
