import { useAuth } from "../../../libs/auth";
import React from "react";
import TopUnapproved from "../TopUnapproved";
import TopApproved from "../TopApproved";

const Content: React.FC = () => {
  const auth = useAuth();

  if (auth.approved === false) return <TopUnapproved />;
  return <TopApproved />;
};

export default Content;
