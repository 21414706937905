import useMessage from "../../../api/message/get";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Container from "../../../parts/Container";
import Text from "../../../parts/Text";
import Button, { ButtonPrev } from "parts/Button";
import styled from "styled-components";
import moment from "moment";
import usePostMessage from "../../../api/message/add";
import { Link } from "react-router-dom";

const SendReply = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;
const SendReplyTitle = styled.input`
  width: 100% !important;
  padding: 10px !important;
  max-width: none !important;
  border: 1px solid #85cde7 !important;
  border-radius: 5px;
`;
const SendReplyContent = styled.textarea`
  width: 100% !important;
  max-width: none !important;
  padding: 10px !important;
  border: 1px solid #85cde7 !important;
  border-radius: 5px;
`;
const SendReplyButton = styled(Button)`
  width: 100%;
`;

const Message = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: start;
  align-items: start;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  gap: 10px;
`;
const User = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  height: 30px;
  gap: 10px;
`;
const UserName = styled.div``;
const UserIcon = styled.span<{ src: string }>`
  background-color: #ccc;
  background-image: url(${(props) => props.src});
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
`;
const MessageContent = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 100%;
`;
const MessageTitle = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 100%;
  font-weight: bold;
`;
const MessageDate = styled.div`
  width: 100px;
  flex-shrink: 0;
  flex-grow: 0;
  text-align: right;
  font-size: 12px;
  color: #666;
  align-self: flex-end;
`;
const ViewReplies = styled.div`
  font-size: 12px;
`;
const ReplyLabel = styled.div`
  font-size: 12px;
`;

const Parent = styled(Message.withComponent(Link))``;
const ParentUser = styled(User)``;
const ParentUserIcon = styled(UserIcon)``;
const ParentUserName = styled(UserName)``;
const ParentMessageTitle = styled(MessageTitle)``;
const ParentMessageContent = styled(MessageContent)``;
const ParentMessageDate = styled(MessageDate)``;
const Replies = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
`;
const Reply = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;
const ReplayUserIcon = styled(UserIcon)`
  width: 20px;
  height: 20px;
`;
const ReplayTitle = styled(MessageTitle)`
  font-size: 12px;
  font-weight: normal;
`;
const ViewParents = styled.div``;
const ParentMessageSmall = styled(Parent)`
  flex-direction: row;
  gap: 10px;
  padding: 5px;
  width: fit-content;
  align-items: center;
`;
const ParentMessageSmallUserIcon = styled(UserIcon)`
  width: 20px;
  height: 20px;
`;
const ParentMessageSmallTitle = styled(MessageTitle)`
  font-size: 12px;
  font-weight: normal;
`;

const Content: React.FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const message = useMessage({ pathParams: { id } });
  const postMessage = usePostMessage();

  const handleSubmitMessage = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.preventDefault();
    const messageContent = e.currentTarget.replymessage.value;
    const title = e.currentTarget.replytitle.value;
    const to = message.data?.from?.id;
    const parent = message.data?.id;
    const result = await postMessage.execute({
      message: messageContent,
      title,
      to,
      parent,
    });

    if (result instanceof Error) return alert("エラーが発生しました");
    alert("メッセージを送信しました");
  };

  useEffect(() => {
    message.execute({}, { id });
  }, [id]);

  return (
    <Container fullWidth>
      <Message>
        {message.data?.parent && (
          <ViewParents>
            <ReplyLabel>このメッセージに返信しました</ReplyLabel>
            <ParentMessageSmall to={`/message/${message.data?.parent?.id}`}>
              <ParentMessageSmallUserIcon
                src={
                  message.data?.parent?.from?.shopProfile?.icon ||
                  message.data?.parent?.from?.profile?.icon ||
                  ""
                }
              />
              <ParentMessageSmallTitle>
                {message.data?.parent?.title}
              </ParentMessageSmallTitle>
            </ParentMessageSmall>
          </ViewParents>
        )}
        <User>
          <UserIcon
            src={
              message.data?.from?.shopProfile?.icon ||
              message.data?.from?.profile?.icon ||
              ""
            }
          />
          <UserName>
            {message.data?.from?.shopProfile?.name ||
              message.data?.from?.profile?.nickname ||
              "管理者"}
          </UserName>
        </User>
        <MessageTitle>{message.data?.title}</MessageTitle>
        <MessageContent>{message.data?.message}</MessageContent>
        <MessageDate>
          {moment(message.data?.createdAt || undefined).format(
            "YYYY/MM/DD HH:mm"
          )}
        </MessageDate>
        {(message.data?.replies && message.data?.replies?.length && (
          <Replies>
            <ViewReplies>返信を見る</ViewReplies>
            {message.data?.replies.map((reply) => (
              <Reply key={reply.id} to={`/message/${reply.id}`}>
                <ReplayUserIcon
                  src={
                    reply.from?.shopProfile?.icon ||
                    reply.from?.profile?.icon ||
                    ""
                  }
                />
                <ReplayTitle>{reply.title}</ReplayTitle>
              </Reply>
            ))}
          </Replies>
        )) ||
          "返信はありません"}
      </Message>

      {/* {message.data?.parent && (
        <Parent to={`/message/${message.data?.parent?.id}`}>
          <ViewParents>返信先</ViewParents>
          <ParentUser>
            <ParentUserIcon
              src={
                message.data?.parent?.from?.shopProfile?.icon ||
                message.data?.parent?.from?.profile?.icon ||
                ""
              }
            />
            <ParentUserName>
              {message.data?.parent?.from?.shopProfile?.name ||
                message.data?.parent?.from?.profile?.nickname ||
                "管理者"}
            </ParentUserName>
          </ParentUser>
          <ParentMessageTitle>{message.data?.parent?.title}</ParentMessageTitle>
          <ParentMessageContent>
            {message.data?.parent?.message}
          </ParentMessageContent>
          <ParentMessageDate>
            {moment(message.data?.parent?.createdAt || undefined).format(
              "YYYY/MM/DD HH:mm"
            )}
          </ParentMessageDate>
        </Parent>
      )} */}
      <SendReply onSubmit={handleSubmitMessage}>
        <SendReplyTitle placeholder="タイトル" name="replytitle" />
        <SendReplyContent placeholder="メッセージ" name="replymessage" />
        <SendReplyButton
          // onClick={handlePostMessage}
          disabled={postMessage.loading}
          loading={postMessage.loading}
        >
          返信する
        </SendReplyButton>
      </SendReply>
      <Container row justifyCenter>
        <ButtonPrev />
        <Button>返信する</Button>
      </Container>
    </Container>
  );
};

export default Content;
