import useApi from "../useApi";
import Plan from "../../types/Plan";

type DataType = Plan[];
type Params =
  | {
      userId: number | "me";
    }
  | {
      joined: boolean;
    };

const path = "/plan";
const method = "get";
const eagerLoad = true;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
