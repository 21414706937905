import { useApiFormData as useApi } from "../useApi";
import UserType from "../../types/UserType";

type DataType = {
  id: number;
  nickname?: string;
  email?: string;
  password?: string;
  role?: UserType;
  approveProgress?: boolean;
  approved?: boolean;
};
type Params =
  | ({ id: number } & (
      | {
          upgradeCreator: true;
        }
      | { approveProgress?: boolean }
      | { approved?: boolean }
      | {
          nickname?: string;
          email?: string;
          password?: string;
          role?: UserType;
        }
      | {
          balance: number;
        }
      | {
          suspend: boolean;
        }
    ))
  | {
      balance: number;
    };

const path = "/user";
const method = "put";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
