import { useApiFormData } from "../useApi";
import Earning from "../../types/Earning";

type DataType = Earning[];
interface Params {
  year: number;
  month: number;
}

const path = "/earning";
const method = "post";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApiFormData<DataType, Params>({
  path,
  method,
  eagerLoad,
});
