import useMessages from "../../../api/message/list";
import React from "react";
import { Link } from "react-router-dom";
import Container from "../../../parts/Container";
import Text from "../../../parts/Text";
import moment from "moment";
import { ButtonPrev } from "parts/Button";
import styled from "styled-components";

const LinkedContainer = Container.withComponent(Link);

const Messages = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const Message = styled(LinkedContainer)`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: fit-content;
  max-height: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: start;
  align-items: start;
  overflow: hidden;
  & + & {
    border-top: 1px solid #ccc;
  }
`;
const UserIcon = styled.span<{ src: string }>`
  background-color: #ccc;
  background-image: url(${(props) => props.src});
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
`;
const MessageContent = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 100%;
`;
const MessageDate = styled.div`
  width: 100px;
  flex-shrink: 0;
  flex-grow: 0;
  text-align: right;
  font-size: 12px;
  color: #666;
  align-self: flex-end;
`;

const Content: React.FC = () => {
  const messages = useMessages();

  return (
    <Container alignCenter width70L width95>
      <Text large>メッセージ一覧</Text>

      <Messages>
        {messages.data
          ?.sort((a, b) => -moment(a.createdAt).diff(moment(b.createdAt)))
          ?.map((message) => (
            <Message key={message.id} to={`/message/${message.id}`}>
              <UserIcon
                src={
                  message.from?.shopProfile?.icon ||
                  message.from?.profile?.icon ||
                  "管理者"
                }
              />
              <MessageContent>{message.title}</MessageContent>
              <MessageDate>
                {moment(message.createdAt).format("YYYY/MM/DD HH:mm")}
              </MessageDate>
            </Message>
          ))}
      </Messages>
      <Container row justifyCenter>
        <ButtonPrev />
      </Container>
    </Container>
  );
};

export default Content;
