import React from "react";
import Modal, {
  ModalTitle,
  ModalText,
  ModalButton,
} from "../../../parts/Modal";
import { ButtonPrev } from "../../../parts/Button";

const Content: React.FC = () => {
  const [modal, setModal] = React.useState<boolean>(false);
  const close = () => setModal(false);
  const open = () => setModal(true);
  return (
    <>
      <div className="wrap">
        <div className="main">
          <h2>販売方式登録編集</h2>
          <div className="cp_ipselect cp_sl">
            <select required>
              <option value="" hidden>
                選択
              </option>
              <option value="1">販売方式A</option>
              <option value="2">販売方式B</option>
              <option value="2">販売方式C</option>
            </select>
          </div>
        </div>
      </div>
      <div className="btn">
        <ButtonPrev>戻る</ButtonPrev>

        <span>
          <input type="submit" onClick={open} value="変更確定" />
        </span>
      </div>
      {/* 勝手にここモーダルにしました。 */}

      <Modal onClose={close} open={modal}>
        <ModalTitle>管理ユーザー編集確認</ModalTitle>
        <ModalText>販売方式B</ModalText>
        <ModalButton>
          <span>
            <input
              type="submit"
              className="back "
              onClick={close}
              value="戻る"
            />
          </span>
          <span>
            <input
              type="submit"
              value="変更確定"
              //  onclick="koshin()"
            />
          </span>
        </ModalButton>
      </Modal>
    </>
  );
};

export default Content;
