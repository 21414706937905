import React from "react";
import { Link } from "react-router-dom";
import Modal, {
  ModalTitle,
  ModalText,
  ModalButton,
} from "../../../parts/Modal";
import useProductList from "../../../api/product/list";
import useDeleteProduct from "../../../api/product/delete";
import useCopyProduct from "../../../api/product/addCopy";
import styles from "./Product.module.scss";
import Button, { ButtonPrev } from "../../../parts/Button";
import Container from "../../../parts/Container";
import styled from "styled-components";

const ButtonBase = styled(Button)`
  padding: 10px 10px;
`;
const ShareButton = styled(ButtonBase)`
  background: #f58db8;
`;
const DuplicateButton = styled(ButtonBase)`
  background: #92a5d3;
`;
const DeleteButton = styled(ButtonBase)`
  background: #f13e6b;
`;

const LinkedButton = ButtonBase.withComponent(Link);

const Content: React.FC = () => {
  const [modal, setModal] = React.useState<number | undefined>(undefined);
  const products = useProductList({ params: { mine: true, limit: 999 } });
  const copyProduct = useCopyProduct();
  const deleteProduct = useDeleteProduct();
  const close = () => setModal(undefined);
  const handleDelete =
    (id: string) => async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (!window.confirm("削除しますか？")) return;
      const result = await deleteProduct.execute(undefined, { id });
      if (result instanceof Error) {
        alert("削除に失敗しました");
      } else {
        alert("削除しました");
      }
      products.execute({ mine: true, limit: 999 });
    };
  const handleCopy =
    (id: number) => async (e: React.MouseEvent<HTMLInputElement>) => {
      e.preventDefault();
      const result = await copyProduct.execute({ target: id });
      if (result instanceof Error) {
        alert("複製に失敗しました");
      } else {
        alert("複製しました");
        close();
      }
      products.execute({ mine: true, limit: 999 });
    };
  const handleShare =
    (id: number) => async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      const path = `/product/${id}`;
      const url = `${window.location.origin}${path}`;
      await navigator.clipboard.writeText(url);
      alert("URLをコピーしました");
    };
  return (
    <>
      <Container width70L width95>
        <div className="main">
          <h2 className="page-title title-blank">買い切り商品一覧</h2>
          <div className="information-button">
            <Link to="/creator/product/add">
              <input type="submit" className="add" value="＋新規追加" />
            </Link>
          </div>

          <div className="information-box">
            <div className={styles.product_container}>
              {products?.data?.map((product, index) => (
                <div key={index} className={styles.product}>
                  <img
                    className={styles.product_image}
                    src={product.thumbnail}
                    alt={product.name}
                  />
                  <div className={styles.product_info}>
                    <div className={styles.product_name}>{product.name}</div>
                    <div className={styles.product_price}>{product.price}</div>
                    <div className={styles.buttons}>
                      <LinkedButton to={`/creator/product/${product.id}`}>
                        編集
                      </LinkedButton>
                      <ShareButton onClick={handleShare(product.id)}>
                        共有
                      </ShareButton>
                      <DuplicateButton onClick={() => setModal(product.id)}>
                        複製
                      </DuplicateButton>
                      <DeleteButton
                        loading={deleteProduct.loading}
                        onClick={handleDelete(`${product.id}`)}
                      >
                        削除
                      </DeleteButton>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>

      <Container row className="btn">
        <ButtonPrev />
        <span>
          <input
            type="submit"
            // onclick="location.href='creator-pick-up.html'"
            value="ピックアップ商品編集"
          />
        </span>
      </Container>
      <Modal onClose={close} open={modal !== undefined}>
        <ModalTitle>商品を複製しますか？</ModalTitle>
        <ModalText>
          {products.data?.find((product) => product.id === modal)?.name}
        </ModalText>
        <ModalButton>
          <span>
            <input
              type="submit"
              className="back "
              onClick={close}
              value="いいえ"
            />
          </span>
          <span>
            {modal && (
              <input type="submit" value="はい" onClick={handleCopy(modal)} />
            )}
          </span>
        </ModalButton>
      </Modal>
    </>
  );
};

export default Content;
