import useApi from "../useApi";

type DataType = {};
interface Params {
  id: number;
  iconRejected?: boolean;
  iconAccepted?: boolean;
}

const path = "/profile";
const method = "put";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
