import React from "react";
import { useParams } from "react-router-dom";
import Button, { ButtonPrev } from "../../parts/Button";
import Container from "../../parts/Container";
import Text from "../../parts/Text";
import moment from "moment";
import styled from "styled-components";
import usePayment from "../../api/payment/add";

const FormButton = styled(Button).attrs({ as: "form" })``;

const SITE_ID = process.env.REACT_APP_MAXCONNECT_SITE_ID;
const SITE_PW = process.env.REACT_APP_MAXCONNECT_SITE_PW;
const MAXCONNECT_URL = process.env.REACT_APP_MAXCONNECT_URL;

const Content: React.FC = () => {
  const { coin } = useParams<{ coin: string }>();

  const payment = usePayment();

  const coins = [
    { coin: 500, price: 500 },
    { coin: 1000, price: 1000 },
    { coin: 5050, price: 5000 },
    { coin: 10200, price: 10000 },
  ];

  const coinNum = Number(coin);
  const coinPrice = coins.find((c) => c.coin === coinNum)?.price;

  const handleSubmit = async () => {
    const SiteId = SITE_ID;
    const SitePass = SITE_PW;
    const Amount = coinPrice;

    const paymentResult = await payment.execute({ coin: coinNum });
    if (paymentResult instanceof Error) return alert(paymentResult.message);

    const paymentId = paymentResult?.paymentId;
    if (!paymentId) return alert("決済に失敗しました");

    const fullUrl = MAXCONNECT_URL;

    if (!fullUrl) return;
    if (!SiteId) return;
    if (!SitePass) return;
    if (!Amount) return;

    const form = document.createElement("form");
    form.setAttribute("action", fullUrl);
    form.setAttribute("method", "post");

    const addKeyValueToForm = (
      key: string,
      value: string,
      form: HTMLFormElement
    ) => {
      const el = document.createElement("input");
      el.setAttribute("type", "hidden");
      el.setAttribute("name", key);
      el.setAttribute("value", value);
      form.appendChild(el);
    };

    addKeyValueToForm("SiteId", SiteId, form);
    addKeyValueToForm("SitePass", SitePass, form);
    addKeyValueToForm("TransactionId", paymentId, form);
    addKeyValueToForm("Amount", Amount.toString(), form);

    document.body.appendChild(form);

    form.submit();
  };

  if (!coinPrice) return <div>error</div>;

  return (
    <Container fullWidth maxWidth750 gap={30}>
      <Container card round noPadding overflowHidden>
        <Text
          center
          large
          background="light-blue"
          white
          height={50}
          flex
          justifyCenter
          bold
        >
          注文確認
        </Text>
        <Container paddingX={50} paddingY={30}>
          <Text>
            FFコインチャージ：{coinNum}C({coinNum} C)
          </Text>
          <Text>
            有効期限：
            {moment()
              .add(180, "days")
              .endOf("day")
              .format("YYYY年MM月DD日 HH:mm")}
            まで(180日有効)
          </Text>
        </Container>
      </Container>
      <Container card round noPadding overflowHidden>
        <Text
          center
          large
          background="light-blue"
          white
          height={50}
          flex
          justifyCenter
          bold
        >
          注文の確定
        </Text>
        <Container paddingX={50} paddingY={30}>
          <Text bold large>
            今回のご請求額
          </Text>
          <Text large>{coinPrice.toLocaleString()}円</Text>
          <Text gray>ご入金後の払い戻しは原則受け付けておりません</Text>
          <FormButton color="tertiary" round={20} onClick={handleSubmit}>
            規約に同意して購入する
          </FormButton>
          <Text gray>
            上の「規約に同意して購入する」ボタンを押して決済を確定いただくことで、
            <br></br>
            利用規約に同意の上、とらコインを購入したことになります。<br></br>
            また、ご購入後のコインの返品・キャンセルは承りかねますので、
            <br></br>
            あらかじめご了承ください。 <br></br>
            ご注文を確定する前に「ご請求額」「有効期限」をよくご確認ください。
            <br></br>
          </Text>
        </Container>
      </Container>
      <Container>
        <ButtonPrev />
      </Container>
    </Container>
  );
};

export default Content;
