import React from "react";
import { useSearchParams, Link } from "react-router-dom";
import Button from "../../parts/Button";
import User from "../../parts/User";
import UserLinks from "../../parts/UserLinks";
import Text from "../../parts/Text";
import Img from "../../parts/Img";
import Container from "../../parts/Container";
import usePlans from "../../api/plan/list";
import styled from "styled-components";
import useUser from "../../api/user/get";
import useListProduct from "../../api/product/list";
import ShareButton from "../../parts/ShareButton";

const LinkButton = styled(Button.withComponent(Link))`
  height: auto;
  white-space: pre-wrap;
  font-size: 12px;
`;
const LeaveText = styled(Text.withComponent(Link))`
  width: 100%;
  text-align: center;
  font-size: 12px;
`;
const BackNumber = styled(Text.withComponent(Link))``;
const PlansContainer = styled(Container)`
  width: 100%;
  padding: 20px;
`;
const PlanContainer = styled(Container)<{ active?: boolean }>`
  background-color: ${(p) => (p.active ? "rgb(230, 250, 255)" : "white")};
  border-radius: 10px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
`;

const Products = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
  box-sizing: border-box;
  padding: 20px;
`;

const ProductContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: calc((100% - 60px) / 4);
  width: calc((100% - 60px) / 4);
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 10px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
  box-sizing: border-box;
  padding: 20px;
  text-decoration: none;
`;
const ProductImage = styled.div<{ src: string }>`
  width: 100%;
  padding-top: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: #ccc;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
  box-sizing: border-box;
`;
const ProductName = styled.div`
  width: 100%;
  font-size: 20px;
  line-height: 20px;
  height: 20px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const ProductDescription = styled.div`
  width: 100%;
  height: 32px;
  line-height: 16px;
  font-size: 14px;
  color: #666;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const HeaderText = styled.p`
  font-size: 16px;
`;
const Content: React.FC = () => {
  const [q] = useSearchParams();
  const id = q.get("userId");
  if (!id) throw new Error("id is required");
  const userId = Number(id);

  const plans = usePlans({ params: { userId } });
  const produtcs = useListProduct({ params: { userId } });
  const user = useUser({ pathParams: { id: `${userId}` } });

  return (
    <Container width70L width95>
      <User user={user.data} />
      <UserLinks userId={userId} />

      <HeaderText>{user.data?.profile?.nickname}のプラン一覧</HeaderText>
      <ShareButton>共有する</ShareButton>
      <PlansContainer>
        {plans.data?.map?.((plan) => (
          <PlanContainer key={plan.id} active={!!plan.joined}>
            <Container row>
              <Img src={plan.thumbnail} square width={100} cover round />
              <Container>
                <Text>{plan.name}</Text>
                <Text>￥{plan.price}</Text>
                <BackNumber to={`/plan/${plan.id}`}>
                  バックナンバーを見る
                </BackNumber>
              </Container>
            </Container>
            <Text>{plan.description}</Text>
            <LinkButton
              to={plan.joined ? "#" : `/plan/${plan.id}`}
              disabled={plan.joined}
              color="tertiary"
            >
              {plan.joined ? (
                <>
                  加入中{"\n"}
                  {plan.price}円/月
                </>
              ) : (
                "プラン加入"
              )}
            </LinkButton>
            {plan.joined && (
              <LeaveText to={`/plan/${plan.id}/leave`}>プランの退会</LeaveText>
            )}
          </PlanContainer>
        ))}
      </PlansContainer>

      <HeaderText>{user.data?.profile?.nickname}の商品一覧</HeaderText>

      <Products>
        {produtcs.data?.map((product) => (
          <ProductContainer key={product.id} to={`/product/${product.id}`}>
            <ProductImage src={product.thumbnail} />
            <ProductName>{product.name}</ProductName>
            <ProductDescription>{product.description}</ProductDescription>
          </ProductContainer>
        ))}
      </Products>
      <Container row justifyCenter>
        <LinkButton color="info" to="/">
          TOPへ戻る
        </LinkButton>
      </Container>
    </Container>
  );
};

export default Content;
