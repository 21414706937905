import useApi from "../useApi";

type DataType = { message: string };
interface Params {
  leave?: boolean;
}

const path = "/plan/:id";
const method = "delete";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApi<DataType, Params>({
  path,
  method,
  eagerLoad,
});
