import React from "react";
import { Link } from "react-router-dom";

const Content: React.FC = () => {
  return (
    <div className="wrap">
      決済画面<Link to="/payment/done">押すと終わったていで進みます。</Link>
      決済会社が提供デザインのみになると思います。つまりデザインしても意味ないかも。
    </div>
  );
};

export default Content;
