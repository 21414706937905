import Layout from "../themplate/Layout";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useProducts from "../api/product/list";
import { ButtonPrev } from "../parts/Button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  @media (min-width: 768px) {
    width: 70%;
  }
  gap: 20px;
`;

const Title = styled.h1`
  width: 100%;
  font-size: 24px;
  line-height: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  text-align: left;
`;
const ProductContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  justify-content: start;
  align-items: center;
`;

const Product = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(25px - 10px);
  flex-basis: calc(25% - 10px);
  flex-grow: 0;
  flex-shrink: 0;
  padding: 10px;
  background-color: inherit;
  border-radius: 10px;
  gap: 10px;
  border: 0.1px solid #ccc;
  box-shadow: 2px 2px 5px #ccc;
  box-sizing: border-box;
`;
const ProductImage = styled.div<{ src: string }>`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  max-width: 100px;
  height: auto;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 100%;
  }
  &:after {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    background-image: url(${(props) => props.src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
`;
const ProductName = styled.div`
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  height: 14px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
`;
const PurchaseDescription = styled.div`
  width: 100%;
  text-align: center;
  font-size: 12px;
  line-height: 12px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ProductNotfound = styled.div`
  display: flex;
  min-height: 100px;
  width: 100%;
  height: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Page: React.FC = () => {
  const products = useProducts({ params: { bought: true } });
  return (
    <Layout type="member">
      <Container>
        <Title>購入済み商品一覧</Title>
        <ProductContainer>
          {products.data?.map?.((product) => {
            return (
              <Product to={`/product/${product.id}`} key={product.id}>
                <ProductImage src={product.thumbnail} />
                <ProductName>{product.name}</ProductName>
                <PurchaseDescription>{product.description}</PurchaseDescription>
              </Product>
            );
          })}
          {products.data?.length === 0 && (
            <ProductNotfound>購入した商品はありません。</ProductNotfound>
          )}
        </ProductContainer>
        <Buttons>
          <ButtonPrev />
        </Buttons>
      </Container>
    </Layout>
  );
};

export default Page;
