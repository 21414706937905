import styled from "styled-components";
import { Link } from "react-router-dom";
import { useState } from "react";
import clsx from "clsx";
import MessageModal from "../MessageModal";

const Links = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;
const LinkItem = styled(Link)`
  flex-grow: 0;
  flex-shrink: 0;

  justify-content: center;
  align-items: center;
`;

interface Props {
  userId: number;
}
const User: React.FC<Props> = (p) => {
  const { userId } = p;
  const [modal, setModal] = useState<boolean>(false);
  const openModal = () => {
    setModal(true);
  };
  return (
    <Links>
      <LinkItem to={`/shop?userId=${userId}`}>クリエイターTOP</LinkItem>
      <LinkItem to={`/plan?userId=${userId}`}>プラン</LinkItem>
      <LinkItem to={`/product?userId=${userId}`}>商品</LinkItem>
      <LinkItem to={`/backnumber/${userId}`}>バックナンバー</LinkItem>
      <LinkItem to={`#`} onClick={openModal}>
        メッセージ
      </LinkItem>

      <MessageModal userId={userId} open={modal} setOpen={setModal} />
    </Links>
  );
};
export default User;
