import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../libs/auth";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const { login } = useAuth();
  const handleSubmit =
    (type: "creator" | "member") =>
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setError("");
      const d = await login.execute({ email, password, type });
      if (d instanceof Error) return;
      navigate("/?reload=true");
      // if (type === "creator") {
      //   if (d?.isCreator) navigate("/creator?reload=true");
      //   else setError("販売者アカウントではありません");
      // }
      // if (type === "member") navigate("/member?reload=true");
    };
  return (
    <div id="wrapper">
      <div className="login-page">
        <div className="form">
          <p className="form-text">メールアドレスでログイン</p>
          {error && (
            <p id="login-error" className="error-message">
              販売者アカウントではありません。
            </p>
          )}
          {login.error && (
            <p id="login-error" className="error-message">
              ログインに失敗しました。メールアドレスとパスワードを確認してください。
            </p>
          )}
          <form
            className="login-form"
            name="login"
            action="/api/user/login"
            method="post"
            id="loginform"
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              type="text"
              name="email"
              placeholder="メールアドレス"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              name="password"
              placeholder="パスワード"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input type="hidden" name="type" value="" />

            <button
              type="submit"
              name="member"
              onClick={handleSubmit("member")}
            >
              ログイン
            </button>
            <p className="message">
              パスワードを忘れた方は <a href="/passwordreset">こちら</a>
            </p>
          </form>

          <div className="register">
            <p>アカウントをお持ちでない場合</p>

            <Link to="/register">
              <button>新規登録はこちら</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
