import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import useListProduct from "../../api/productplan/list";
import Container from "../../parts/Container";
import Text from "../../parts/Text";
import Img from "../../parts/Img";
import WithUserId from "../ProductPlansUserId";

const Item = Container.withComponent(Link);

const Home: React.FC = () => {
  const [searchParams] = useSearchParams();

  const userId = searchParams.get("userId");

  const products = useListProduct();

  if (userId) {
    return <WithUserId userId={userId} />;
  }
  return (
    <Container fullWidth>
      <Container row>
        {products?.data?.map((product) => (
          <Item
            quarter
            to={`/productplan/${product.id}`}
            background="light-blue"
            round
          >
            <Img src={product.thumbnail} square fullWidth cover />
            <Text bold center>
              {product.name}
            </Text>
          </Item>
        ))}
      </Container>
    </Container>
  );
};

export default Home;
