import React from "react";
import { Link } from "react-router-dom";
import Button, { ButtonPrev } from "../../../parts/Button";
import Text from "../../../parts/Text";
import Container from "../../../parts/Container";
import usePlans from "../../../api/plan/list";
import useDeletePlan from "../../../api/plan/delete";
import styled from "styled-components";
import { useAuth } from "libs/auth";
import { AxiosError } from "axios";

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin: 0;
  padding: 0;
  padding: 0 10px;
  width: 100px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: start;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
`;

const LinkedButton = StyledButton.withComponent(Link);
const SendMessageButton = styled(LinkedButton)`
  width: fit-content;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
`;
const LinkedText = styled(Link)`
  text-decoration: none;
  color: inherit;
  color: #70b4cc;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Spacer = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 0;
`;
const PlanIcon = styled.span<{ src: string }>`
  width: 100px;
  flex-basis: 100px;
  height: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  &::after {
    content: "";
    background-image: url(${(p) => p.src});
    background-size: cover;
    background-position: center;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #ccc;
  }
  &::before {
    content: "";
    padding-bottom: 100%;
    width: 100%;
    z-index: -1;
  }
`;

const PlanTexts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 2;
  width: auto;
  overflow: hidden;
`;

const Name = styled("p")`
  display: flex;
  width: 100%;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  width: 100%;
  word-break: break-all;
`;

const Description = styled("p")`
  width: 100%;
  overflow-wrap: break-word;
`;

const Content: React.FC = () => {
  const plans = usePlans({ params: { userId: "me" } });
  const { userId } = useAuth();
  const deletePlan = useDeletePlan();
  const handleDeletePlan =
    (id: string) => async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      const result = await deletePlan.execute({}, { id });
      // console.log(result, deletePlan.status);
      if (result instanceof AxiosError && result.response?.status === 409) {
        return alert("商品が登録されているため削除に失敗しました");
      }
      if (result instanceof Error) {
        return alert("削除に失敗しました");
      }
      alert("削除しました");
      plans.execute({ userId: "me" });
    };
  const handleShare = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    console.log("share");
    const path = `/plan/?userId=${userId}`;
    const url = new URL(path, window.location.origin);
    await navigator.clipboard.writeText(url.toString());
    alert("クリップボードにコピーしました");
  };
  return (
    <Container width={"70%"} noPadding paddingX={100}>
      <Container row justifyEnd>
        <ButtonContainer className="list-top">
          <h2 className="page-title">プラン一覧</h2>
          <Spacer />
          <LinkedButton to="/creator/plan/add">＋新規追加</LinkedButton>
          <StyledButton onClick={handleShare}>シェア</StyledButton>
        </ButtonContainer>
      </Container>
      {plans.data?.map((plan) => (
        <Container key={plan.id} card round>
          <Container row>
            {/* <Container>{plan.thumbnail}</Container> */}
            <PlanIcon src={plan.thumbnail} />
            <PlanTexts>
              <Name>{plan.name}</Name>
              <Text>￥{plan.price}</Text>
              <Description>{plan.description}</Description>
            </PlanTexts>
          </Container>
          <Container row>
            <SendMessageButton to="">メッセージ一斉送信</SendMessageButton>
            <Spacer />
            <Button
              color="info"
              onClick={handleDeletePlan(`${plan.id}`)}
              loading={deletePlan.loading}
            >
              削除
            </Button>
            <LinkedText to={`/creator/plan/${plan.id}`}>編集</LinkedText>
          </Container>
        </Container>
      ))}
      <Container row justifyCenter>
        <ButtonPrev>戻る</ButtonPrev>
      </Container>
    </Container>
  );
};

export default Content;
