// import SideBar from "../parts/SideBar";
import Header from "../parts/Header";
import Footer from "../parts/Footer";
import styled from "styled-components";
import { Navigate } from "react-router-dom";
import { useAuth } from "../libs/auth";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import R18Verification from "../components/R18Verification";
import Suspended from "../components/Suspended";

const Wrapper = styled.div`
  // display: flex;
  // flex-direction: row;
  // width: 100%;
  // justify-content: center;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

interface Props {
  children: React.ReactNode;
  type?: "creator" | "master" | "member";
  loginPage?: boolean;
}
const Layout: React.FC<Props> = (props) => {
  const { children, type, loginPage = false } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const reload = searchParams.get("reload");
  const [cookies] = useCookies(["r18-verification"]);
  const isR18 = cookies["r18-verification"] === "true";

  useEffect(() => {
    if (reload === "true") {
      navigate(location.pathname, { replace: true });
      navigate(0);
    }
  }, [reload, location.pathname, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const { isLogin, isAdmin, isCreator, loading, user } = useAuth();
  if (loading) return <>"loading..."</>;

  if (!isR18) return <R18Verification />;

  const getIsSuspended = () =>
    isLogin && (type === "creator" || type === "member") && user.data?.suspend;

  const isSuspended = getIsSuspended();

  // クリエーターページにクリエーター以外でアクセスしたら、ログインページにリダイレクト
  if (!isCreator && !loginPage && type === "creator")
    return <Navigate to="/login" />;
  if (loginPage && type === "creator" && isCreator) return <Navigate to="/" />;

  // アドミンページにアドミン以外でアクセスしたら、admin loginページにリダイレクト
  if (!isAdmin && !loginPage && type === "master")
    return <Navigate to="/master/login" />;
  if (loginPage && type === "master" && isAdmin)
    return <Navigate to="/master" />;

  return (
    <div className={type || "undefined"}>
      <Header type={isLogin ? type : undefined} />
      <Wrapper>
        {/* <SideBar type={type} /> */}
        <Content>{isSuspended ? <Suspended /> : children}</Content>
      </Wrapper>
      <Footer />
    </div>
  );
};

export default Layout;
