import React from "react";
import styles from "./Company.module.scss";

const Content: React.FC = () => {
  return (
    <div id="wrapper">
      <div className={styles.item}>
        <div className={styles.key}>サービス提供元</div>
        <div className={styles.value}>株式会社rara</div>
      </div>
      <div className={styles.item}>
        <div className={styles.key}>代表者</div>
        <div className={styles.value}>代表取締役　塩野 克彦</div>
      </div>
      <div className={styles.item}>
        <div className={styles.key}>所在地</div>
        <div className={styles.value}>
          〒170-0014 東京都豊島区池袋1-1-8 SKYビル6F
        </div>
      </div>
    </div>
  );
};

export default Content;
