import { useApiFormData } from "../useApi";

type DataType = {};
export interface Params {
  name: string;
  price: number;
  description: string;
  code?: string;
  stock?: number;
  shipping?: number;
  thumbnail?: File;
  type: "image" | "video" | "pdf";
  file1?: File;
  file2?: File;
  file3?: File;
  file4?: File;
  file5?: File;
  file6?: File;
  file7?: File;
  file8?: File;
  file9?: File;
  file10?: File;
  file11?: File;
  file12?: File;
  file13?: File;
  file14?: File;
  file15?: File;
  file16?: File;
  file17?: File;
  file18?: File;
  file19?: File;
  file20?: File;
  video?: File;
  sampleVideo?: File;
  overview?: string;
  category1: number;
  category2?: number;
  tags: string[];
  timelimit?: {
    start: string;
    end: string;
  };
}

const path = "/product";
const method = "post";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApiFormData<DataType, Params>({
  path,
  method,
  eagerLoad,
});
