import React from "react";
// import { Link } from "react-router-dom";
import Container from "../../../parts/Container/Container";
import Image from "../../../parts/Img";
import useOrders from "../../../api/purchase/list";
import moment from "moment";

const Content: React.FC = () => {
  const orders = useOrders();
  const convertDatestringToString = (d: string) => {
    const date = moment(d).format("yyyy/MM/DD hh:mm:ss");
    return date;
  };
  return (
    <Container fullWidth maxWidth750>
      {Array.isArray(orders?.data) &&
        orders?.data?.map((order) => {
          return (
            <Container card round>
              <Container noPadding>
                {convertDatestringToString(order.createdAt)}
              </Container>
              <Container noPadding>
                {order.products?.map((p) => (
                  <Image src={p.thumbnail} width={100} square borderd cover />
                ))}
              </Container>
            </Container>
          );
        })}
      {/* {JSON.stringify(orders?.data)} */}
    </Container>
  );
};

export default Content;
