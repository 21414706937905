// import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import routes from "./route/Route";

import "./styles/style.scss";
import "./styles/creator.scss";
import "./styles/master.scss";
import "./styles/member.scss";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map(({ path, Component }, index) => (
          <Route path={path} key={index} element={Component} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
