import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../parts/Button";
import Container from "../../parts/Container";

const Content: React.FC = () => {
  const navigate = useNavigate();
  const handlePrev = () => {
    navigate(-1);
  };
  const handleLeave = () => {
    alert("退会しました");
  };

  return (
    <Container fullWidth>
      退会しますか？
      <Container row noPadding>
        <Button onClick={handlePrev} color="info" grow>
          戻る
        </Button>
        <Button grow onClick={handleLeave}>
          はい
        </Button>
      </Container>
    </Container>
  );
};

export default Content;
