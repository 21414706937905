import styles from "./Title.module.scss";
import clsx from "clsx";
import styled from "styled-components";

interface Props {
  fullWidth?: boolean;
}
const Container = styled("h2").attrs<Props>((p) => ({
  className: clsx(styles.title, p.fullWidth && styles.fullWidth, p.className),
}))<Props>``;

export default Container;
