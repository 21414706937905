import React from "react";
import { Link } from "react-router-dom";
//↑エラー回避のためこちら追加しましたが違ったら変更お願いします…！
import { useAuth } from "../../libs/auth";
import styled, { css } from "styled-components";
import { RiAccountCircleFill } from "react-icons/ri";
import LogoImage from "../../imgs/logo.png";
import useNotifies from "../../api/notify/list";
import { useCookies } from "react-cookie";
import { useMemo } from "react";
import SearchIcon from "../../imgs/SearchIcon";

const StyledSearchIcon = styled(SearchIcon)`
  width: 30px;
  height: 50px;
  color: #a2edfe;
  fill: currentColor;
  & > path {
    fill: currentColor;
    color: currentColor;
  }
`;

const HeaderContainer = styled.header`
  height: 107.5px;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 20px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    height: 215px;
  }
`;

const Spacer = styled.div`
  flex: 1 1 auto;
  @media (max-width: 768px) {
    flex: 0 0 0;
  }
`;

const MenuIcon = styled.span<{ src?: string }>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  overflow: hidden;
  font-size: 5px;
  width: 100%;
  height: 50px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  &:before {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    z-index: 1;
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);
    border-radius: 50px;
    display: flex;
    border: 1px solid #ccc;
    background: url(${(props) => props.src});
    background-size: cover;
    background-position: center;
  }
`;
const IconNotFound = styled(RiAccountCircleFill)`
  font-size: 50px;
  color: #ccc;
  width: 50px;
  height: 50px;
`;
const Logo = styled(Link)<{ src: string }>`
  width: 200px;
  flex-basis: 200px;
  flex-shrink: 0;
  flex-grow: 0;
  height: 100%;
  background: url(${(props) => props.src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    flex: 0 0 50px;
    height: 50px;
  }
`;

const Logos = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const HeaderItemCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-basis: 60px;
  flex-shrink: 0;
  flex-grow: 0;
  width: 60px;
  @media (max-width: 768px) {
    width: 20%;
    flex: 1 1 20%;
  }
`;
const BadgeCSS = css`
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    top: 11px;
    right: 16px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fe8346;
    border: 1px solid #fff;
  }
`;

const HeaderItem = styled(Link)<{ badge?: boolean }>`
  ${HeaderItemCSS}
  ${(props) => props?.badge && BadgeCSS}
`;
const HeaderItemDiv = styled.div`
  ${HeaderItemCSS}
`;
const HeaderItemIcon = styled.i`
  line-height: 50px;
  display: inline-block;
  font-size: 30px;
  vertical-align: middle;
`;
const HeaderItemText = styled.p`
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  text-align: center;
`;

const MenuOverlayContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(0%, 100%);
  width: 200px;
  background: #d9f1fb;
  transition: all 0.2s ease;
  box-shadow: 6px 6px 10px 0px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  padding: 10px;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  *:hover > & {
    visibility: visible;
    opacity: 1;
    z-index: 99;
  }
`;
const MenuOverlayItem = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  color: #4f4f4f;
  text-decoration: none;
`;

const creatorItem = [
  { to: "/creator/shop", text: "ショップ編集" },
  { to: "/creator/product/add", text: "買い切り商品登録" },
  { to: "/creator/product", text: "買い切り商品一覧" },
  { to: "/creator/plan", text: "継続プランの登録・編集" },
  { to: "/creator/productplan/add", text: "継続プラン商品登録" },
  { to: "/creator/productplan", text: "継続プラン商品一覧" },
  // { to: "/creator/order", text: "オーダー一覧" },
  // { to: "/creator/supporter-management", text: "販売補助員一覧" },
  // { to: "/creator/message", text: "メッセージ設定" },
  // { to: "/creator/design-template", text: "テンプレート編集" },
  { to: "/creator/earning", text: "売上管理" },
  { to: "/creator/setting", text: "ショップ設定" },
];

const memberItem = [
  { to: "/creator/account", text: "アカウント管理" },
  { to: "/bought", text: "購入商品一覧" },
  { to: "/plan", text: "加入中のプラン" },
  { to: "/charge", text: "コインの購入" },
  { to: "#", text: "ログアウト", onclick: "logout" },
  { to: "/delete-account", text: "退会" },
];

const Header: React.FC = () => {
  const { logout, user, isCreator } = useAuth();
  const [cookie] = useCookies(["notify"]);

  const notifyCookie = useMemo(() => {
    return (cookie.notify as number[]) ?? [];
  }, [cookie.notify]);

  const notifies = useNotifies({ params: { limit: 10 } });

  const notifyCount = notifies.data?.filter(
    (item) => !notifyCookie.some((c) => c === item.id)
  ).length;

  return (
    <>
      <HeaderContainer>
        <Logo to="/" src={LogoImage} />
        <Spacer />
        <Logos>
          <HeaderItem to="/search">
            <HeaderItemIcon as={StyledSearchIcon} />

            <HeaderItemText>検索</HeaderItemText>
          </HeaderItem>
          <HeaderItem to="/notify" badge={!!notifyCount}>
            <HeaderItemIcon className="fa-regular fa-bell" />
            <HeaderItemText>お知らせ</HeaderItemText>
          </HeaderItem>
          <HeaderItem to="/message">
            <HeaderItemIcon className="fa-regular fa-envelope" />
            <HeaderItemText>メッセージ</HeaderItemText>
          </HeaderItem>
          <HeaderItem to="/commingsoon">
            <HeaderItemIcon className="fa-regular fa-heart" />
            <HeaderItemText>お気に入り</HeaderItemText>
          </HeaderItem>
          {isCreator && (
            <HeaderItemDiv>
              <MenuIcon src={user?.data?.shopProfile?.icon}>
                <IconNotFound />
              </MenuIcon>
              <HeaderItemText>ショップ</HeaderItemText>
              <MenuOverlayContainer>
                {creatorItem.map((item) => (
                  <MenuOverlayItem to={item.to}>{item.text}</MenuOverlayItem>
                ))}
              </MenuOverlayContainer>
            </HeaderItemDiv>
          )}
          <HeaderItemDiv>
            <MenuIcon src={user?.data?.profile?.icon}>
              <IconNotFound />
            </MenuIcon>
            <HeaderItemText>{user.data?.profile?.nickname}</HeaderItemText>
            <MenuOverlayContainer>
              {memberItem.map((item) => (
                <MenuOverlayItem
                  to={item.to}
                  onClick={item?.onclick === "logout" ? logout : undefined}
                >
                  {item.text}
                </MenuOverlayItem>
              ))}
            </MenuOverlayContainer>
          </HeaderItemDiv>
        </Logos>
      </HeaderContainer>
    </>
  );
};
export default Header;
