import React from "react";
import { useNavigate } from "react-router-dom";
import useProductAdd from "../../../api/product/add";
import useListCategory from "../../../api/category/list";
import TagInput from "../../../parts/TagInput";
import Button, { ButtonPrev } from "../../../parts/Button";
import Container from "../../../parts/Container";
import { cautionThumbnail } from "../../../consts/texts";
import styled from "styled-components";
import ImageInput from "../../../parts/ImageInput";
import FileContainer from "../../../parts/FileContainer";

const Form = styled.form`
  width: 95%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    width: 70%;
  }
`;
const Caution = styled.p`
  font-size: 0.8rem;
  color: #6d6d6d;
  white-space: pre-wrap;
  text-wrap: normal;
`;

const Content: React.FC = () => {
  const navigate = useNavigate();

  const [name, setName] = React.useState<string>("");
  const [price, setPrice] = React.useState<number>(0);
  const [description, setDescription] = React.useState<string>("");
  const [type, setType] = React.useState<"image" | "video" | "pdf">("image");
  const [video, setVideo] = React.useState<File | undefined>(undefined);
  const [sampleVideo, setSampleVideo] = React.useState<File | undefined>(
    undefined
  );
  const [category1, setCategory1] = React.useState<number | undefined>(
    undefined
  );
  const [tags, setTags] = React.useState<string[]>([]);

  const onChangeFile =
    (n: number | "video" | "sample") =>
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (n === "sample") setSampleVideo(file);
      if (n === "video") setVideo(file);
    };

  const addProdcut = useProductAdd();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!name) return alert("商品名を入力してください");
    if (!price) return alert("価格を入力してください");
    if (category1 === undefined) return alert("カテゴリーを選択してください");
    if (!description) return alert("商品説明を入力してください");
    if (!tags) return alert("タグを入力してください");
    if (!tags.length) return alert("タグを入力してください");

    const thumbnail = e.currentTarget?.thumbnail?.files?.[0];
    if (thumbnail === undefined) return alert("サムネイルを選択してください");

    const file1 = e.currentTarget?.file1?.files?.[0];
    const file2 = e.currentTarget?.file2?.files?.[0];
    const file3 = e.currentTarget?.file3?.files?.[0];
    const file4 = e.currentTarget?.file4?.files?.[0];
    const file5 = e.currentTarget?.file5?.files?.[0];
    const file6 = e.currentTarget?.file6?.files?.[0];
    const file7 = e.currentTarget?.file7?.files?.[0];
    const file8 = e.currentTarget?.file8?.files?.[0];
    const file9 = e.currentTarget?.file9?.files?.[0];
    const file10 = e.currentTarget?.file10?.files?.[0];
    const file11 = e.currentTarget?.file11?.files?.[0];
    const file12 = e.currentTarget?.file12?.files?.[0];
    const file13 = e.currentTarget?.file13?.files?.[0];
    const file14 = e.currentTarget?.file14?.files?.[0];
    const file15 = e.currentTarget?.file15?.files?.[0];
    const file16 = e.currentTarget?.file16?.files?.[0];
    const file17 = e.currentTarget?.file17?.files?.[0];
    const file18 = e.currentTarget?.file18?.files?.[0];
    const file19 = e.currentTarget?.file19?.files?.[0];
    const file20 = e.currentTarget?.file20?.files?.[0];

    const result = await addProdcut.execute({
      name,
      price,
      description,
      thumbnail,
      tags,
      category1,
      type,
      file1,
      file2,
      file3,
      file4,
      file5,
      file6,
      file7,
      file8,
      file9,
      file10,
      file11,
      file12,
      file13,
      file14,
      file15,
      file16,
      file17,
      file18,
      file19,
      file20,
      video,
      sampleVideo,
    });
    if (result instanceof Error) return alert("エラーが発生しました。");
    navigate("/creator/product");
  };

  const listCategory = useListCategory();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    const isTextarea = (e?.target as HTMLElement)?.tagName === "TEXTAREA";
    if (isTextarea) return;
    const isEnter = e.key === "Enter";
    if (isEnter) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <Form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
      <Container>
        <div className="main">
          <h2 className="page-title title-blank">買い切り商品新規追加</h2>

          <div className="information-wrap">
            <div className="information-box information-border information-contents">
              <h3>
                商品名 <span className="required">必須</span>
              </h3>
              <input
                size={20}
                type="text"
                className="wide"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="information-box information-border information-contents">
              <h3>
                サムネイル画像<span className="required">必須</span>
              </h3>
              <div>
                <ImageInput name="thumbnail" />
                <br />
                <Caution>{cautionThumbnail}</Caution>
              </div>
            </div>

            <div className="information-box information-border information-contents">
              <h3>
                商品説明<span className="required">必須</span>
              </h3>
              <textarea
                id="product-text"
                name="product-text"
                rows={5}
                cols={33}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>

            <div className="information-box information-border information-contents">
              <h3>
                商品カテゴリ<span className="required">必須</span>
              </h3>
              <div className="cp_ipselect cp_sl">
                <select
                  required
                  value={category1}
                  onChange={(e) => setCategory1(parseInt(e.target.value))}
                >
                  <option value="" hidden>
                    選択
                  </option>
                  {listCategory.data?.map((category, index) => (
                    <option value={category.id} key={index}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="information-box information-border information-contents">
              <h3>
                商品タグ<span className="required">必須</span>
              </h3>
              <TagInput tags={tags} setTags={setTags} />
            </div>

            <div className="information-box information-border add-title">
              <h3>商品設定</h3>
            </div>
            <div className="information-box information-border">
              「ファン限定コンテンツ」を投稿したい場合はプランを登録後、継続商品登録にて投稿してください。
            </div>

            {/*  <div className="information-box information-border information-contents">
              <h3>
                公開設定<span className="required">必須</span>
              </h3>
              <div className="cp_ipselect cp_sl">
                <select required>
                  <option value="" hidden>
                    選択してください
                  </option>
                  <option value="1">非公開</option>
                  <option value="2">一般公開（全ユーザー閲覧可）</option>
                  <option value="3">
                    限定公開（ファンクラブ加入ユーザー閲覧可）
                  </option>
                  <option value="4">プランA </option>
                </select>
              </div>
            </div>

            <div className="information-box information-border information-contents">
              <h3>プラン編集</h3>
              <p className="message">
                <Link to="/creator/category/add">プラン編集はこちら</Link>
              </p>
            </div>*/}

            <div className="information-box information-contents">
              <h3>
                商品タイプ<span className="required">必須</span>
              </h3>
              <div className="cp_ipselect cp_sl">
                <div></div>
                <select
                  required
                  value={type}
                  onChange={(e) => {
                    const t =
                      e.target.value === "image"
                        ? ("image" as const)
                        : e.target.value === "pdf"
                        ? ("pdf" as const)
                        : ("video" as const);
                    setType(t);
                  }}
                >
                  <option value={"image"}>画像</option>
                  <option value={"pdf"}>pdf</option>
                  <option value={"video"}>動画</option>
                </select>
              </div>
            </div>
            <div className="information-border information-contents caution-contents">
              <span className="caution">
                ※画像5枚以上投稿の場合はpdfにて投稿してください
              </span>
            </div>

            <FileContainer>
              {type === "image" &&
                [...new Array(20)].map((_, index) => (
                  <div
                    className="information-box information-border information-contents"
                    key={index}
                  >
                    <h3> ファイル{index + 1}</h3>
                    <ImageInput name={`file${index + 1}`} />
                  </div>
                ))}

              {type === "pdf" &&
                [...new Array(20)].map((_, index) => (
                  <div className="information-box information-border information-contents">
                    <h3> ファイル{index + 1}</h3>
                    <input
                      type="file"
                      accept="application/pdf"
                      name={`file${index + 1}`}
                    />
                  </div>
                ))}

              {type === "video" && (
                <>
                  <div className="information-box information-border information-contents">
                    <h3> サンプル動画</h3>
                    <input
                      type="file"
                      accept="video/*"
                      onChange={onChangeFile("sample")}
                    />
                  </div>
                  <div className="information-box information-border information-contents">
                    <h3> 動画</h3>
                    <input
                      type="file"
                      accept="video/*"
                      onChange={onChangeFile("video")}
                    />
                  </div>
                </>
              )}
            </FileContainer>

            <div className="information-box information-border information-contents">
              <h3>
                価格設定<span className="required">必須</span>
              </h3>
              <input
                size={20}
                type="number"
                className="wide"
                value={price.toString()}
                onChange={(e) =>
                  !Number.isNaN(e.target.value) &&
                  setPrice(Number(e.target.value) - 0)
                }
              />
            </div>
          </div>
        </div>
      </Container>
      <Container row className="btn">
        <ButtonPrev />
        <Button loading={addProdcut.loading}>確定</Button>
      </Container>
    </Form>
  );
};

export default Content;
