import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Button from "../../parts/Button";
import Container from "../../parts/Container";
import Text from "../../parts/Text";
import { Link } from "react-router-dom";
import useUser from "../../api/user/get";
import Img from "../../parts/Img";
import usePlan from "../../api/plan/get";
import useBuyBacknumber from "../../api/plan/buyBacknumber";

const LinkedButton = Button.withComponent(Link);

const Content: React.FC = () => {
  const navigate = useNavigate();
  const { planId } = useParams<{
    planId: string;
  }>();

  const [searchParams] = useSearchParams();
  const year = searchParams.get("year");
  const month = searchParams.get("month");
  if (!planId) throw new Error("planIdがありません");
  if (!year) throw new Error("yearがありません");
  if (!month) throw new Error("monthがありません");

  const plan = usePlan({ pathParams: { id: planId } });
  const user = useUser({ pathParams: { id: "me" } });
  const buyBacknumber = useBuyBacknumber();

  const price = plan.data?.price || 0;
  const balance = user.data?.balance || 0;
  const handlePurchase = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    console.log("planId", planId);
    console.log("year", year);
    console.log("month", month);
    const result = await buyBacknumber.execute({
      planId: Number(planId),
      year: Number(year),
      month: Number(month),
    });
    if (result instanceof Error) return;
    console.log("result", result);
    navigate(-1);
  };

  const isPurchasable = balance - price >= 0;

  return (
    <Container fullWidth maxWidth750 gap={30}>
      <Text>注文確認</Text>
      <Container card round>
        <Text center>ご注文内容</Text>
        <Container row noPadding>
          <Img
            src={plan.data?.thumbnail || ""}
            square
            cover
            width={150}
            borderd
          />
          <Container noPadding>
            <Text>商品名</Text>
            <Text small>{price}C</Text>
            <Text>現在のFFコイン</Text>
            <Text>{balance}C</Text>
            <Text>決済後のFFコイン</Text>
            <Text>{balance - price}C</Text>
          </Container>
        </Container>
      </Container>
      {buyBacknumber.error && <Text red>エラーが発生しました</Text>}
      <Container>
        <LinkedButton to="/" color="info">
          TOPへ戻る
        </LinkedButton>
        {!isPurchasable ? (
          <LinkedButton to="/charge">コインをチャージする</LinkedButton>
        ) : (
          <Button onClick={handlePurchase} loading={user.loading}>
            購入確定
          </Button>
        )}
      </Container>
    </Container>
  );
};

export default Content;
