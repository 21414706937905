import Content from "../../components/Master/Earning";
import Layout from "../../themplate/Layout";
import usePurchases from "../../api/purchase/list";
import usePlanbought from "../../api/planbought/list";
import styled, { css } from "styled-components";
import moment from "moment";
import Container from "../../parts/Container";

const Title = styled.h1``;

const Purchases = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: scroll;
  padding: 20px;
  gap: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
`;

const Purchase = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 20px;
  &:nth-child(odd) {
    background-color: #eee;
  }
`;
const items = css`
  width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-wrap;
  word-break: break-all;
  text-wrap: wrap;
  text-align: center;
`;
const PurchaseId = styled.div`
  ${items}
`;
const PurchaseDate = styled.div`
  ${items}
`;
const PurchaseProductNo = styled.div`
  ${items}
`;
const PurchaseProductName = styled.div`
  ${items}
`;
const PurchaseProductPrice = styled.div`
  ${items}
`;
const PurchaseProductThumbnail = styled.div<{ src: string }>`
  width: 50px;
  height: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: #ccc;
  background-image: url(${(props) => props.src});
  background-size: cover;
  object-fit: cover;
`;
const PurchaseProductThumbnailTitle = styled.div`
  ${items}
  width: 50px;
`;
const PurchaseProductQuantity = styled.div`
  ${items}
`;
const PurchaseProductTotal = styled.div`
  ${items}
`;
const PurchaseUserId = styled.div`
  ${items}
`;
const PurchaseUserName = styled.div`
  ${items}
`;
const PurchaseUserEmail = styled.div`
  ${items}
`;
const PurchaseMethod = styled.div`
  ${items}
`;
const PurchaseContainer = styled(Container)`
  background: white;
  overflow-x: scroll;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
`;
const PlanboughtId = styled.div`
  width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
const PlanboughtPrice = styled.div`
  width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
const PlanboughtDate = styled.div`
  width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
const PlanboughtUserId = styled.div`
  width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
const PlanboughtPlanUserId = styled.div`
  width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
const PlanboughtCreatedAt = styled.div`
  width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
const PurchaseItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  &:nth-child(odd) {
    background-color: #eee;
  }
`;

const Page: React.FC = () => {
  const purchases = usePurchases();

  const planboughts = usePlanbought({});

  return (
    <Layout type="master">
      <Container width70L width95>
        <Purchases>
          <Title>通常商品購入履歴</Title>
          <Purchase>
            <PurchaseId>ID</PurchaseId>
            <PurchaseDate>購入日時</PurchaseDate>
            <PurchaseProductNo>商品番号</PurchaseProductNo>
            <PurchaseProductName>商品名</PurchaseProductName>
            <PurchaseProductPrice>価格</PurchaseProductPrice>
            <PurchaseProductThumbnailTitle>
              サムネイル
            </PurchaseProductThumbnailTitle>
            <PurchaseProductQuantity>数量</PurchaseProductQuantity>
            <PurchaseProductTotal>総額</PurchaseProductTotal>
            <PurchaseUserId>購入者ID</PurchaseUserId>
            <PurchaseUserName>購入者ニックネーム</PurchaseUserName>
            <PurchaseUserEmail>購入者メール</PurchaseUserEmail>
            <PurchaseMethod>購入方法</PurchaseMethod>
          </Purchase>
          {purchases.data?.map((purchase) =>
            purchase.products?.map((product) => (
              <Purchase>
                <PurchaseId>{purchase.id}</PurchaseId>
                <PurchaseDate>
                  {moment(purchase.createdAt).format("YYYY/MM/DD HH:mm:ss")}
                </PurchaseDate>
                <PurchaseProductNo>{product?.id}</PurchaseProductNo>
                <PurchaseProductName>{product?.name}</PurchaseProductName>
                <PurchaseProductPrice>{product?.price}円</PurchaseProductPrice>
                <PurchaseProductThumbnail src={product?.thumbnail} />
                <PurchaseProductQuantity>1</PurchaseProductQuantity>
                <PurchaseProductTotal>
                  {product?.price * 1}円
                </PurchaseProductTotal>
                <PurchaseUserId>{purchase.user?.id}</PurchaseUserId>
                <PurchaseUserName>
                  {purchase.user?.profile?.nickname}
                </PurchaseUserName>
                <PurchaseUserEmail>{purchase.user?.email}</PurchaseUserEmail>
                <PurchaseMethod>クレジットカード</PurchaseMethod>
              </Purchase>
            ))
          )}
        </Purchases>

        <PurchaseContainer>
          <Title>プラン購入履歴</Title>
          <PurchaseItem>
            <PlanboughtId>ID</PlanboughtId>
            <PlanboughtPrice>価格</PlanboughtPrice>
            <PlanboughtDate>購入商品</PlanboughtDate>
            <PlanboughtPlanUserId>プランのユーザーID</PlanboughtPlanUserId>
            <PlanboughtUserId>購入者ID</PlanboughtUserId>
            <PlanboughtCreatedAt>購入日時</PlanboughtCreatedAt>
          </PurchaseItem>
          {planboughts.data?.map((planbought) => (
            <PurchaseItem>
              <PlanboughtId>{planbought.id}</PlanboughtId>
              <PlanboughtPrice>{planbought.price}</PlanboughtPrice>
              <PlanboughtDate>
                {planbought.year}年{planbought.month}月サブスク分
              </PlanboughtDate>
              <PlanboughtPlanUserId>
                {planbought?.plan?.user?.id}
              </PlanboughtPlanUserId>
              <PlanboughtUserId>{planbought?.user?.id}</PlanboughtUserId>
              <PlanboughtCreatedAt>
                {moment(planbought.createdAt).format("YYYY/MM/DD HH:mm:ss")}
              </PlanboughtCreatedAt>
            </PurchaseItem>
          ))}
        </PurchaseContainer>
      </Container>
    </Layout>
  );
};

export default Page;
