import styles from "./Space.module.scss";
import clsx from "clsx";
import styled from "styled-components";

interface Props {}
const Button = styled("span").attrs<Props>((p) => ({
  className: clsx(p.className, styles.space),
}))<Props>``;

export default Button;
