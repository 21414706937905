import HeaderDefault from "./HeaderDefault";
import HeaderMaster from "./HeaderMaster";
import HeaderMember from "./HeaderMember";
import { useAuth } from "../../libs/auth";

interface HeaderProps {
  type?: "creator" | "master" | "member";
}
const Header: React.FC<HeaderProps> = (props) => {
  const { isLogin } = useAuth();
  if (props.type === "master") return <HeaderMaster />;
  if (isLogin) return <HeaderMember />;
  return <HeaderDefault />;
};

export default Header;
