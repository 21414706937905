import React from "react";
import "./Landing.scss";
import topimg from "./img/topimg2.png";
import btn2 from "./img/btn2.png";
import ffimage1 from "./img/ffimage1.png";
import girlimg1 from "./img/girlimg1.png";
import girlimg2 from "./img/girlimg2.png";
import girlimg3 from "./img/girlimg3.png";
import model1 from "./img/model1.png";
import model2 from "./img/model2.png";
import model3 from "./img/model3.png";
import model4 from "./img/model4.png";
import topimg_sp2 from "./img/topimg_sp2.png";
import coin from "./img/coin.png";

const Landing: React.FC = () => (
  <div className="landing">
    <div className="mainimg">
      <div className="mainimg2">
        <img src={topimg} alt="メイン画像" />
        <div className="topbtn">
          <a href="/register">
            <img src={btn2} alt="登録はお問い合わせまで" />
          </a>
        </div>
      </div>
    </div>
    <div className="mainimgsp">
      <a href="/register">
        <img src={topimg_sp2} alt="メイン画像" />
      </a>
    </div>
    <main>
      <div className="catch">
        <div className="mainimg">
          <div className="card-container">
            <div className="card-text">
              <p>
                Funny Fans［ファニーファンズ］は、
                あなたが応援したいクリエイターから
                商品を購入したりさまざまな支援を届けることができるサービスです。
              </p>

              <p>
                イラストレーターやコスプレイヤーなど、
                創作活動を軸に活躍するクリエイターへ
                継続的な支援はもちろんデジタルデータ購入や
                グッズ商品の購入といった応援もできます。
              </p>

              <p>どなたでも無料でご登録いただけます。</p>
            </div>
            <div className="card-img">
              <img src={ffimage1} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="mainimgsp">
        <div className="card-containersp">
          <div className="card-textsp2">
            <p>
              Funny Fans［ファニーファンズ］は、
              あなたを応援したいファンから継続的な支援を
              受けることができるサービスです。
            </p>

            <p>
              イラストレーターやコスプレイヤーなど、
              創作活動を軸に活躍するクリエイターの応援や
              コニュニティー作りに最適な場所です。
            </p>

            <p>どなたでも無料でご利用いただけます。</p>
          </div>
          <div className="card-imgsp">
            <img src={ffimage1} alt="" />
          </div>
        </div>
      </div>
      <section id="1" className="gray-back2">
        <h2 className="center member">ご利用イメージ</h2>
        <div className="container center">
          <div className="goriyou">
            <div className="goriyouflame">
              <div className="goriyou1">
                <div className="gornum2">
                  <h3 className="gorinum">
                    <span>1</span>
                  </h3>
                </div>
                <div className="gortxt">
                  <h5 className="gorttl">お気に入りのクリエイターを探す</h5>
                  <p>
                    Funny Fansでお気に入りのクリエイターを探そう！
                    デジタルデータ購入やグッズ商品といった買い切り商品、
                    継続プランが購入可能です。
                  </p>
                </div>
                <div className="gorimg">
                  <img src={girlimg1} alt="ここに商品" />
                </div>
              </div>
            </div>
            <div className="goriyouflame">
              <div className="goriyou1">
                <div className="gornum2">
                  <h3 className="gorinum">
                    <span>2</span>
                  </h3>
                </div>
                <div className="gortxt">
                  <h5 className="gorttl">記事や特典を受け取ろう</h5>
                  <p>
                    お気に入りのクリエイターから
                    デジタルデータやオリジナルグッズといった
                    商品を購入したりプランに参加して限定特典を受け取ろう。
                  </p>
                </div>
                <div className="gorimg">
                  <img src={girlimg2} alt="ここに商品" />
                </div>
              </div>
            </div>
            <div className="goriyouflame">
              <div className="goriyou1">
                <div className="gornum2">
                  <h3 className="gorinum">
                    <span>3</span>
                  </h3>
                </div>
                <div className="gortxt">
                  <h5 className="gorttl">コメントや応援を届けよう</h5>
                  <p>
                    投稿した記事に応援コメントを届けよう！
                    あなたの応援がクリエイターの活動に繋がります。
                  </p>
                </div>
                <div className="gorimg">
                  <img src={girlimg3} alt="ここに商品" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="2">
        <h2 className="center member">モデルケース</h2>
        <div className="modelcase">
          <div className="md1">
            <div className="mdtop2">
              <div className="mdimg">
                <img src={model1} alt="" />
              </div>
              <div className="mdttl">
                <span className="tgf">モデルケース1</span>
                <span className="tgf2">イラストレーター</span>
              </div>
            </div>
            <div className="mdinner">
              <p className="ex member plan">継続プランのモデル例</p>
              <h6 className="mdtxt1 member">無料プラン</h6>
              <span>新作やラフ絵イラスト公開</span>
              <h6 className="mdtxt1 member">500円プラン</h6>
              <span>
                上記プラン加えて次回作の先行公開
                Twitter等で投稿した作品に、枚数をプラスして公開
              </span>
              <h6 className="mdtxt1 member">1000円プラン</h6>
              <span>上記プラン加えてFunny Fans限定公開</span>
              <p className="ex">買い切り商品も購入できます！</p>
              <h6 className="mdtxt2">イラスト集・デジタルデータ</h6>
              <span>プラン販売とは別に、単品商品の購入が可能</span>
            </div>
          </div>

          <div className="md1">
            <div className="mdtop2">
              <div className="mdimg">
                <img src={model2} alt="" />
              </div>
              <div className="mdttl">
                <span className="tgf">モデルケース2</span>
                <span className="tgf2">コスプレイヤー</span>
              </div>
            </div>
            <div className="mdinner">
              <p className="ex member plan">継続プランのモデル例</p>
              <h6 className="mdtxt1 member">無料プラン</h6>
              <span>活動に関する告知や日記</span>
              <h6 className="mdtxt1 member">500円プラン</h6>
              <span>
                上記プラン加えて次回コスプレの先行公開
                Twitter等で投稿した写真に、枚数をプラスして公開
              </span>
              <h6 className="mdtxt1 member">1000円プラン</h6>
              <span>上記プラン加えてFunny Fans限定公開 リクエスト写真公開</span>
              <p className="ex">買い切り商品販売例</p>
              <h6 className="mdtxt2">写真集・デジタルデータ販売</h6>
              <span>プラン販売とは別に、単品商品の購入が可能</span>
            </div>
          </div>
        </div>
        <div className="modelcase">
          <div className="md1">
            <div className="mdtop2">
              <div className="mdimg">
                <img src={model3} alt="" />
              </div>
              <div className="mdttl">
                <span className="tgf">モデルケース3</span>
                <span className="tgf2">モデル</span>
              </div>
            </div>
            <div className="mdinner">
              <p className="ex member plan">継続プランのモデル例</p>
              <h6 className="mdtxt1 member">無料プラン</h6>
              <span>活動に関する告知や日記</span>
              <h6 className="mdtxt1 member">500円プラン</h6>
              <span>
                上記プラン加えて次回作の先行公開
                Twitter等で投稿した写真に、枚数をプラスして公開
              </span>
              <h6 className="mdtxt1 member">5000円プラン</h6>
              <span>上記プラン加えてFunny Fans限定公開コンテンツ</span>
              <p className="ex">買い切り商品販売例</p>
              <h6 className="mdtxt2">サイン付き写真販売</h6>
              <span>プラン販売とは別に、単品商品の購入が可能</span>
            </div>
          </div>

          <div className="md1">
            <div className="mdtop2">
              <div className="mdimg">
                <img src={model4} alt="" />
              </div>
              <div className="mdttl">
                <span className="tgf">モデルケース4</span>
                <span className="tgf2">配信者、Vtuber</span>
              </div>
            </div>
            <div className="mdinner">
              <p className="ex member plan">継続プランのモデル例</p>
              <h6 className="mdtxt1 member">無料プラン</h6>
              <span>活動に関する告知や日記</span>
              <h6 className="mdtxt1 member">500円プラン</h6>
              <span>
                上記プラン加えてボイスのリクエスト受付
                シチュエーションボイスの配布
              </span>
              <h6 className="mdtxt1 member">3000円プラン</h6>
              <span>上記プラン加えてFunny Fans限定動画 先行公開など</span>
              <p className="ex">買い切り商品販売例</p>
              <h6 className="mdtxt2">シチュエーションボイス販売</h6>
              <span>プラン販売とは別に、単品商品の購入が可能</span>
            </div>
          </div>
        </div>
      </section>

      <section id="3" className="gray-back2">
        <h2 className="center member">FFコインについて</h2>
        <div className="card-container coin goriyouflame">
          <div className="container orag">
            <span className="uragtxt">
              クリエイターの商品購入やプランの支払いに当サイトのFFコインを購入いただき、
              <br />
              そちらでお支払いいただく形になっております。
              FFコインは基本的に1ポイント＝1円です。
            </span>

            <span className="uragtxt text01">
              ※一度に多く換金いただく場合、換金額によりオマケのサービスFFコインが追加されます。
              <br />
              大変お得になってますので是非ご利用ください。
              <br />
              ※プラン参加等の継続決済は毎月自動的にご登録頂いたクレジットカードよりプラン分のFFコイン分の料金を引き落とし致します。
              <br />
              事前にご購入済みのFFコインはご利用できません。予めご了承ください。
            </span>
          </div>
          <div className="card-imgsp">
            <img src={coin} alt="" />
          </div>
        </div>
      </section>

      <section id="4">
        <div className="pagelast">
          <span className="oragtxt">
            Funny Fansでクリエイター活動を充実させましょう
          </span>

          <a href="/register">
            <img src={btn2} alt="登録はお問い合わせまで" />
          </a>
        </div>
      </section>
    </main>
  </div>
);

export default Landing;
