import React from "react";
import { Link } from "react-router-dom";
import { ButtonPrev } from "../../../parts/Button";

const Content: React.FC = () => {
  return (
    <>
      <div className="wrap">
        <div className="main">
          <h2>売上一覧？</h2>

          <table>
            <tr>
              <td>〇月〇日</td>
              <td>商品名</td>
              <td>〇〇〇〇〇〇〇〇</td>
            </tr>
            <tr>
              <td>〇月〇日</td>
              <td>商品名</td>
              <td>〇〇〇〇〇〇〇〇</td>
            </tr>
            <tr>
              <td>〇月〇日</td>
              <td>商品名</td>
              <td>〇〇〇〇〇〇〇〇</td>
            </tr>
            <tr>
              <td>〇月〇日</td>
              <td>商品名</td>
              <td>〇〇〇〇〇〇〇〇</td>
            </tr>
          </table>

          <h2>入金口座編集</h2>

          <div className="more-button">
            <Link to="/creator/bank" className="btn-flat-more">
              <i className="fa fa-caret-right"></i> 入金口座編集
            </Link>
          </div>
        </div>
      </div>
      <div className="btn">
        <ButtonPrev>戻る</ButtonPrev>
      </div>
    </>
  );
};

export default Content;
